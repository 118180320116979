import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { Auth } from "aws-amplify";
import { validateInput } from "../helper";
import { FaCheckCircle, FaEyeSlash, FaEye, FaInfoCircle } from "react-icons/fa";
import InputMask from "react-input-mask";
import { customerFind } from "../../api";
import { updateCustomerProfile } from "../../api";
import { Link } from "react-router-dom";
import AppButton from "../../components/AppButton";
import AppInput from "../../components/AppInput";
// import { url } from 'inspector';

const checkColor = "#c4c4c4";
const checkColorSuccess = "#3EB5AB";

function Signup() {
  const location = useLocation();
  const routeState: any = location?.state;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let urlValue = useQuery();
  // const urlValue = useParams();
  const initState = {
    email: urlValue?.get("email") || routeState?.email || "",
    phone_number:
      urlValue?.get("phonenumber") || routeState?.phone_number || "",
    firstName: urlValue?.get("firstname") || routeState?.firstName || "",
    lastName: urlValue?.get("lastname") || routeState?.lastName || "",
    password: "",
    hubspotContactId: "",
    confirmPassword: "",
    isPassShown: false,
    isConfirmPassShown: false,
    isConfirmPassActive: false,
    isLoading: false,
    hasError: "",
    errors: {
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
    },
    isSuccess: false,
  };

  const [state, setState] = React.useState(initState);
  const navigate = useNavigate();


  /**
   * Register
   */
  async function registrationHandler() {

    if (!state.firstName) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          firstName: "Field cannot be empty",
        },
      }));
    }

    if (!state.lastName) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          lastName: "Field cannot be empty",
        },
      }));
    }
    if (!state.email) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          email: "Field cannot be empty",
        },
      }));
    }

    if (!state.phone_number) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          phone: "Field cannot be empty",
        },
      }));
    }

    if (!state.password) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          password: "Field cannot be empty",
        },
      }));
    }
    if (!state.confirmPassword) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          confirmPassword: "Field cannot be empty",
        },
      }));
    }

    if (!validateInput(state.email)) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          email: "Please enter valid email",
        },
      }));
    }
    if (state.phone_number.replace(/[_-]/g, "").length < 10) {
      setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          phone: "Please enter valid phone number",
        },
      }));
    }
    if (
      !(
        state.firstName &&
        state.lastName &&
        state.email &&
        state.phone_number &&
        state.password &&
        state.confirmPassword &&
        state.phone_number.replace(/[_-]/g, "").length === 10 &&
        validateInput(state.email)
      )
    ) {
      return;
    }
    if (!checkAll()) {
      setState({ ...state, hasError: "Password strength is weak" });
      return;
    }
    if (state.password !== state.confirmPassword) {
      return;
    }

    setState({
      ...state,
      isLoading: true,
    });

    try {
      // // await Auth.signIn(userData.email, userData.password);
      // // AsyncStorage.setItem('user', JSON.stringify(_user));
      const checkCustomer = await customerFind(state.email);
      if (checkCustomer.exists) {
        const contactId = checkCustomer.contactId;
        setState({ ...state, hubspotContactId: contactId });
        const attributes = {
          email: state.email,
          phone_number: `+1${state.phone_number.replace(/-/g, "")}`,
          given_name: state.firstName,
          family_name: state.lastName,
          "custom:hubspotContactId": contactId,
        };
        const user = await Auth.signUp({
          username: state.email,
          password: state.password,
          attributes: attributes,
        });

        if (user) {
          setState({ ...initState, isSuccess: true });

          let body = {
            cognitoUsername: user?.userSub,
          };

          await updateCustomerProfile(contactId, body);

          // alert("Successfully register")
          // navigate("/login");
        } else {
          setState(initState);
        }
      } else {
        setState({
          ...state,
          isLoading: false,
          hasError: "Hubspot Contact Id not found",
        });
      }
    } catch (error) {
      setState({ ...state, isLoading: false, hasError: error.message });
    }
  }

  const passwordStrengthHandler = (type) => {
    if (type === "min") {
      return state.password.length < 8;
    }
    if (type === "hasLowerCase") {
      return !/[a-z]/g.test(state.password);
    }
    if (type === "hasUpperCase") {
      return !/[A-Z]/.test(state.password);
    }
    if (type === "hasNumber") {
      return !/[0-9]/.test(state.password);
    }
    if (type === "hasSpecial") {
      return !/[-!@#$%^&*+_]/.test(state.password);
    }
  };

  const checkAll = () => {
    const isValid =
      state.password.length >= 8 &&
      /[a-z]/g.test(state.password) &&
      /[A-Z]/.test(state.password) &&
      /[0-9]/.test(state.password) &&
      /[-!@#$%^&*+_]/.test(state.password);
    // console.log(isValid, "isvalid")
    if (!isValid) {
    }
    return isValid;
  };

  const isEmailAlreadyExists =
    urlValue.get("email") !== null || !!routeState?.email;

  return (
    <div className="App">
      <div className={"bg-pattren"}>
        <div className={"bgCardContainer"}>
          <div className={"card-top-img"}>
            <img src={logo} alt={"monster"} />
          </div>
          <div className="bgCard">
            <h1>
              {state.isSuccess
                ? "Your Account has been created"
                : "Create your Recyclops account"}
            </h1>
            {state.isSuccess ? (
              <button
                onClick={() => navigate("/login")}
                className={"btn-container"}
                style={{ marginTop: 55 }}
              >
                Sign in
              </button>
            ) : (
              <>
                <div className={"form signup-form"}>
                  <div className="row">
                    <AppInput
                      label={"First Name"}
                      disabled={
                        urlValue.get("firstname") == null ? false : true
                      }
                      inputStyle={
                        urlValue.get("firstname") == null
                          ? { borderBottom: "1", borderColor: "#C4C4C4" }
                          : { border: "none", background: "transparent" }
                      }
                      placeholder={"John"}
                      value={
                        urlValue == null
                          ? state.firstName
                          : urlValue.get("firstname")
                      }
                      type={"text"}
                      onChange={(e) =>
                        setState({
                          ...state,
                          firstName: e.target.value,
                          errors: { ...state.errors, firstName: "" },
                        })
                      }
                      hasError={state?.errors?.firstName}
                    />
                    <AppInput
                      label={"Last Name"}
                      inputStyle={
                        urlValue.get("lastname") == null
                          ? { borderBottom: "1", borderColor: "#C4C4C4" }
                          : { border: "none", background: "transparent" }
                      }
                      type={"text"}
                      placeholder={"Doe"}
                      value={
                        urlValue == null
                          ? state.lastName
                          : urlValue.get("lastname")
                      }
                      disabled={urlValue.get("lastname") == null ? false : true}
                      onChange={(e) =>
                        setState({
                          ...state,
                          lastName: e.target.value,
                          errors: { ...state.errors, lastName: "" },
                        })
                      }
                      hasError={state?.errors?.lastName}
                    />
                  </div>
                  <div className="row row-sm-single">
                    <AppInput
                      label={"Email"}
                      inputStyle={
                        !isEmailAlreadyExists
                          ? { borderBottom: "1", borderColor: "#C4C4C4" }
                          : { border: "none", background: "transparent" }
                      }
                      type={"text"}
                      placeholder={"Enter your email"}
                      value={state.email}
                      disabled={isEmailAlreadyExists}
                      onChange={(e) =>
                        setState({
                          ...state,
                          email: e.target.value,
                          errors: { ...state.errors, email: "" },
                        })
                      }
                      hasError={state?.errors?.email}
                    />
                    <div className={"form-grop"}>
                      <label className={"signupInput"}>Phone Number</label>
                      <InputMask
                        mask="999-999-9999"
                        style={
                          urlValue.get("phonenumber") == null
                            ? { borderBottom: "1", borderColor: "#C4C4C4" }
                            : { border: "none", background: "transparent" }
                        }
                        data-testid={"number"}
                        placeholder={"412-098-3452"}
                        value={
                          urlValue == null
                            ? state.phone_number
                            : urlValue.get("phonenumber")
                        }
                        onChange={(e) =>
                          setState({
                            ...state,
                            phone_number: e.target.value,
                            errors: { ...state.errors, phone: "" },
                          })
                        }
                        disabled={
                          urlValue.get("phonenumber") == null ? false : true
                        }
                      />
                      {state.errors.phone ? (
                        <span
                          className={"pass-strength blank-password"}
                          style={{ marginLeft: 0, color: "#FB0303" }}
                        >
                          <FaInfoCircle
                            style={{
                              top: 1,
                              position: "relative",
                              marginRight: 3,
                            }}
                          />{" "}
                          {state.errors.phone}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div
                    className="row row-sm-single"
                    style={{ marginBottom: 15 }}
                  >
                    <div style={{ flex: 1 }}>
                      <AppInput
                        label={"Create Password"}
                        type={"password"}
                        placeholder={"Create Password"}
                        inputStyle={{ borderBottomWidth: 0 }}
                        onFocus={() =>
                          setState({ ...state, isConfirmPassActive: true })
                        }
                        value={state.password}
                        onChange={(e) =>
                          setState({
                            ...state,
                            password: e.target.value,
                            hasError: "",
                            errors: { ...state.errors, phone: "" },
                          })
                        }
                        containerStyle={
                          state.isConfirmPassActive && !state.password
                            ? { borderBottomColor: "#FB0303" }
                            : {}
                        }
                        hasError={state?.errors?.password}
                      />
                    </div>
                    <div style={{ flex: 1 }} className={"not-sm"}>
                      <AppInput
                        label={"Confirm Password"}
                        type={"password"}
                        placeholder={"Confirm Password"}
                        value={state.confirmPassword}
                        onChange={(e) =>
                          setState({
                            ...state,
                            confirmPassword: e.target.value,
                          })
                        }
                        inputStyle={{ borderBottomWidth: 0 }}
                        onFocus={() =>
                          setState({ ...state, isConfirmPassActive: true })
                        }
                        hasError={state?.errors?.confirmPassword}
                      />

                      {state.confirmPassword.length > 0 ? (
                        <span
                          className={"pass-strength"}
                          style={{
                            color:
                              state.password !== state.confirmPassword
                                ? "#FB0303"
                                : checkColorSuccess,
                            marginLeft: 0,
                            marginTop: 0,
                          }}
                        >
                          <FaInfoCircle
                            style={{
                              top: 1,
                              position: "relative",
                              marginRight: 3,
                            }}
                          />
                          {state.password !== state.confirmPassword
                            ? "Password do not match"
                            : "Password match"}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  {state.password.length > 0 && !checkAll() ? (
                    <>
                      <div className={"checks-container"}>
                        <p
                          className={"pass-strength"}
                          style={{ marginLeft: 0 }}
                        >
                          Your password must include{" "}
                        </p>
                        <div>
                          <span>
                            <FaCheckCircle
                              className={"FaCheckCircle-icon"}
                              color={
                                passwordStrengthHandler("min")
                                  ? checkColor
                                  : checkColorSuccess
                              }
                              size={14}
                            />
                          </span>
                          <span className={"pass-strength"}>
                            Minimum 8 characters
                          </span>
                        </div>
                        <div>
                          <span>
                            <FaCheckCircle
                              className={"FaCheckCircle-icon"}
                              color={
                                passwordStrengthHandler("hasLowerCase")
                                  ? checkColor
                                  : checkColorSuccess
                              }
                              size={14}
                            />
                          </span>
                          <span className={"pass-strength"}>
                            A lowercase letter
                          </span>
                        </div>
                        <div>
                          <span>
                            <FaCheckCircle
                              className={"FaCheckCircle-icon"}
                              color={
                                passwordStrengthHandler("hasUpperCase")
                                  ? checkColor
                                  : checkColorSuccess
                              }
                              size={14}
                            />
                          </span>
                          <span className={"pass-strength"}>
                            An uppercase letter
                          </span>
                        </div>
                        <div>
                          <span>
                            <FaCheckCircle
                              className={"FaCheckCircle-icon"}
                              color={
                                passwordStrengthHandler("hasNumber")
                                  ? checkColor
                                  : checkColorSuccess
                              }
                              size={14}
                            />
                          </span>
                          <span className={"pass-strength"}>A number</span>
                        </div>
                        <div>
                          <span>
                            <FaCheckCircle
                              className={"FaCheckCircle-icon"}
                              color={
                                passwordStrengthHandler("hasSpecial")
                                  ? checkColor
                                  : checkColorSuccess
                              }
                              size={14}
                            />
                          </span>
                          <span className={"pass-strength"}>
                            A special character(-!@#$%^&*+_)
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div
                    className="row row-sm-single sm"
                    style={{ marginBottom: 15 }}
                  >
                    <div style={{ flex: 1 }}>
                      <div className={"form-grop"}>
                        <label className="signupInput">Confirm Password</label>
                        <div className={"input-pass"}>
                          <input
                            type={
                              !state.isConfirmPassShown ? "password" : "text"
                            }
                            name="confirm-password"
                            id=""
                            placeholder={"Confirm Password"}
                            value={state.confirmPassword}
                            onChange={(e) =>
                              setState({
                                ...state,
                                confirmPassword: e.target.value,
                                errors: {
                                  ...state.errors,
                                  confirmPassword: "",
                                },
                              })
                            }
                            style={{ borderBottomWidth: 0 }}
                            onFocus={() =>
                              setState({ ...state, isConfirmPassActive: true })
                            }
                          />
                          {!state.isConfirmPassShown ? (
                            <FaEye
                              color={checkColor}
                              onClick={() =>
                                setState({
                                  ...state,
                                  isConfirmPassShown: !state.isConfirmPassShown,
                                })
                              }
                            />
                          ) : (
                            <FaEyeSlash
                              color={checkColor}
                              onClick={() =>
                                setState({
                                  ...state,
                                  isConfirmPassShown: !state.isConfirmPassShown,
                                })
                              }
                            />
                          )}
                        </div>
                        {state.errors.confirmPassword && (
                          <span
                            className={"pass-strength blank-password"}
                            style={{ marginLeft: 0, color: "#FB0303" }}
                          >
                            <FaInfoCircle
                              style={{
                                top: 1,
                                position: "relative",
                                marginRight: 3,
                              }}
                            />{" "}
                            {state.errors.confirmPassword}
                          </span>
                        )}
                      </div>
                      {state.confirmPassword.length > 0 ? (
                        <span
                          className={"pass-strength blank-password"}
                          style={{
                            color:
                              state.password !== state.confirmPassword
                                ? "#FB0303"
                                : checkColorSuccess,
                            margin: "5px 10px",
                          }}
                        >
                          <FaInfoCircle
                            color={
                              state.password !== state.confirmPassword
                                ? "#FB0303"
                                : checkColorSuccess
                            }
                            style={{
                              top: 1,
                              position: "relative",
                              marginRight: 3,
                            }}
                          />
                          {state.password !== state.confirmPassword
                            ? "Password do not match"
                            : "Password match"}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  {state.hasError ? (
                    <span
                      className={"pass-strength pass-strength-mob"}
                      style={{ color: "#FB0303", marginLeft: 0, marginTop: 0 }}
                    >
                      {state.hasError}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>

                <AppButton
                  // isDisable = {!(checkAll()) || !isTwoStrIsSame(state.password,state.confirmPassword) || state.isLoading}
                  title={"Create"}
                  onClick={registrationHandler}
                  // className={"btn-container"}
                  isLoading={state.isLoading}
                  isDisable={
                    state.password === "" || state.confirmPassword === ""
                      ? true
                      : false
                  }
                  btnProps={{
                    "data-testid": "submitFormHandler",
                  }}
                />
                <div className={"route-change-tagline"}>
                  <span>
                    Have an account?{" "}
                    <Link className={"route-change-taglineLink"} to={"/login"}>
                      Sign in
                    </Link>
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
