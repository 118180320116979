import React, { useEffect } from 'react'
import { ThreeDots } from  'react-loader-spinner'

function PaymentCard({brand, number, name,date, isActive= false, onClick, onCardClick, hideMonster = false, hasShadow = false, isLoading = false,id}) {
    // const secureNumber =  number.replace(/.(?=.{4})/g, '*').replace(/(.{4})/g, '$1 ')
    const [activeShadow, setActiveShadow] = React.useState(false) 

    useEffect(() => {
        
        if(hasShadow){
            setActiveShadow(true)
        }
        return () => setActiveShadow(false)
    }, [hasShadow])

 return (
    <div className = {"payment-card"}>
        <div className = {`payment-card-container hasShadow ${isActive?"active":""} ${activeShadow?"shadowActive":""}`}/>
        
        <div className = {`payment-card-container ${isActive?"active":""}`} onClick = {onCardClick}>
            <div className = {`payment-card-active ${isActive?"active":""}`}>
                <div className= {'badge'}>
                    <img className ={!hideMonster?"showMonster":""} src={require("../../assets/images/logo.png")} alt=""/>
                    <span>Default</span>
                </div>
            </div>
            <h4>{brand.toUpperCase()}</h4>
            <div className = "payment-card-data">
                <div className = "payment-card-number">**** **** **** {number}</div>
                <div className = "payment-card-meta">
                    <span>{name}</span>
                    <span>{date}</span>
                </div>
            </div>
        </div>
        {
            
            <div className = {`payment-card-default ${(!isActive && !hideMonster)?"active": ""}`} onClick={onClick}>
                <span className = {"link"}>
                    {
                        isLoading === id?
                        <ThreeDots color={"#3EB5AB"} height={12} width={12} />
                        :
                        "Make this default"
                    }
                </span>
            </div>
        }
    </div>
  )
}

export default PaymentCard