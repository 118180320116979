import { ContactUsSvg, FaqSvg, SignOutSvg } from "../../components/Svgs";
import Header from "../../components/Header";
import { logout, setUser } from "../../store/features/auth/authSlice";
import Auth from "@aws-amplify/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import useWindowDimensions from "../../Hook/useWindowDimensions";

function Settings() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const hasMobile = width <= 768;
  const dispatch = useDispatch();
  const onLogout = async () => {
    try {
      await Auth.signOut();
      dispatch(setUser(false));
    } catch (error) {
      dispatch(setUser(false));
    }
    dispatch(logout());
  };

  return (
    <div className={hasMobile ? "mobile-account-background" : ""}>
      <div className={"page-account page-setting"}>
        <Header
          hasBack
          onBackPress={() => {
            navigate("/account");
          }}
          title={"Settings"}
          isSettings={false}
        />
        <div className={"account-cards"}>
          {/* <Card title={"FAQs"} type={"faq"} onClick={() => {
             window.open(`${process.env.REACT_APP_KNOWLEDGE_BASE_URL}`, "_blank");
          }} />
          <Card
            title={"Contact us"}
            type={"contact"}
            onClick={() => {
              navigate("/dashboard/contactus");
            }}
          />
          <Card
            title={"Terms & Conditions"}
            type={"terms"}
            onClick={() => {}}
          /> */}
          <Card title={"Sign out"} type={"signout"} onClick={onLogout} />
        </div>
      </div>
    </div>
  );
}

export default Settings;

const Card = ({ src, title, type, onClick }) => (
  <div className={"account-card"} onClick={onClick}>
    {type === "faq" ? (
      <FaqSvg />
    ) : type === "contact" ? (
      <ContactUsSvg />
    ) : type === "signout" ? (
      <SignOutSvg />
    ) : (
      <img src={require("../../assets/images/terms.png")} alt={title} />
    )}
    <span>{title}</span>
  </div>
);
