// eslint-disable-next-line  no-unused-vars
import React from "react";
import Header from "../../../components/Header";
import AppButton from "../../../components/AppButton";
import useWindowDimensions from "../../../Hook/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import Murph from "../../../assets/images/Murph05.png";

const CancelationSuccess = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const hasMobile = width <= 768;

  const onBackPressHandler = () => {
    navigate("/dashboard/services");
  };

  return (
    <div className="app-main-content  main-seaction">
      <section
        className={`${
          !hasMobile ? "card-section-web section-bg-card active" : "active"
        } `}
      >
        <div className={"page-account"}>
          {hasMobile ? (
            <>
              <Header
                hasBack
                onBackPress={onBackPressHandler}
                isSettings={false}
                title={"Cancel subscription"}
                icon={""}
              />
              <div className="contact-main ">
                <div className="mt-5 p-5 border rounded-2 shadow bg-white m-2 d-flex flex-column align-items-center">
                  <p style={{ fontSize: "22px", fontWeight: "600" }}>
                    Cancellation successful
                  </p>
                  <div>
                    <img src={Murph} alt="loading" />
                  </div>

                  <div className="mt-3 text-center w-50 ">
                    <span style={{ display: "block" }}>
                      We are sad to see you go.
                    </span>
                    <span style={{ display: "block" }}>
                      Your last pick up will be on August 23, 2022. We hope you
                      choose us again in future.
                    </span>
                    <span style={{ display: "block" }}>Happy recycling.</span>
                  </div>

                  <AppButton onClick={onBackPressHandler} title="Done" />
                </div>
              </div>
            </>
          ) : (
            <div className="contact-main">
              <h3 className="w500" style={{ borderBottom: 0 }}>
                Cancel subscription
              </h3>

              <div className="contact-form">
                <div className="mt-5  d-flex flex-column align-items-center">
                  <div className=" ">
                    <img src={Murph} alt="loading" />
                  </div>

                  <div className="mt-3 text-center w-50 ">
                    <p style={{ fontSize: "22px", fontWeight: "600" }}>
                      Cancellation successful
                    </p>
                    <span style={{ display: "block" }}>
                      We are sad to see you go.
                    </span>
                    <span style={{ display: "block" }}>
                      Your last pick up will be on August 23, 2022. We hope you
                      choose us again in future.
                    </span>
                    <span style={{ display: "block" }}>Happy recycling.</span>
                  </div>
                </div>

                <AppButton onClick={onBackPressHandler} title="Done" />
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default CancelationSuccess;
