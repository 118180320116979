import { useState } from 'react'
import { useSelector } from 'react-redux'
import { FaEyeSlash, FaEye, FaCheckCircle, FaInfoCircle } from 'react-icons/fa';
import { GrFormClose } from "react-icons/gr";
import { Auth } from 'aws-amplify';

const checkColor = "#A5A5A5"
const checkColorSuccess = "#3EB5AB"

const initState = {
    password: '',
    newPassword: '',
    confirmPassword: '',
    isNewPassShown: false,
    isPassShown: false,
    isConfirmPassShown: false,
    isConfirmPassActive: false,
}

function UpdatePassword({ setEnableModal, modalHandler }) {
    const [state, setState] = useState(initState)
    const [newPwSameAsOld, setNewPwSameAsOld] = useState(false)
    const [updatePassed, setUpdatePassed] = useState(false)
    const [oldPasswordError, setOldPasswordError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const pickupInfo = useSelector(state => state?.customerData)

    const passwordStrengthHandler = (type) => {
        if (type === "min") {
            return state.newPassword.length < 8
        }
        if (type === "hasLowerCase") {
            return !(/[a-z]/g.test(state.newPassword));
        }
        if (type === "hasUpperCase") {
            return !(/[A-Z]/.test(state.newPassword));

        }
        if (type === "hasNumber") {
            return !(/[0-9]/.test(state.newPassword));

        }
        if (type === "hasSpecial") {
            return !(/[-!@#$%^&*+_]/.test(state.newPassword));
        }
    }

    const checkAll = () => {
        return ((state.newPassword.length >= 8) && (/[a-z]/g.test(state.newPassword)) && (/[A-Z]/.test(state.newPassword) && (/[0-9]/.test(state.newPassword)) && (/[-!@#$%^&*+_]/.test(state.newPassword))))
    }

    const amplifyChangePassword = async () => {
        setIsLoading(true)
        Auth.changePassword(pickupInfo?.data?.user, state.password, state.newPassword)
            .then(result => {
                if (result) {
                    setUpdatePassed(true)
                    setOldPasswordError(false)
                    setIsLoading(false)
                } else {
                    setOldPasswordError(true)
                    setUpdatePassed(false)
                    setIsLoading(false)
                }
            })
            .catch(err => console.log('changePassword error:', err), setIsLoading(false));
    }

    const updatePasswordHandler = () => {
        let newPasswordCheck = checkAll()

        if (newPasswordCheck && !isLoading) {
            if ((state.newPassword === state.confirmPassword) && state.password.length && state.password.toLowerCase() !== state.newPassword.toLowerCase()) {
                amplifyChangePassword()
                setNewPwSameAsOld(false)
            } else {
                setNewPwSameAsOld(true)
                setUpdatePassed(false)
            }
        }
    }

    return (
        <div>
            {updatePassed
                ? <div className={'modal-container-with-background'}>
                    <div className='modal-design-popup'>
                        {/*  */}
                        <div className="row">
                            <div className='text-right col-12' style={{ paddingTop: '13%' }}></div>
                            <div style={{ flex: 1 }}>
                                <div className={"form-grop"}>
                                    <div className='col-12'>
                                        <div className={"password-updated-font"}>Password reset</div>
                                        <label className={"password-updated-response-msg-font"} style={{ paddingTop: '3%' }}>Your password has been reset successfully</label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 top-padding-30px'>
                                <div>
                                    <button
                                        onClick={() => setEnableModal()}
                                        className='btn-containerEnabled'>
                                        {`Ok`}
                                    </button>
                                </div>
                            </div>
                            <div className='text-right col-12 top-padding-10-percent'></div>
                        </div>
                    </div>
                </div>
                : <div className='modal-container-with-background'>
                    <div className='container modal-design-popup'>
                        <div className="row">
                            <div className='text-right col-12 close-modal-button pointer' onClick={() => setEnableModal()}>
                                <GrFormClose />
                            </div>
                            <div style={{ flex: 1 }}>
                                <div className={"form-grop"}>
                                    <div className='col-12'>
                                        <label className={"signupInput update-password--header-padding"}>Old password</label>
                                        <div className={"input-pass"}>
                                            <input data-testid={"updatePassword"} type={!state.isPassShown ? "password" : "text"} name="password" id="updatePassword" placeholder={"Enter current Password"} value={state.password} onChange={e => setState({ ...state, password: e.target.value })} style={{ borderBottomWidth: 0, fontSize: '16px' }} />
                                            {
                                                !state.isPassShown ?
                                                    <FaEye className='pointer' color={checkColor} onClick={() => setState({ ...state, isPassShown: !state.isPassShown })} />
                                                    :
                                                    <FaEyeSlash className='pointer' color={checkColor} onClick={() => setState({ ...state, isPassShown: !state.isPassShown })} />
                                            }
                                        </div>
                                        {
                                            oldPasswordError ?
                                                <span className={"pass-strength"} style={{ color: 'red', marginLeft: 0, marginTop: 0 }}>
                                                    <FaInfoCircle style={{ top: 1, position: 'relative', marginRight: 3 }} />
                                                    {`Incorrect password`}
                                                </span>
                                                :
                                                null
                                        }
                                    </div>
                                    {/*  */}
                                    <div className='col-12'>
                                        <label className={"signupInput update-password--header-padding"}>New password</label>
                                        <div className={"input-pass"}  >
                                            <input data-testid={"updateNewPassword"} type={!state.isNewPassShown ? "password" : "text"} name="newpassword" id="updateNewPassword" placeholder={"Enter new password"} value={state.newPassword} onChange={e => setState({ ...state, newPassword: e.target.value })} style={{ borderBottomWidth: 0, fontSize: '16px' }} />
                                            {
                                                !state.isNewPassShown ?
                                                    <FaEye className='pointer' color={checkColor} onClick={() => setState({ ...state, isNewPassShown: !state.isNewPassShown })} />
                                                    :
                                                    <FaEyeSlash className='pointer' color={checkColor} onClick={() => setState({ ...state, isNewPassShown: !state.isNewPassShown })} />
                                            }
                                        </div>
                                        {
                                            (state.newPassword.length > 0 && !checkAll()) ?
                                                <>
                                                    <div className={""}>
                                                        <p className={"pass-strength"} style={{ marginLeft: 0 }}>Your password must include </p>
                                                        <div>
                                                            <span ><FaCheckCircle className={"FaCheckCircle-icon"} color={passwordStrengthHandler("min") ? checkColor : checkColorSuccess} size={14} /></span>
                                                            <span className={"pass-strength"}>Minimum 8 characters</span>
                                                        </div>
                                                        <div>
                                                            <span ><FaCheckCircle className={"FaCheckCircle-icon"} color={passwordStrengthHandler("hasLowerCase") ? checkColor : checkColorSuccess} size={14} /></span>
                                                            <span className={"pass-strength"}>A lowercase letter</span>
                                                        </div>
                                                        <div>
                                                            <span ><FaCheckCircle className={"FaCheckCircle-icon"} color={passwordStrengthHandler("hasUpperCase") ? checkColor : checkColorSuccess} size={14} /></span>
                                                            <span className={"pass-strength"}>An uppercase letter</span>
                                                        </div>
                                                        <div>
                                                            <span ><FaCheckCircle className={"FaCheckCircle-icon"} color={passwordStrengthHandler("hasNumber") ? checkColor : checkColorSuccess} size={14} /></span>
                                                            <span className={"pass-strength"}>A number</span>
                                                        </div>
                                                        <div>
                                                            <span ><FaCheckCircle className={"FaCheckCircle-icon"} color={passwordStrengthHandler("hasSpecial") ? checkColor : checkColorSuccess} size={14} /></span>
                                                            <span className={"pass-strength"}>A special character(-!@#$%^&*+_)</span>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                        {
                                            state.newPassword.length > 0 && newPwSameAsOld && (state.newPassword.toLowerCase() === state.password.toLowerCase()) ?
                                                <span className={"pass-strength"} style={{ color: 'red', marginLeft: 0, marginTop: 0 }}>
                                                    <FaInfoCircle style={{ top: 1, position: 'relative', marginRight: 3 }} />
                                                    {`Password cannot match old password `}
                                                </span>
                                                :
                                                null
                                        }
                                    </div>
                                    {/*  */}
                                    <div className='col-12'>
                                        <label className={"signupInput update-password--header-padding"}>Confirm password</label>
                                        <div className={"input-pass"}  >
                                            <input type={!state.isConfirmPassShown ? "password" : "text"} name="confirm-password" id="confirmNewPassword" data-testid={"confirmNewPassword"} placeholder={"Confirm new Password"} value={state.confirmPassword} onChange={e => setState({ ...state, confirmPassword: e.target.value })} style={{ borderBottomWidth: 0, fontSize: '16px' }} onFocus={() => setState({ ...state, isConfirmPassActive: true })} />
                                            {
                                                !state.isConfirmPassShown ?
                                                    <FaEye className='pointer' color={checkColor} onClick={() => setState({ ...state, isConfirmPassShown: !state.isConfirmPassShown })} />
                                                    :
                                                    <FaEyeSlash className='pointer' color={checkColor} onClick={() => setState({ ...state, isConfirmPassShown: !state.isConfirmPassShown })} />
                                            }
                                        </div>
                                        <div>
                                            {
                                                state.confirmPassword.length > 0 ?
                                                    <span className={"pass-strength"} style={{ color: state.newPassword !== state.confirmPassword ? "#FB0303" : checkColorSuccess, marginLeft: 0, marginTop: 0 }}>
                                                        <FaInfoCircle style={{ top: 1, position: 'relative', marginRight: 3 }} />
                                                        {
                                                            state.newPassword !== state.confirmPassword ?
                                                                "Password do not match"
                                                                : "Password match"
                                                        }
                                                    </span>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div className='col-12 top-padding-40px'>
                                        <div>
                                            <button
                                                type='button'
                                                data-testid='submitFormHandle'
                                                onClick={updatePasswordHandler}
                                                className='btn-containerEnabled'>
                                                {isLoading ? 'updating...' : `Update Password`}
                                            </button>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div className='col-12 update-password-button-container'>
                                        <div className='forgot-password-text-button pointer' data-testid={"forgotPasswordButton"} onClick={() => modalHandler()}>
                                            {`Forgot password?`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default UpdatePassword
