import { NavLink } from 'react-router-dom'
import { BotomPaymentSvg, HomeSvg, UserSvg, CalendarSvg} from '../Svgs'
function Footer() {
  return (
    <div className = {"app-footer"}>
        <div className= {"footer-tab"}>
            <NavLink to={"/dashboard"} className="botom-nav-link" end>
                <HomeSvg/>
                <span>Home</span>
            </NavLink>
        </div>    
        <div className= {"footer-tab"}>
            <NavLink to={"/dashboard/services"} className="botom-nav-link" end>
                <BotomPaymentSvg/>
                {/* <img
                  src={require("../../assets/images/serviceIcon.png")}
                  alt="icon8"
                  
                /> */}
                <span>Plan</span>
            </NavLink>
        </div>    
        {/* <div className= {"footer-tab"}>
            <NavLink to={"/dashboard/billing"} className="botom-nav-link" end>
                <BotomPaymentSvg/>
                <span>Payment</span>
            </NavLink>
        </div>     */}
        <div className= {"footer-tab"}>
                <a
                 href={`${process.env.REACT_APP_PICKUP_DATE_URL}`}
                 target="_blank"
                 rel="noreferrer"
                 className="botom-nav-link"
               >
            {/* <NavLink to={"/dashboard/calendar"} className="botom-nav-link" end> */}
            {/* <img src={require("../../assets/images/settings.png")} alt="settings-icon"/> */}

            <CalendarSvg/>
                <span>Calendar</span>
                </a>
            {/* </NavLink> */}
        </div>    
       
         <div className= {"footer-tab"}>
         <NavLink to={"/dashboard/account"} className="botom-nav-link" end>
            <UserSvg/>
                <span>Account</span>
            </NavLink>
        </div>   
       
    </div>
  )
}

export default Footer