import React from 'react'
import { useNavigate } from 'react-router'
import { BackArrow } from '../Svgs'

function Header({hasBack,title, onBackPress, isSettings=true, icon = ""}) {
  const navigate = useNavigate()
  return (
    <div className={'app-header'}>
      <div className={"app-header-left"}>
        {
          hasBack?
          <div onClick = {onBackPress}>{<BackArrow/>}</div>:""
        }
        <span>{title}</span>
      </div>
      {(isSettings)?
      <div onClick = {()=>navigate("/dashboard/settings")}>
        <img src={require("../../assets/images/settings.png")} alt="settings-icon" />
      </div>:<></>
      }
    </div>
  )
}

export default Header