import RadeemCancel from "../../CancelTabs/RadeemCancel";

const MissedPickupsRadeem = () => {
  const heading = "Get a free service for a month.";
  const paragraphOne =
    "We hate to have you not be able to recycle.";
  const paragraphTwo = "Would it help if we give you a month of free service?";
  const btnOne = "Radeem offer";
  const btnTwo = "No, Continue Cancellation";

  return (
    <RadeemCancel
      heading={heading}
      paragraphOne={paragraphOne}
      paragraphTwo={paragraphTwo}
      btnOne={btnOne}
      btnTwo={btnTwo}
    />
  );
};

export default MissedPickupsRadeem;
