import { BsCheckCircleFill } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import { SiIfixit } from "react-icons/si";

function DeleteCreditCard({ buttonLoading, creditCardRemovedError, last4Digits, setEnableModal, removeCreditCardHandler, creditCardRemoved }) {
    return (
        <div>
            {creditCardRemoved
                ? <div className={'modal-container-with-background'}>
                    <div className='modal-design-popup-remove-credit-card'>
                        {/*  */}
                        <div className="row">
                            <div className='text-right col-12' style={{ paddingTop: '16%' }}></div>
                            <div style={{ flex: 1 }}>
                                <div className={"form-grop"}>
                                    {creditCardRemovedError
                                        ? <div className='col-12 check-mark-error-filled-small'>
                                            <SiIfixit />
                                        </div>
                                        : <div className='col-12 check-mark-filled-small'>
                                            <BsCheckCircleFill />
                                        </div>
                                    }
                                    <div className='col-12' style={{ paddingTop: '22px' }}>
                                        <div className={"password-updated-font"}>{creditCardRemovedError ? `Error occured while removing credit card` : `Your card has been removed`}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='text-right col-12 top-padding-10-percent'></div>
                        </div>
                    </div>
                </div>
                : <div className={'modal-container-with-background'}>
                    <div className='modal-design-popup-remove-credit-card'>
                        {/*  */}
                        <div className="row">
                            <div className='text-right col-12 close-modal-button-remove-credit-card' onClick={() => setEnableModal()}>
                                <GrFormClose />
                            </div>
                            <div className='text-right col-12' style={{ paddingTop: '2%' }}></div>
                            <div style={{ flex: 1 }}>
                                <div className={"form-grop"}>
                                    <div className='col-12'>
                                        <div className={"password-updated-font container"}>{`Are you sure you want to remove card ending in ${last4Digits}?`}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 top-padding-30px'>
                                <div>
                                    <button
                                        disabled={buttonLoading ? true : false}
                                        onClick={() => removeCreditCardHandler()}
                                        className='btn-containerEnabled-remove-credit-card'
                                    >
                                        {buttonLoading ? `Loading...` : `Remove`}
                                    </button>
                                </div>
                            </div>
                            <div className='col-12 top-padding-15px'>
                                <div>
                                    <button
                                        disabled={buttonLoading ? true : false}
                                        onClick={() => setEnableModal()}
                                        className='btn-container'>
                                        {`Cancel`}
                                    </button>
                                </div>
                            </div>
                            <div className='text-right col-12 '></div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default DeleteCreditCard
