import React, { useEffect } from 'react'
import { CameraSvg, ContactUsSvg, FaqSvg, SignOutSvg, UserSvg } from '../../components/Svgs'
import Header from '../../components/Header'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../Hook/useWindowDimensions';

function Account() {
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    const hasMobile = width <= 768;
    // @ts-ignore
    const loggedInUser = useSelector(state => state?.customerData?.data.userProfile?.firstName)
    const [firstName, setFirstName] = React.useState('')
    const hiddenFileInput = React.useRef(null);
    const [state, setState] = React.useState({
        image: ""
    })

    useEffect(() => {
        setFirstName(loggedInUser)
    }, [loggedInUser])

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        if (event.target.files && fileUploaded) {
            setState({
                image: URL.createObjectURL(fileUploaded)
            });
        }
    };

    return (
        <div className={hasMobile ? "mobile-account-background" : ""}>
            <div className={"page-account page-setting"}>
                <Header hasBack onBackPress={() => { navigate("/dashboard") }} title={"Account"} icon={""} />
                <div className={"account-detail"}>
                    <input ref={hiddenFileInput} type="file" style={{ display: 'none' }} onChange={handleChange} />
                    <div onClick={() => hiddenFileInput.current.click()}>
                        {
                            state?.image ?
                                <img id="target" src={state.image} style={{ width: 100, height: 100, borderRadius: 100 }} alt="profile" />
                                : CameraSvg(true)
                        }
                    </div>
                    <h2>{firstName}</h2>
                    {/* <span>Add stars, ratings etc</span> */}
                </div>
                <div className={"account-cards"}>
                    <Card title={"Profile"} type="profile" onClick={() => { navigate("/dashboard/profile") }} />
                    <Card src={require('../../assets/images/checkDollar.png')} title={"My plan"} type={"img"} onClick={() => { navigate("/dashboard/services") }} />
                    <Card title={"FAQs"} type={"faq"} onClick={() => {
                        window.open(`${process.env.REACT_APP_KNOWLEDGE_BASE_URL}`, "_blank");
                    }} />
                    <Card
                        title={"Contact us"}
                        type={"contact"}
                        onClick={() => {
                            navigate("/dashboard/contactus");
                        }}
                    />
                </div>
                <div className={"mt120"}>
                    <span></span>
                </div>
            </div>
        </div>
    )
}

export default Account


const Card = ({ src="", title, type, onClick }) => (
    <div className={"account-card"} onClick={onClick}>
      {type === "faq" ? (
        <FaqSvg />
      ) : type === "contact" ? (
        <ContactUsSvg />
      ) : type === "signout" ? (
        <SignOutSvg />
      ) : type ==="img" ? (
        <img src={src} alt={title} />
      ) : type === "profile" ? (
        <UserSvg fill={"#2A9B8D"} />
      ): ''}
      <span>{title}</span>
    </div>
  );