import React from "react";
import { ThreeDots } from "react-loader-spinner";

function AppButton({
  title = "",
  isLoading = false,
  isDisable = false,
  onClick,
  className = "",
  btnProps = {}
}) {
  return (
    <button
      data-testid={"appButton"}
      disabled={isLoading || isDisable}
      onClick={onClick}
      className={` 
        ${isLoading ? "no-hover" : ""} 
        ${isDisable === true ? "btn-container-disable" : "btn-container"} 
        ${className}`}
      style={{ marginBottom: 10 }}
      {...btnProps}
    >
      {isLoading ? (
        <ThreeDots
          color={className === "active" ? "#fff" : "#fff"}
          height={30}
          width={30}
        />
      ) : (
        title
      )}
    </button>
  );
}

export default AppButton;
