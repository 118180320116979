import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit'

export const selectedPickupPlan = createAsyncThunk(
    'services/selectedPickupPlan',
    async (pickupPlan) => {
        return pickupPlan
    })

const selectedPickupPlanSlice = createSlice({
    name: 'services',
    initialState: {
        pickupPlan: {},
        status: null,
    },
    extraReducers: {
        [selectedPickupPlan.pending]: (state, action) => {
            state.status = 'loading'
        },
        [selectedPickupPlan.fulfilled]: (state, { payload }) => {
            state.pickupPlan = payload
            state.status = 'success'
        },
        [selectedPickupPlan.rejected]: (state, action) => {
            state.status = 'failed'
        },
    },
})

export default selectedPickupPlanSlice.reducer
