import React, { useRef, useEffect, useCallback } from "react";
import AppButton from "../../../../components/AppButton";
import { useNavigate } from "react-router-dom";

const CancelationModal = ({ setCancelation }) => {
  const bar = useRef();
  const navigate = useNavigate();

  const CancelingSubs = useCallback(async () => {
    bar.current.style.width = "20%";
    // api
    setTimeout(() => {
      bar.current.style.width = "50%";
    }, 1000);

    setTimeout(() => {
      bar.current.style.width = "75%";
    }, 2500);

    setTimeout(() => {
      bar.current.style.width = "100%";
      if (bar.current.style.width === "100%") {
        navigate("/dashboard/cancelation-successful");
      }
    }, 4000);
  }, [navigate]);

  useEffect(() => {
    CancelingSubs();
  }, [CancelingSubs]);

  return (
    <div className={"modal-container-with-background"}>
      <div className="modal-design-popup-remove-credit-card">
        <div
          style={{ paddingTop: "70px", paddingBottom: "35px" }}
          className="d-flex flex-column justify-content-between align-items-center"
        >
          <div>
            <p className="password-updated-font">Canceling your account</p>
            <div style={{ paddingBottom: "35px" }} className="mt-4 px-3">
              <div
                className="progress"
                style={{ height: "25px", borderRadius: "20px 20px 20px 20px" }}
              >
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "0%" }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  ref={bar}
                ></div>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              setCancelation(false);
            }}
          >
            <AppButton title="Stop, don’t cancel" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelationModal;
