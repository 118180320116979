import React, { useEffect, useState } from "react";
import PaymentCard from "../../../components/PaymentCard";
import { FaPlusSquare } from "react-icons/fa";
import Detail from "../Detail";
import { HomeServiceSvg } from "../../../components/Svgs";
import { ThreeDots } from "react-loader-spinner";
import { updateBillingCardAPI } from "../../../api";
import ErrorModal from "../../../components/ErrorModal";
import { Link } from "react-router-dom";

import { OptimizelyFeature } from "@optimizely/react-sdk";
import useWindowDimensions from "../../../Hook/useWindowDimensions";

import { useSelector } from "react-redux";
import { string } from "yup";

function PaymentMethod({
  defaultId,
  isLoading = false,
  paymentCards = [],
  isActiveSection,
  hasMobile = false,
  fetchAgain = () => {},
  toggleBetweenAddresses = Object,
  activeAddress = string
}) {
  const { width } = useWindowDimensions();

  const isMobile = width <= 768;

  const [isActive, setIsActive] = React.useState("");

  const [detailSection, setDetailSection] = React.useState<any>(false);

  // @ts-ignore
  const userSub = useSelector((state) => state.getContactSubs.subscription);
  // @ts-ignore
  const selectedDeal = useSelector((state) => state.selectedDeal.deal);

  const [state, setState] = useState({
    isLoading: false,
    hasApiError: false,
    paymentCards: paymentCards
  });

  useEffect(() => {
    if (defaultId) {
      setIsActive(defaultId);
    }
    if (paymentCards) {
      setState((state) => ({ ...state, paymentCards }));
    }
  }, [defaultId, paymentCards]);

  const makeThisDefaultHandler = async (paymentCard) => {
    if (state.isLoading) return;
    try {
      setState({ ...state, isLoading: paymentCard.id });
      // @ts-ignore
      let res: any = await updateBillingCardAPI({
        isDefault: true,
        type: paymentCard.type,
        id: paymentCard.id,
        stripeCustomerId: paymentCard.customer,
        hubspotDealId: selectedDeal.dealId,
        isFranchised: selectedDeal.isFranchised
      });
      res = JSON.parse(res);
      if (res.success) {
        setIsActive(paymentCard.id);
        setState({ ...state, isLoading: false });
        const _paymentCards = [...state.paymentCards];
        const updatedCardIndex = _paymentCards.findIndex(
          (c) => c.id === paymentCard.id
        );
        _paymentCards[updatedCardIndex] = paymentCard;
        setState({
          ...state,
          paymentCards: _paymentCards
        });
        state.paymentCards.map((e, index) => {
          if (e.id === paymentCard.id) {
            const element = paymentCards.splice(index, 1)[0];
            paymentCards.splice(0, 0, element);
          }
          return 0;
        });
      } else {
        setState({
          ...state,
          isLoading: false,
          hasApiError: res?.message || "Something went wrong"
        });
      }
      setState({ ...state, isLoading: false });
    } catch (error) {
      // setState({ ...state, isLoading: false, hasApiError: true })
      setState({
        ...state,
        isLoading: false,
        hasApiError: error?.message || "Something went wrong"
      });
      // console.log(error)
    }
  };
  // const makeThisDefaultHandler = async(paymentCard)=>{
  //     if(state.isLoading) return
  //     try {
  //         setState({ ...state, isLoading: paymentCard.id })
  //         // @ts-ignore
  //         let res: any = await updateBillingCardAPI({isDefault: true,type: paymentCard.type,id: paymentCard.id,customerId:paymentCard.customer});
  //         res = JSON.parse(res);
  //         if (res.success) {
  //             setIsActive(paymentCard.id)
  //             setState({ ...state, isLoading: false })
  //             // alert("Successfully updated")
  //         } else {
  //             setState({ ...state, isLoading: false, hasApiError: res?.message || "Something went wrong" })
  //         }
  //         setState({ ...state, isLoading: false })

  //     } catch (error) {
  //         // setState({ ...state, isLoading: false, hasApiError: true })
  //         setState({ ...state, isLoading: false, hasApiError: error?.message || "Something went wrong" })
  //         // console.log(error)
  //     }
  //     setState({ ...state, isLoading: false })

  // }

  return (
    <section
      className={`${!isMobile ? "card-section-web section-bg-card" : ""} ${
        isActiveSection ? "active" : ""
      }`}
    >
      <ErrorModal
        isOpen={state.hasApiError}
        onClose={() => {
          setState({ ...state, hasApiError: false });
        }}
        message={state.hasApiError}
      />

      {!isMobile ? (
        <h3 className="w500" style={{ borderBottom: 0 }}>
          Payment methods
        </h3>
      ) : (
        ""
      )}

      {!detailSection ? (
        <>
          <div style={isMobile ? { marginLeft: 20, marginRight: 20 } : {}}>
            {userSub.length > 1 ? (
              <div className="contact-form-fields">
                <div className={"heading2 w500"}>
                  Select your service address
                </div>
                <div className={"address-cards"} style={{ paddingTop: "15px" }}>
                  {userSub.map((item, index) => (
                    <div
                      key={item.dealId}
                      className={`address-card-container ${
                        item.dealId === selectedDeal.dealId ? "active" : ""
                      }`}
                    >
                      <div
                        className={`address-card ${
                          item.dealId === selectedDeal.dealId ? "active" : ""
                        }`}
                        onClick={() => toggleBetweenAddresses(item.dealId)}
                      >
                        <div>
                          <HomeServiceSvg />
                        </div>
                        <label>{`Address ${index + 1}`}</label>
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  style={{ paddingTop: "15px" }}
                >{`${selectedDeal.serviceAddressDisplay}`}</div>
              </div>
            ) : (
              ""
            )}

            <div className="card-options-header">
              <div className={"heading2 w500"}>My cards</div>

              <OptimizelyFeature feature="add_card_button">
                {(enabled, variables) =>
                  enabled ? (
                    <Link
                      to={`/dashboard/services`}
                      state={{ paymentCards: state.paymentCards }}
                      className={"link"}
                    >
                      <FaPlusSquare style={{ marginRight: 10 }} />
                      Add Card
                    </Link>
                  ) : (
                    ""
                  )
                }
              </OptimizelyFeature>
            </div>
            {isLoading ? (
              <ThreeDots color="#3EB5AB" height={30} width={30} />
            ) : (
              <></>
            )}
          </div>
          <div className={"payment-cards-container"}>
            {state.paymentCards.map((paymentCard, i) => (
              <PaymentCard
                key={i}
                date={`${paymentCard.card.exp_month}/${paymentCard.card.exp_year}`}
                name={paymentCard.billing_details.name}
                number={paymentCard.card.last4}
                brand={paymentCard.card.brand}
                isActive={paymentCard.id === isActive}
                onClick={() => makeThisDefaultHandler(paymentCard)}
                onCardClick={() => setDetailSection(paymentCard)}
                isLoading={state.isLoading}
                {...paymentCard}
              />
            ))}
          </div>
        </>
      ) : (
        <Detail
          paymentCards={state.paymentCards}
          onBack={() => setDetailSection(false)}
          selectedCard={detailSection}
          isActive={isActive}
          fetchAgain={fetchAgain}
        />
      )}
    </section>
  );
}

export default PaymentMethod;
