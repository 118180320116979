import React, { useEffect, useState } from 'react'
import PaymentCard from '../../../components/PaymentCard'
import { MdDelete } from "react-icons/md";
import AppButton from '../../../components/AppButton';
import AppInput from '../../../components/AppInput';
import DeleteCreditCard from './DeleteCredCard'
import { removeCreditCard, updateBillingCardAPI } from '../../../api'
import ErrorModal from '../../../components/ErrorModal';
import { useSelector } from "react-redux";

function Detail({ onBack, selectedCard, isActive, paymentCards, fetchAgain }) {

    const [isEdit, setIsEdit] = React.useState<any>(false)
    const [cardInfo, setCardInfo] = React.useState({ ...selectedCard })
    const [customerId, setCustomerId] = useState('')
    const [enableModal, setEnableModal] = useState(false);
    const [creditCardRemoved, setCreditCardRemoved] = useState(false);
    const [creditCardRemovedError, setCreditCardRemovedError] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [cardId, setCardId] = useState('')

    // @ts-ignore
    // const userSub = useSelector((state) => state.getContactSubs.subscription);
    // @ts-ignore
    const selectedDeal = useSelector((state) => state.selectedDeal.deal);

    const [state, setState] = useState({
        isLoading: false,
        updated: false,
        hasApiError: false
    })


    useEffect(() => {
        setCardId(cardInfo.id)
        setCustomerId(cardInfo.customer)
    }, [cardInfo.id, cardInfo.customer])


    const openModalHandler = () => {
        setEnableModal(true)
    }

    const removeCreditCardHandler = async () => {
        setButtonLoading(true)

        if (customerId) {
            let deleteBody = {
                data: {
                    paymentMethodId: cardId,
                    isFranchised: selectedDeal.isFranchised
                }
            }
            try {
                // get payment methods
                let res = await removeCreditCard(customerId, deleteBody)

                setCreditCardRemovedError(false)

                if (res && res.success) {
                    setCreditCardRemoved(true)
                    setButtonLoading(false)
                    setTimeout(() => {
                        setEnableModal(false)
                        setCreditCardRemoved(false)
                        window.location.reload();
                    }, 3000)
                } else {
                    setCreditCardRemoved(true)
                    setCreditCardRemovedError(true)
                    setButtonLoading(false)
                    setTimeout(() => {
                        setEnableModal(false)
                        setCreditCardRemoved(false)
                    }, 4000)
                }

            } catch (error) {
                return {
                    error: `Error with removing customer's credit card: ${error}`
                }
            }
        } else {
            alert('Error occured. Please try again or refresh page')
            setButtonLoading(false)
        }

    }

    const onUpdate = async () => {

        const updateDataObj = {
            type: cardInfo.type,
            id: cardInfo.id,
            stripeCustomerId: cardInfo.customer,
            hubspotDealId: selectedDeal.dealId,
            line1: cardInfo.billing_details.address.line1,
            "line2": cardInfo.billing_details.address.line2,
            "city": cardInfo.billing_details.address.city,
            "state": cardInfo.billing_details.address.state,
            "postalCode": cardInfo.billing_details.address.postal_code,
            name: cardInfo.billing_details.name,
            month: cardInfo.card.exp_month,
            year: cardInfo.card.exp_year,
            isFranchised: selectedDeal.isFranchised
        }

        try {
            setState({ ...state, isLoading: true })
            let res: any = await updateBillingCardAPI(updateDataObj);
            res = JSON.parse(res);
            if (res.success) {
                fetchAgain(cardInfo)
                setState({ ...state, isLoading: false, updated: true })
                setTimeout(() => {
                    setState({ ...state, updated: false })
                }, 3000);
            } else {
                setState({ ...state, isLoading: false, hasApiError: res?.message || "Something went wrong" })
            }

        } catch (error) {
            setState({ ...state, isLoading: false, hasApiError: error?.message || "Something went wrong" })
            console.log(error)
        }
    }

    return (
        <div>
            {enableModal ? <DeleteCreditCard buttonLoading={buttonLoading} creditCardRemovedError={creditCardRemovedError} last4Digits={cardInfo.card.last4} setEnableModal={setEnableModal} removeCreditCardHandler={removeCreditCardHandler} creditCardRemoved={creditCardRemoved} /> : ''}
            <ErrorModal
                isOpen={state.hasApiError}
                onClose={() => { setState({ ...state, hasApiError: false }) }}
                message={state.hasApiError}
            />
            <div className="card-options-header">
                <div style={{ cursor: "pointer" }} className={"heading2 w500"} onClick={onBack}>
                    <span className='p20'>{`<`}</span>{`Card ending with ${cardInfo.card.last4}`}
                </div>
                <span className={"link"}>
                    {/* {
                        !isEdit ?
                            // <abb
                            <MdEdit title="test" onClick={() => { setIsEdit(true) }} />
                            : <></>
                    } */}
                    {(paymentCards.length > 1 && cardInfo.id !== isActive) ? <MdDelete onClick={() => openModalHandler()} /> : ''}
                </span>
            </div>
            <div className={"payment-cards-container"}>
                <PaymentCard
                    id={cardInfo.id}
                    date={`${cardInfo.card.exp_month}/${cardInfo.card.exp_year}`}
                    name={cardInfo.billing_details.name}
                    number={cardInfo.card.last4}
                    brand={cardInfo.card.brand}
                    isActive={cardInfo.id === isActive}
                    onClick={() => { }}
                    onCardClick={() => { }}
                    hideMonster
                    hasShadow
                />
                <div className={"payment-card payment-cards-data-group"}>
                    <div className={"payment-card-data-group"}>
                        <label>Card number</label>
                        {
                            // isEdit?
                            // <AppInput
                            //     label = {""}
                            //     // type = {"password"}
                            //     placeholder = {"Card Number"}
                            //     value = {`**** **** **** ${cardInfo?.card.last4}`}
                            //     // onChange = {(e)=>{setCardInfo({...cardInfo, : e.target.value})}}
                            //     onChange = {(e)=>{setCardInfo({...cardInfo,card:{...cardInfo.card,number: e.target.value}})}}
                            // />
                            // :
                            // <span>{cardInfo.number}</span>
                            <span><span className='asterik'>**** **** ****</span> {cardInfo?.card.last4}</span>
                        }
                    </div>
                    <div className={"payment-card-data-group"}>
                        <label>Card holder</label>
                        {
                            isEdit ?
                                <AppInput
                                    label={""}
                                    placeholder={"Card Holder"}
                                    value={cardInfo?.billing_details?.name}
                                    onChange={(e) => { setCardInfo({ ...cardInfo, billing_details: { ...cardInfo.billing_details, name: e.target.value } }) }}
                                />
                                :
                                <span>{cardInfo?.billing_details.name}</span>
                        }
                    </div>
                    {!isEdit &&
                        <div className={"payment-card-data-group"}>
                            <label>CVV</label>
                            {
                                // isEdit?
                                // <AppInput
                                //     label = {""}
                                //     placeholder = {"CVV"}
                                //     // type = {"password"}
                                //     value = {cardInfo?.cvv || "***"}
                                //     // onChange = {(e)=>{setCardInfo({...cardInfo,cvv: e.target.value})}}
                                //     onChange = {(e)=>{setCardInfo({...cardInfo,card:{...cardInfo.card,cvv: e.target.value}})}}
                                // />
                                // :
                                <span className='asterik'>{cardInfo?.card?.cvv || "***"}</span>
                            }
                        </div>
                    }
                    <div className={"payment-card-data-group"}>
                        <label>Exp date</label>
                        {
                            isEdit ?
                                <span style={{ display: "inherit" }}>
                                    <AppInput
                                        label={""}
                                        type={"select"}
                                        placeholder={"Month"}
                                        value={cardInfo?.card?.exp_month || ""}
                                        onChange={(e: any) => {
                                            setCardInfo({ ...cardInfo, card: { ...cardInfo.card, exp_month: e.target.value } })
                                        }}
                                    />
                                    <AppInput
                                        label={""}
                                        type={"select"}
                                        placeholder={"Year"}
                                        value={cardInfo?.card?.exp_year || ""}
                                        onChange={(e) => { setCardInfo({ ...cardInfo, card: { ...cardInfo.card, exp_year: e.target.value } }) }}
                                    // onChange = {(e)=>{setCardInfo({...cardInfo,date: e.target.value})}}
                                    />

                                    {/* <AppInput
                                label = {""}
                                type = {"month"}
                                placeholder = {"Expire Date"}
                                value = {cardInfo?.card?.date || ""}
                                onChange = {(e)=>{setCardInfo({...cardInfo,card:{...cardInfo.card,date: e.target.value}})}}
                                // onChange = {(e)=>{setCardInfo({...cardInfo,date: e.target.value})}}
                            /> */}
                                </span>
                                :
                                <span>{cardInfo?.card?.exp_month || ""}/{cardInfo?.card?.exp_year}</span>
                        }
                    </div>
                    <div className={"payment-card-data-group"}>
                        <label style={{ alignSelf: "baseline" }}>Billing address</label>
                        {
                            isEdit === "address" ?
                                <label>
                                    <AppInput
                                        label={""}
                                        placeholder={"Line 1"}
                                        value={cardInfo?.billing_details?.address?.line1}
                                        onChange={(e) => { setCardInfo({ ...cardInfo, billing_details: { ...cardInfo.billing_details, address: { ...cardInfo.billing_details.address, line1: e.target.value } } }) }}
                                    />
                                    <AppInput
                                        label={""}
                                        placeholder={"Line 2"}
                                        value={cardInfo?.billing_details?.address?.line2}
                                        // onChange={(e) => { setCardInfo({ ...cardInfo, billing_details: { ...cardInfo.billing_details, line2: e.target.value } }) }}
                                        onChange={(e) => { setCardInfo({ ...cardInfo, billing_details: { ...cardInfo.billing_details, address: { ...cardInfo.billing_details.address, line2: e.target.value } } }) }}
                                    />

                                    <AppInput
                                        label={""}
                                        placeholder={"City"}
                                        value={cardInfo?.billing_details?.address?.city}
                                        onChange={(e) => { setCardInfo({ ...cardInfo, billing_details: { ...cardInfo.billing_details, address: { ...cardInfo.billing_details.address, city: e.target.value } } }) }}
                                    />
                                    <AppInput
                                        label={""}
                                        type={"select"}
                                        placeholder={"State"}
                                        inputStyle={{ width: "100%" }}
                                        value={cardInfo?.billing_details?.address?.state}
                                        onChange={(e) => { setCardInfo({ ...cardInfo, billing_details: { ...cardInfo.billing_details, address: { ...cardInfo.billing_details.address, state: e.target.value } } }) }}
                                    />
                                    <AppInput
                                        label={""}
                                        placeholder={"Postal Code"}
                                        value={cardInfo?.billing_details?.address?.postal_code}
                                        onChange={(e) => { setCardInfo({ ...cardInfo, billing_details: { ...cardInfo.billing_details, address: { ...cardInfo.billing_details.address, postal_code: e.target.value } } }) }}
                                    />
                                    {/* <AppInput
                                            label={""}
                                            placeholder={"Address"}
                                            value={cardInfo?.billing_details?.address?.line1 + ` ${cardInfo?.billing_details?.address?.line2}, ${cardInfo?.billing_details?.address?.city}, ${cardInfo?.billing_details?.address?.postal_code}, ${cardInfo?.billing_details?.address?.state}`}
                                            onChange={(e) => { setCardInfo({ ...cardInfo, billing_details: { ...cardInfo.billing_details, line1: e.target.value } }) }}
                                        /> */}
                                </label>
                                :
                                <span className='edit-span'>
                                    <span>
                                        <p>
                                            {cardInfo?.billing_details?.address?.line1 ? cardInfo?.billing_details?.address?.line1 + ", " : ""}{cardInfo?.billing_details?.address?.line2 ? ` ${cardInfo.billing_details.address.line2}, ` : ""}
                                        </p>

                                        <p>
                                            {`${cardInfo?.billing_details?.address?.city ? cardInfo?.billing_details?.address?.city + ", " : ""}`}{`${cardInfo?.billing_details?.address?.state ? cardInfo?.billing_details?.address?.state + ", " : ""}`}
                                        </p>

                                        <p>
                                            {`${cardInfo?.billing_details?.address?.postal_code ? cardInfo?.billing_details?.address?.postal_code : ""}`}
                                        </p>
                                        {Object.values(cardInfo.billing_details.address).every(x => x === null || x === '') &&
                                            <p style={{ color: "#C4C4C4" }}>No billing address found</p>
                                        }
                                    </span>
                                    {/* {
                                            // 32 Circa Av,Utah, 23344
                                            isEdit ?
                                                <span onClick={() => setIsEdit("address")} className={"link"} style={{ marginInlineStart: 10 }}><MdEdit onClick={() => { setIsEdit(true) }} /></span>
                                                : <></>
                                        } */}
                                </span>
                        }
                    </div>
                </div>
            </div>
            {
                isEdit ?
                    <div style={{ marginTop: 40 }}>
                        <AppButton title={state.updated ? "Saved" : "Save"} className={"active"} onClick={onUpdate} isLoading={state.isLoading} />
                        <AppButton title={"Cancel"} onClick={() => setIsEdit(false)} />
                    </div>
                    : <></>
            }
        </div>
    )
}

export default Detail