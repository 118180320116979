import RadeemCancel from "../CancelTabs/RadeemCancel";

const PickupFrequently = () => {
  const heading = "Apologies for inconvenience";
  const paragraphOne =
    "We are sorry you experienced incovenience with our services.";
  const paragraphTwo = "Would it help if we give you a month of free service?";
  const btnOne = "Radeem offer";
  const btnTwo = "No, Continue Cancellation";

  return (
    <RadeemCancel
      heading={heading}
      paragraphOne={paragraphOne}
      paragraphTwo={paragraphTwo}
      btnOne={btnOne}
      btnTwo={btnTwo}
    />
  );
};

export default PickupFrequently;
