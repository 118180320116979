import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    pickupFrequency: ''
}

export const getPickupFrequencySlice = createSlice({
    name: "pickupFrequency",
    initialState,
    reducers: {
        setPickupFrequency: (state, { payload }) => {
            state.pickupFrequency = payload
        },
    },

})


export const { setPickupFrequency} = getPickupFrequencySlice.actions
export default getPickupFrequencySlice.reducer