import Auth from "@aws-amplify/auth";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

/**
 * Login user Api
 */
export const login = createAsyncThunk(
  "auth/login",
  async (data: any, thunkAPI) => {
    try {
      // const user = await Auth.signIn(data.email, data.password);

      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        // localStorage.setItem("user", JSON.stringify(user))
        return user;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get user from Locl storage
// const user = JSON.parse(localStorage.getItem('user'));

// initial state
const initialState = {
  user: null,
  token: null,
  isLoading: false,
  errors: ""
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.isLoading = false;
      state.token = null;
      state.user = null;
      state.errors = "";
    },
    setUser: (state, { payload }) => {
      state.isLoading = false;
      state.user = payload;
      state.errors = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.errors = "";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload?.attributes;
        state.errors = "";
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action?.payload || "";
      });
  }
});

export const { logout, setUser } = authSlice.actions;
export default authSlice.reducer;
