import React, { Component } from "react";
import CheckAvailabilityForm from "./checkAvailabilityForm";
// import PlanSelection from '../plan_selection/planSelection';
// import dummyData from '../../dummyData';
// import AddOnServices from '../add_on_services/addOnServices';
// import SignUpForm from '../signup_form/signUpForm';
import { googleGeocode } from "../google_geo_code/googleGeocode";
import stateSelections from "../stateSelections/stateSelections";
// import CustomerExist from '../customer_exist/customerExist';
import { locationByAddress } from "../../api";
import NoServiceMsg from "./noServiceMsg";
import { connect } from "react-redux";
import { returningCustomerParams } from "../../helpers";

class CheckAvailabilityContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerType: "residential",
      firstName: "",
      lastName: "",
      email: "",
      checkboxOffers: false,
      emailConfirmation: "",
      doesEmailMatch: false,
      phoneNumber: "",
      checkboxTextingOff: false,

      signUpFormCompleted: false,
      billingFormButtonEnabled: false,

      lat: "",
      lng: "",

      services: [],
      setupCost: 0,
      address: "",
      streetAddress1: "",
      streetAddress2: "",
      specialInstructions: "",
      zipCode: "",
      cityName: "",
      stateName: "",
      stateNameAbrev: "",
      countyName: "",

      // checkAvailabilityDisabled: true,

      serviceSelectedId: {},
      serviceSelectedVal: "",
      serviceSelected: false,

      selectedAddOnArray: [],
      selectedAddOnArrayKey: [],
      noOfSelectedAddOns: 0,

      continueToSignupClicked: false, //
      isWithinServicedLocation: "",
      updateServicePlan: false,

      howDidYouHearAboutUs: "",
      howDidYouHearAboutUsOther: "",
      couponCode: "",

      sameBillingAddress: false,

      openSignupForm: true,
      openServiceAddressForm: false,
      openAdditionInfoForm: false,

      allRequiredFieldsCompleted: false,
      stepOneRes: false,
      billingIntent: false,
      isLoading: false,

      locationId: "",
      zoneId: "",
      routeDay: "",

      customerExistHubspot: {
        addressActive: false,
        addressExists: false,
        contactId: "",
        dealId: "",
        emailExists: false
      },

      validatingPhoneNmbr: false,
      validatingEmail: false,
      validatingEmailConfirmation: false,

      emailValidated: false,
      phoneNumberValidated: false,

      creditCardName: "",

      billingSetupIntentRan: 0,

      enableContinueBtnServicePlan: false,

      isReturningCustomer: false,

      pageLoading: false,

      stripeCustomerId: "",
      hubspotDealId: "",
      changePlanClicked: false
    };
  }

  componentDidMount = async () => {
    const { checkAvailabilityClickedHandle, zoneIdHandle, locationIdHandle } =
      this.props;

    let customerDataParams = returningCustomerParams();

    if (customerDataParams?.isReturningCustomer) {
      this.setState({ pageLoading: true });

      let addressStr = customerDataParams.address2
        ? `${customerDataParams.address1} ${customerDataParams.address2} ${customerDataParams.locationName}, ${customerDataParams.serviceState} ${customerDataParams.postalCode}`
        : `${customerDataParams.address1} ${customerDataParams.locationName}, ${customerDataParams.serviceState} ${customerDataParams.postalCode}`;
      let geoCodeResults = await this.googleGeocodeHandle(addressStr);

      if (geoCodeResults === "success") {
        locationByAddress(
          this.state.lat,
          this.state.lng,
          this.state.stateName,
          (res) => {
            if (res?.services?.length > 0) {
              // setupCost
              checkAvailabilityClickedHandle(true);

              this.setState({
                isWithinServicedLocation: true,
                services: res.services,
                locationId: res.locationId,
                zoneId: res.zoneId
              });

              zoneIdHandle({
                type: "ZONE_ID",
                zoneId: res.zoneId
              });

              locationIdHandle({
                type: "LOCATION_ID",
                locationId: res.locationId
              });

              this.setState({
                isReturningCustomer: true,
                firstName: customerDataParams.firstName,
                lastName: customerDataParams.lastName,
                email: customerDataParams.email,
                emailConfirmation: customerDataParams.email,
                doesEmailMatch: true,
                howDidYouHearAboutUs: "Other",
                howDidYouHearAboutUsOther: "D2D",
                signUpFormFilled: true,
                validatingEmailConfirmation: true
              });
            } else {
              checkAvailabilityClickedHandle(false);
              this.setState({
                isWithinServicedLocation: false
              });
            }
          }
        );
      } else {
        this.setState({ pageLoading: false });
      }
    }
  };

  // phoneNumberErrorHandling = e => {
  //   this.setState({ validatingPhoneNmbr: true });
  // };
  // emailErrorHandling = e => {
  //   this.setState({ validatingEmail: true });
  // };
  // emailConfirmationErrorHandling = e => {
  //   this.setState({ validatingEmailConfirmation: true });
  // };

  // customerTypeHandle = event => {
  //   this.setState({
  //     customerType: event.target.value,
  //   });
  // };

  // firstNameHandle = event => {
  //   const firstName = event.target.value;
  //   this.setState({ firstName });
  //   this.props.setFormState({ firstName: firstName ?? '' });
  // };

  // lastNameHandle = event => {
  //   const lastName = event.target.value;
  //   this.setState({ lastName });
  //   this.props.setFormState({ lastName: lastName ?? '' });
  // };

  // emailHandle = event => {
  //   const email = event.target.value.trim();
  //   this.setState({
  //     email,
  //     validatingEmail: true,
  //   });
  //   this.props.setFormState({ email: email ?? '' });
  // };

  // emailConfirmationHandle = event => {
  //   this.setState({
  //     emailConfirmation: event.target.value.trim(),
  //     validatingEmailConfirmation: true,
  //   });
  // };

  // checkboxOffersHandle = () => {
  //   const { checkboxOffers } = this.state;
  //   this.setState({
  //     checkboxOffers: !checkboxOffers,
  //   });
  // };

  // phoneNumberHandle = event => {
  //   const phone = event.target.value;

  //   this.setState({
  //     phoneNumber: phone,
  //     validatingPhoneNmbr: true,
  //   });

  //   this.props.setFormState({ phone: phone ?? '' });
  // };

  // checkboxTextingOffHandle = () => {
  //   const { checkboxTextingOff } = this.state;
  //   this.setState({
  //     checkboxTextingOff: !checkboxTextingOff,
  //   });
  // };

  addressHandle = (address) => {
    //  after google provides address, seperate street address, zip, etc
    this.setState({ address });
  };

  streetAddress2Handle = (event) => {
    this.setState({ streetAddress2: event.target.value });
  };

  zipCodeHandle = (event) => {
    this.setState({
      zipCode: event.target.value
    });
  };

  // selectedBaseServiceHandle = (key, data) => {
  //   this.setState({
  //     serviceSelectedVal: data,
  //     serviceSelectedId: key,
  //     enableContinueBtnServicePlan: true,
  //   });
  // };

  // continueAfterSelectedBaseServiceHandle = (key, data) => {
  //   // window.scrollTo(0, 300);
  //   const { serviceSelectedVal, serviceSelectedId } = this.state
  //   if (serviceSelectedVal && serviceSelectedId) {
  //     this.setState({
  //       serviceSelected: true,
  //     });
  //   } else {
  //     this.setState({
  //       enableContinueBtnServicePlan: false
  //     })
  //   }
  // };

  // selectedAddOnServiceHandle = (key, data) => {
  //   const { selectedAddOnArray, noOfSelectedAddOns, selectedAddOnArrayKey } =
  //     this.state;

  //   let arrOfSelectedAddOns = [...selectedAddOnArray];
  //   let arrOfSelectedAddOnArrayKey = [...selectedAddOnArrayKey];

  //   if (!arrOfSelectedAddOnArrayKey.includes(key)) {
  //     arrOfSelectedAddOns.push(data);
  //     arrOfSelectedAddOnArrayKey.push(key);

  //     this.setState({
  //       selectedAddOnArray: arrOfSelectedAddOns,
  //       selectedAddOnArrayKey: arrOfSelectedAddOnArrayKey,
  //       noOfSelectedAddOns: noOfSelectedAddOns + 1, // to keep track of how many are selected
  //     });
  //   }
  // };

  // unSelectedAddOnServiceHandle = (key, data) => {
  //   const { selectedAddOnArray, noOfSelectedAddOns, selectedAddOnArrayKey } = this.state;

  //   // let arrOfSelectedAddOns = [...selectedAddOnArray];
  //   let arrOfSelectedAddOnArrayKey = [...selectedAddOnArrayKey];

  //   if (arrOfSelectedAddOnArrayKey.length > 0) {
  //     // arrOfSelectedAddOns.splice(selectedAddOnArray.indexOf(data), 1);
  //     const arrOfSelectedAddOns = selectedAddOnArray.filter(
  //       s => s.serviceType !== key,
  //     );
  //     arrOfSelectedAddOnArrayKey.splice(selectedAddOnArrayKey.indexOf(key), 1);

  //     this.setState({
  //       selectedAddOnArray: arrOfSelectedAddOns,
  //       selectedAddOnArrayKey: arrOfSelectedAddOnArrayKey,
  //       noOfSelectedAddOns: noOfSelectedAddOns - 1, // to keep track of how many are selected
  //     });
  //   }
  // };

  // moveOnePageBackHandle = () => {
  //   this.setState({
  //     serviceSelected: false,
  //   });
  // };

  // continueToSignupFormHandle = () => {
  //   window.scrollTo(0, 0);
  //   const { customerIsOnFormsHandle } = this.props;
  //   customerIsOnFormsHandle('');
  //   this.setState({
  //     continueToSignupClicked: true,
  //   });
  // };

  // updateServicePlanHandle = () => {
  //   // window.scrollTo(0, 0);
  //   const { customerIsOnFormsHandle } = this.props;
  //   customerIsOnFormsHandle('');
  //   this.setState({
  //     changePlanClicked: true,
  //     continueToSignupClicked: false,
  //     serviceSelected: false,

  //     signUpFormCompleted: false,
  //     openSignupForm: true,
  //     // stepOneRes: false,
  //     // billingFormButtonEnabled: false,
  //     openServiceAddressForm: false,
  //     openAdditionInfoForm: false,
  //     // customerDealExist: false,

  //     // customerExistHubspot: {
  //     //   addressActive: false,
  //     //   addressExists: false,
  //     //   contactId: '',
  //     //   dealId: '',
  //     //   emailExists: false,
  //     // },
  //   });
  // };

  updateAddressHandle = () => {
    window.location.reload(true);
  };

  specialInstructionsHandle = (event) => {
    this.setState({
      specialInstructions: event.target.value
    });
  };

  // howDidYouHearAboutUsHandle = event => {
  //   this.setState({
  //     howDidYouHearAboutUs: event.target.value,
  //   });
  // };

  // couponCodeHandle = event => {
  //   this.setState({
  //     couponCode: event.target.value,
  //   });
  // };

  // howDidYouHearAboutUsOtherHandle = event => {
  //   this.setState({
  //     howDidYouHearAboutUsOther: event.target.value,
  //   });
  // };

  // initialSignUpFormCompletedHandler = e => {

  //   e.preventDefault();
  //   this.billingSetupHandle((intent = false) => {
  //     if (intent) {
  //       this.setState({ stripeCustomerId: intent.stripeCustomerId })
  //     }
  //   });

  //   const {
  //     customerType,
  //     firstName,
  //     lastName,
  //     email,
  //     stateNameAbrev,
  //     emailConfirmation,
  //     phoneNumber,
  //     howDidYouHearAboutUs,
  //     serviceSelectedId,
  //     serviceSelectedVal,
  //     locationId,
  //     streetAddress1,
  //     streetAddress2,
  //     specialInstructions,
  //     changePlanClicked
  //   } = this.state;

  //   let emailMatches = false;
  //   if (email === emailConfirmation) {
  //     emailMatches = true;
  //   }
  //   // check for email charactors
  //   const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  //   // check to see if phone number has 10 digits and has optional dashes
  //   const onlyHasTenDigits = /^[0-9]{10}$/
  //   const isNumberWithDash = /^(1-)?\d{3}-\d{3}-\d{4}$/

  //   if (isNumberWithDash.test(phoneNumber) || onlyHasTenDigits.test(phoneNumber)) {
  //     this.setState({
  //       phoneNumberValidated: true,
  //     });
  //   }

  //   let emailValidation = false;
  //   if (regex.test(email)) {
  //     emailValidation = true;
  //   }

  //   if (emailValidation && emailMatches) {
  //     this.setState({
  //       emailValidated: true,
  //     });
  //   }

  //   if (
  //     customerType.length > 0 &&
  //     firstName.length > 0 &&
  //     lastName.length > 0 &&
  //     howDidYouHearAboutUs.length > 0
  //   ) {
  //     const selectedKey = Object.keys(serviceSelectedId)[0] || '';

  //     const apiData = {
  //       hubspotDealId: this.state.hubspotDealId,
  //       stripeCustomerId: this.state.stripeCustomerId,
  //       howDidYouHearAboutUs: this.state.howDidYouHearAboutUs,
  //       howDidYouHearAboutUsOther: this.state.howDidYouHearAboutUsOther,
  //       specialInstructions: specialInstructions,
  //       firstName: firstName,
  //       lastName: lastName,
  //       email: email,
  //       phone: phoneNumber,
  //       customerType: customerType,
  //       doNotText: false,
  //       locationId: locationId,
  //       serviceAddress: {
  //         line1: streetAddress1,
  //         line2: streetAddress2,
  //         city: this.state.cityName,
  //         state: stateNameAbrev,
  //         postalCode: this.state.zipCode,
  //         longitude: this.state.lng,
  //         latitude: this.state.lat,
  //       },
  //       promoCode: this.state.couponCode,
  //       pickupFrequency: this.props.pickupFrequency,
  //       paymentFrequency: selectedKey,
  //       servicesSelected: [
  //         ...this.state.selectedAddOnArrayKey,
  //         serviceSelectedVal.serviceType,
  //       ],
  //     };
  //     //
  //     this.setState({ isLoading: true });

  //     signupStepOne(apiData, res => {
  //       if (res?.hubspotContactId) {
  //         // window.scrollTo(0, 0);
  //         window.scrollTo(0, 0);
  //         this.setState({
  //           // isLoading: false,
  //           stepOneRes: res,
  //           hubspotDealId: res.hubspotDealId
  //         });
  //       } else {
  //         this.setState({ isLoading: false });
  //       }
  //     });

  //     // enables customer to make updates via signupStepOne and
  //     // will allow customer to continue to billing page
  //     if (changePlanClicked) {
  //       this.toggleBetweenPagesHandler()
  //     }
  //   }

  // };

  // billingSetupHandle = (initialSignUpCompleteCB = false) => {
  //   const {
  //     firstName,
  //     lastName,
  //     email,
  //     streetAddress1,
  //     streetAddress2,
  //     cityName,
  //     stateNameAbrev,
  //     zipCode,
  //     serviceSelectedId,
  //     locationId,
  //     billingSetupIntentRan,
  //   } = this.state;

  //   const selectedKey = Object.keys(serviceSelectedId)[0] || '';

  //   // this.setState({ isLoading: true })

  //   const apiData = {
  //     firstName: firstName,
  //     lastName: lastName,
  //     email: email,
  //     serviceAddress: {
  //       line1: streetAddress1,
  //       line2: streetAddress2,
  //       city: cityName,
  //       state: stateNameAbrev,
  //       postalCode: zipCode,
  //     },
  //     pickupFrequency: this.props.pickupFrequency,
  //     paymentFrequency: selectedKey,
  //     newCustomer: true,
  //     locationId: locationId,
  //   };

  //   // billingSetupIntent seems to call twice so for now, billingSetupIntentRan was created to only run once
  //   if (billingSetupIntentRan <= 0) {
  //     billingSetupIntent(apiData, res => {
  //       if (res?.stripeCustomerId) {
  //         // this.openServiceAddressFormHandle();
  //         window.scrollTo(0, 0);
  //         this.setState({
  //           billingIntent: res,
  //           isLoading: false,
  //           signUpFormCompleted: true,
  //           openSignupForm: true,
  //           openServiceAddressForm: false,
  //           openAdditionInfoForm: false,
  //           billingFormButtonEnabled: true,
  //           billingSetupIntentRan: 1,
  //         });
  //         if (initialSignUpCompleteCB) {
  //           initialSignUpCompleteCB(res)
  //         }
  //       } else {
  //         this.setState({ isLoading: false });
  //         if (initialSignUpCompleteCB) {
  //           initialSignUpCompleteCB(false)
  //         }
  //         // alert('Something went wrong');
  //       }
  //     });
  //   }
  // };

  // toggleBetweenPagesHandler = () => {
  //   const {
  //     signUpFormCompleted,
  //     customerType,
  //     firstName,
  //     lastName,
  //     howDidYouHearAboutUs,
  //     phoneNumberValidated,
  //     emailValidated,
  //   } = this.state;

  //   if (
  //     customerType.length > 0 &&
  //     firstName.length > 0 &&
  //     lastName.length > 0 &&
  //     emailValidated &&
  //     phoneNumberValidated &&
  //     // emailMatches &&
  //     // phoneNumber.length >= 10 &&
  //     howDidYouHearAboutUs.length > 0
  //   ) {
  //     this.setState({
  //       signUpFormCompleted: !signUpFormCompleted,
  //       openSignupForm: true,
  //       openServiceAddressForm: false,
  //       openAdditionInfoForm: false,
  //     });
  //   }
  // };

  // sameBillingAddressHandle = () => {
  //   const { sameBillingAddress } = this.state;
  //   this.setState({
  //     sameBillingAddress: !sameBillingAddress,
  //   });
  // };

  // openSignupFormHandle = () => {
  //   this.setState({
  //     openSignupForm: true,
  //     openServiceAddressForm: false,
  //     openAdditionInfoForm: false,
  //   });
  // };

  // openServiceAddressFormHandle = () => {
  //   this.setState({
  //     openSignupForm: false,
  //     openServiceAddressForm: true,
  //     openAdditionInfoForm: false,
  //   });
  // };

  // openAdditionInfoFormHandle = () => {
  //   this.setState({
  //     openSignupForm: false,
  //     openServiceAddressForm: false,
  //     openAdditionInfoForm: true,
  //   });
  // };

  googleLocationSearchHandle = (address) => {
    this.setState({
      address: address
    });
  };

  googleGeocodeHandle = async (address) => {
    const { streetAddress2 } = this.state;
    try {
      let geocode = await googleGeocode(address);
      const { lat, lng } = geocode[0].geometry.location;
      let toParseAddress = geocode[0].address_components;

      let streetNumber = "";
      let streetAddress = "";
      let streetAddressTwo = streetAddress2;
      let cityName = "";
      let stateName = "";
      let zipCode = "";

      for (let i = 0; i < toParseAddress.length; i++) {
        let addressType = toParseAddress[i].types;

        //  required
        if (addressType[0] === "street_number") {
          streetNumber = toParseAddress[i].long_name;
        }
        if (addressType[0] === "route") {
          streetAddress = toParseAddress[i].long_name;
        }
        if (addressType[0] === "subpremise") {
          streetAddressTwo = toParseAddress[i].long_name;
        }
        //  required
        if (addressType[0] === "locality") {
          cityName = toParseAddress[i].long_name;
        }
        //  required
        if (addressType[0] === "administrative_area_level_1") {
          stateName = toParseAddress[i].long_name;
        }
        //  required
        if (addressType[0] === "postal_code") {
          zipCode = toParseAddress[i].long_name;
        }
      }
      // required address properties to be determined as an address
      let stateNameAbrev = "";
      for (let i = 0; i < stateSelections.length; i++) {
        if (stateSelections[i].label === stateName) {
          stateNameAbrev = stateSelections[i].value;
        }
      }

      if (
        streetAddress.length > 0 &&
        cityName.length > 0 &&
        stateName.length > 0 &&
        zipCode.length > 0
      ) {
        this.setState({
          lat,
          lng,
          streetAddress1: `${streetNumber} ${streetAddress}`,
          streetAddress2: streetAddressTwo,
          zipCode,
          cityName,
          stateName,
          stateNameAbrev
          // isWithinServicedLocation: true,
        });
        return "success";
      } else {
        // if address is updated and service is not in location, reset to default
        this.setState({
          isWithinServicedLocation: false,
          lat: "",
          lng: "",
          streetAddress1: ``,
          streetAddress2: "",
          zipCode: "",
          cityName: "",
          stateName: "",
          stateNameAbrev: ""
        });
        return "";
      }
    } catch (e) {
      // console.error('error', e)
      return null;
    }
  };

  getServicesByAddress = () => {
    const { checkAvailabilityClickedHandle, zoneIdHandle, locationIdHandle } =
      this.props;
    const { lat, lng, stateName, zipCode, streetAddress1 } = this.state;

    if (lat.length === 0 && lng.length === 0) {
      checkAvailabilityClickedHandle(false);
      this.setState({
        isWithinServicedLocation: false
      });
    } else {
      let doesCustomerExistObj = {
        serviceAddress: {
          line1: streetAddress1, //'line310',
          postalCode: zipCode, //'84005',
          longitude: lng, //'30.0771815',
          latitude: lat //'-94.1701341',
        }
      };

      this.ifCustomerExist(doesCustomerExistObj);

      locationByAddress(lat, lng, stateName, (res) => {
        if (res?.services?.length > 0) {
          // setupCost
          checkAvailabilityClickedHandle(true);

          this.setState({
            isWithinServicedLocation: true,
            services: res.services,
            locationId: res.locationId,
            zoneId: res.zoneId
          });

          zoneIdHandle({
            type: "ZONE_ID",
            zoneId: res.zoneId
          });

          locationIdHandle({
            type: "LOCATION_ID",
            locationId: res.locationId
          });
        } else {
          checkAvailabilityClickedHandle(false);
          this.setState({
            isWithinServicedLocation: false
          });
        }
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { address, zipCode } = this.state;
    const { isWithinServicedLocationHandle, checkAvailabilityClickedHandle } =
      this.props;

    let geoCodeResults = null;

    let customerAddress = `${address}`;

    if (zipCode.length > 0) {
      customerAddress = `${address} ${zipCode}`;
    }

    if (address.length > 5) {
      geoCodeResults = await this.googleGeocodeHandle(customerAddress);
    }

    checkAvailabilityClickedHandle("loading");

    // if service is not included in location or geocode error
    if (geoCodeResults === null) {
      checkAvailabilityClickedHandle(false);
      this.setState({
        isWithinServicedLocation: false
      });
    } else {
      isWithinServicedLocationHandle();
      this.getServicesByAddress();
    }
  };

  changeAddressHandler = () => {
    window.location.reload(true);
  };

  render() {
    const {
      // locationId,
      // services,
      // customerType,
      // firstName,
      // lastName,
      // email,
      // checkboxOffers,
      // emailConfirmation,
      // doesEmailMatch,
      // phoneNumber,
      // checkboxTextingOff,
      address,
      isWithinServicedLocation,
      // serviceSelectedId,
      // serviceSelected,
      // selectedAddOnArray,
      continueToSignupClicked,
      // serviceSelectedVal,
      // specialInstructions,
      streetAddress1,
      streetAddress2,
      zipCode,
      cityName,
      stateName
      // stateNameAbrev,
      // countyName,
      // howDidYouHearAboutUs,
      // howDidYouHearAboutUsOther,
      // couponCode,
      // signUpFormCompleted,
      // billingFormButtonEnabled,
      // sameBillingAddress,
      // openSignupForm,
      // openServiceAddressForm,
      // openAdditionInfoForm,
      // allRequiredFieldsCompleted,
      // selectedAddOnArrayKey,
      // lat,
      // lng,
      // billingIntent,
      // customerDealExist,
      // checkAvailabilityDisabled,
      // validatingPhoneNmbr,
      // validatingEmail,
      // validatingEmailConfirmation,
      // emailValidated,
      // phoneNumberValidated,
      // creditCardName
    } = this.state;

    const { checkAvailabilityClicked } = this.props;

    // will replace with logic to capture if address requirements are successful
    let servicedWithinLocation = false;
    if (address.length > 5) {
      servicedWithinLocation = true;
    }

    let checkAvailabilityDisabled = true;
    if (address.length > 0) {
      checkAvailabilityDisabled = false;
    }

    return (
      <div className="text-center">
        {!continueToSignupClicked ? (
          <div>
            {/* {isWithinServicedLocation || checkAvailabilityClicked === false || this.state.pageLoading
                  ? ''
                  : <div >
                    <div style={{ fontSize: '28px', fontWeight: 'lighter', paddingTop: '5%' }}>
                      <div style={{ paddingTop: '6.2%' }}></div>
                      <div style={{ background: 'yellow', padding: '1%' }}>We are experiencing technical difficulties. Please try again at a later time.</div>
                      <span>{`Check out if `}</span>
                      <span style={{ fontWeight: 'bold' }}>{`Recyclops `}</span>
                      <span>{`is available at your address `}</span>
                    </div>
                  </div>
                } */}

            <br />

            {/* <div className="container"> */}
            {/* <div> */}
            {/* <br /> */}

            {/* <div> */}
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col">
                {isWithinServicedLocation ||
                checkAvailabilityClicked === false ||
                this.state.pageLoading ? (
                  ""
                ) : (
                  <CheckAvailabilityForm
                    checkAvailabilityDisabled={checkAvailabilityDisabled}
                    checkAvailabilityClicked={checkAvailabilityClicked}
                    servicedWithinLocation={servicedWithinLocation}
                    handleSubmit={this.handleSubmit}
                    addressHandle={this.addressHandle}
                    address={address}
                    streetAddress1={streetAddress1}
                    streetAddress2={streetAddress2}
                    streetAddress2Handle={this.streetAddress2Handle}
                    zipCode={zipCode}
                    zipCodeHandle={this.zipCodeHandle}
                    cityName={cityName}
                    stateName={stateName}
                    googleLocationSearchHandle={this.googleLocationSearchHandle}
                    googleGeocodeHandle={this.googleGeocodeHandle}
                  />
                )}
              </div>
              {/* <div className="col-lg-1"></div> */}
              {/* </div> */}
            </div>
            <br />
            {/* </div> */}
            {/* </div> */}

            {/* <div className=''>
                  {checkAvailabilityClicked === false ? (
                    <div className="row">
                      <div className="col-sm"> </div>
                      <div className="col-sm">
                        <div style={{ paddingTop: '10%' }}></div>
                        {`This service is not yet available in your area. \n Check `}
                        <a
                          style={{ color: '#3EB5AB', fontWeight: 'bold' }}
                          href="https://recyclops.com/locations/"
                        >
                          here
                        </a>
                        {` to see if other service types are available where you live!`}
                      </div>
                      <div className="col-sm"> </div>
                    </div>
                  ) : checkAvailabilityClicked == 'loading' ? (
                    <div>Loading...</div>
                  ) : (
                    ''
                  )}
                </div> */}

            <div className="">
              {checkAvailabilityClicked === false ? (
                // <div>
                <NoServiceMsg
                // changeAddressHandler={this.changeAddressHandler}
                // streetAddress1={streetAddress1}
                // streetAddress2={streetAddress2}
                // cityName={cityName}
                // stateNameAbrev={stateNameAbrev}
                // zipCode={zipCode}
                // lat={lat}
                // lng={lng}
                />
              ) : (
                // </div>
                // : checkAvailabilityClicked == 'loading' ? (
                //   <div>Loading...</div>
                // )
                ""
              )}
            </div>

            <br />
            <br />

            {/* <div className="container">
                  {isWithinServicedLocation ? (
                    !serviceSelected ? (
                      <PlanSelection
                        enableContinueBtnServicePlan={this.state.enableContinueBtnServicePlan}
                        changeAddressHandler={this.changeAddressHandler}
                        streetAddress1={streetAddress1}
                        streetAddress2={streetAddress2}
                        cityName={cityName}
                        stateNameAbrev={stateNameAbrev}
                        zipCode={zipCode}
                        services={this.state.services}
                        selectedBaseServiceHandle={
                          this.selectedBaseServiceHandle
                        }
                        continueAfterSelectedBaseServiceHandle={this.continueAfterSelectedBaseServiceHandle}
                        serviceSelectedId={serviceSelectedId}
                        serviceSelectedVal={serviceSelectedVal}
                      />
                    ) : (
                      <AddOnServices
                        changeAddressHandler={this.changeAddressHandler}
                        streetAddress1={streetAddress1}
                        streetAddress2={streetAddress2}
                        cityName={cityName}
                        stateNameAbrev={stateNameAbrev}
                        zipCode={zipCode}
                        pickupFrequency={this.props.pickupFrequency}
                        dummyData={dummyData}
                        services={this.state.services}
                        serviceSelectedId={serviceSelectedId}
                        serviceSelectedVal={serviceSelectedVal}
                        selectedAddOnArray={selectedAddOnArray}
                        selectedAddOnServiceHandle={
                          this.selectedAddOnServiceHandle
                        }
                        selectedAddOnArrayKey={this.state.selectedAddOnArrayKey}
                        moveOnePageBackHandle={this.moveOnePageBackHandle}
                        selectedBaseServiceHandle={
                          this.selectedBaseServiceHandle
                        }
                        continueToSignupFormHandle={
                          this.continueToSignupFormHandle
                        }
                        unSelectedAddOnServiceHandle={
                          this.unSelectedAddOnServiceHandle
                        }
                      />
                    )
                  ) : (
                    ''
                  )}
                </div> */}
          </div>
        ) : (
          <div className="container">
            <div>Loading...</div>
            {/* <SignUpForm
                  getRouteDayHandle={this.getRouteDayHandle}
                  creditCardName={creditCardName}
                  stateNameAbrev={stateNameAbrev}
                  locationId={locationId}
                  emailValidated={emailValidated}
                  phoneNumberValidated={phoneNumberValidated}
                  validatingPhoneNmbr={validatingPhoneNmbr}
                  validatingEmail={validatingEmail}
                  validatingEmailConfirmation={validatingEmailConfirmation}
                  phoneNumberErrorHandling={this.phoneNumberErrorHandling}
                  emailErrorHandling={this.emailErrorHandling}
                  emailConfirmationErrorHandling={
                    this.emailConfirmationErrorHandling
                  }
                  services={services}
                  specialInstructionsHandle={this.specialInstructionsHandle}
                  customerTypeHandle={this.customerTypeHandle}
                  firstNameHandle={this.firstNameHandle}
                  lastNameHandle={this.lastNameHandle}
                  emailHandle={this.emailHandle}
                  emailConfirmationHandle={this.emailConfirmationHandle}
                  checkboxOffersHandle={this.checkboxOffersHandle}
                  phoneNumberHandle={this.phoneNumberHandle}
                  checkboxTextingOffHandle={this.checkboxTextingOffHandle}
                  updateServicePlanHandle={this.updateServicePlanHandle}
                  howDidYouHearAboutUsHandle={this.howDidYouHearAboutUsHandle}
                  couponCodeHandle={this.couponCodeHandle}
                  howDidYouHearAboutUsOtherHandle={
                    this.howDidYouHearAboutUsOtherHandle
                  }
                  toggleBetweenPagesHandler={this.toggleBetweenPagesHandler}
                  initialSignUpFormCompletedHandler={
                    this.initialSignUpFormCompletedHandler
                  }
                  streetAddress2Handle={this.streetAddress2Handle}
                  allRequiredFieldsCompleted={allRequiredFieldsCompleted}
                  signUpFormCompleted={signUpFormCompleted}
                  sameBillingAddressHandle={this.sameBillingAddressHandle}
                  sameBillingAddress={sameBillingAddress}
                  openSignupFormHandle={this.openSignupFormHandle}
                  openServiceAddressFormHandle={
                    this.openServiceAddressFormHandle
                  }
                  openAdditionInfoFormHandle={this.openAdditionInfoFormHandle}
                  openSignupForm={openSignupForm}
                  openServiceAddressForm={openServiceAddressForm}
                  openAdditionInfoForm={openAdditionInfoForm}
                  howDidYouHearAboutUsOther={howDidYouHearAboutUsOther}
                  couponCode={couponCode}
                  howDidYouHearAboutUs={howDidYouHearAboutUs}
                  specialInstructions={specialInstructions}
                  selectedAddOnArray={selectedAddOnArray}
                  serviceSelectedVal={serviceSelectedVal}
                  customerType={customerType}
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  lat={lat}
                  lng={lng}
                  isLoading={this.state.isLoading}
                  stepOneRes={this.state.stepOneRes}
                  checkboxOffers={checkboxOffers}
                  emailConfirmation={emailConfirmation}
                  doesEmailMatch={doesEmailMatch}
                  phoneNumber={phoneNumber}
                  checkboxTextingOff={checkboxTextingOff}
                  streetAddress1={streetAddress1}
                  streetAddress2={streetAddress2}
                  zipCode={zipCode}
                  cityName={cityName}
                  stateName={stateName}
                  countyName={countyName}
                  serviceSelectedId={serviceSelectedId}
                  customerIsOnFormsHandle={customerIsOnFormsHandle}
                  billingFormButtonEnabled={billingFormButtonEnabled}
                  selectedAddOnArrayKey={selectedAddOnArrayKey}
                  billingIntent={billingIntent}
                /> */}
          </div>
        )}
        {/* </div> */}
        {/* // )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pickupDate: state.pickup.pickupDate,
    pickupDateDisplay: state.pickup.pickupDateDisplay,
    locationId: state.pickup.locationId,
    zoneId: state.pickup.zoneId,
    routeDay: state.pickup.routeDay,
    pickupFrequency: state.pickupFrequency.pickupFrequency
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pickupDateHandle: (e) => dispatch(e),
    zoneIdHandle: (e) => dispatch(e),
    routeDayHandle: (e) => dispatch(e),
    pickupDateDisplayHandle: (e) => dispatch(e),
    locationIdHandle: (id) => dispatch(id),
    getPickupFreqHandle: (PickupFreq) => dispatch(PickupFreq),
    getPickupFreqOptions: (PickupFreqOpt) => dispatch(PickupFreqOpt)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckAvailabilityContainer);
