import { useState, useEffect } from 'react'
import logo from '../../assets/images/logo.png'
import { GrFormClose } from "react-icons/gr";
import { useFormik } from 'formik';
import CodeInput from './CodeInput'
import * as Yup from 'yup'
import { IoIosAlert } from "react-icons/io";
import { BsCheckCircleFill } from "react-icons/bs";
import { Auth } from 'aws-amplify';

const initState = {
    email: " ",
}

function ForgotPassword({ modalHandler }) {
    const [state, setState] = useState(initState);
    const [changeDetected, setChangeDetected] = useState(false);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [resendCode, setResendCode] = useState(false);
    const [confirmationCodeError, setConfirmationCodeError] = useState(false);
    const [confirmationCodeSent, setConfirmationCodeSent] = useState(false);
    const [forgotPasswordLimitError, setForgotPasswordLimitError] = useState(false);
    const [emailSubmited, setEmailSubmited] = useState(false);
    const [showCodeInput, setShowCodeInput] = useState(false);
    const [submitForgotEmailClicked, setSubmitForgotEmailClicked] = useState(false);
    const [code, setCode] = useState('');

    const codeHandler = (otp) => setCode(otp);
    //eslint-disable-next-line
    const regex = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/gm
    const { handleSubmit, handleChange, values, touched, errors, handleBlur, } = useFormik({
        initialValues: state,
        enableReinitialize: true,
        validationSchema: Yup.object({
            email:
                Yup
                    .string()
                    .email()
                    .min(1, 'Field cannot be empty').required(),
        }),
        onSubmit: values => {
            emailInputHandler(values)
        },
    });

    useEffect(() => {
        if (values.email.toLocaleLowerCase() !== state.email.toLocaleLowerCase()) {
            setState({
                email: values.email,
            })
            setChangeDetected(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.email]);

    const emailInputHandler = async (values) => {
        if (values.email.length > 0 && !errors.email && touched.email && changeDetected) {
            setSubmitForgotEmailClicked(true)
            try {
                await Auth.forgotPassword(values.email)
                setEmailSubmited(true)
                setForgotPasswordLimitError(false)
                setTimeout(() => {
                    setShowCodeInput(true)
                    setSubmitForgotEmailClicked(false)
                }, 2000)
            } catch (err) {
                console.error('Forgot password request limit hit:', err)
                setForgotPasswordLimitError(true)
                setSubmitForgotEmailClicked(false)
            }
        }
    }

    const resendConfirmationCodeHandler = async () => {
        if (state.email) {
            setResendCode(true)
            try {
                // let test = await Auth.resendSignUp(state.email);
                await Auth.forgotPassword(values.email)
                setEmailSubmited(true)
                setForgotPasswordLimitError(false)
                setTimeout(() => {
                    setResendCode(false)
                    setShowCodeInput(true)
                }, 2000)
            } catch (err) {
                console.error('Forgot password request limit hit:', err)
                setForgotPasswordLimitError(true)
                setResendCode(false)
            }
        }
    }

    const sendConfirmationCode = () => {
        if (code.length === 6) {
            setConfirmationCodeSent(true)
        }
    }

    const updatePasswordHandler = async (newPassword) => {
        try {
            const res = await Auth.forgotPasswordSubmit(values.email, code, newPassword)
            if (res === 'SUCCESS') {
                setConfirmationCodeError(false)
                setUpdatePassword(true)
            } else {
                setConfirmationCodeError(true)
            }
        } catch (err) {
            console.error('update password error: ', err)
            setConfirmationCodeError(true)
            setConfirmationCodeSent(false)
        }
    }

    return (
        <div>
            <div>
                {updatePassword
                    ? < div className={"forgot-password-container"} >
                        <div className={"card-top-img"}>
                            <img className='monster-desktop-display' src={logo} alt={"monster"} />
                        </div>
                        <div className="forgot-password-bgCard">
                            <div className='text-right col-12 close-modal-button pointer' onClick={() => modalHandler()}>
                                {/* <GrFormClose /> */}
                            </div>
                            <div className={"email-sent-msg forgot-update-success-msg"}>
                                {`Congratulations`}
                            </div>
                            <div className={"password-updated-response-msg-font forgot-update-msg-size"}>
                                {`Your password has been updated`}
                            </div>
                            <div>
                                <div className='row'
                                // style={{ paddingTop: '8%' }}
                                >
                                    {/* <div className='col-12 check-mark-filled'>
                                        <BsCheckCircleFill />
                                    </div> */}
                                    <div className='col-12'>
                                        <div style={{ paddingTop: '40px' }}></div>
                                        <div>
                                            <button
                                                type='button'
                                                onClick={() => modalHandler()}
                                                className='btn-containerEnabled'>
                                                {`Continue`}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    : <div>
                        {showCodeInput
                            ? <div>
                                <CodeInput
                                    resendCode={resendCode}
                                    resendConfirmationCodeHandler={resendConfirmationCodeHandler}
                                    updatePasswordHandler={updatePasswordHandler}
                                    confirmationCodeError={confirmationCodeError}
                                    modalHandler={modalHandler}
                                    logo={logo}
                                    email={values.email}
                                    confirmationCodeSent={confirmationCodeSent}
                                    codeHandler={codeHandler}
                                    code={code}
                                    sendConfirmationCode={sendConfirmationCode} />
                            </div>
                            : <div>
                                {emailSubmited
                                    ? <form onSubmit={handleSubmit}>
                                        <div className={""}>
                                            <div className={"forgot-password-container"}>
                                                <div className={"card-top-img"}>
                                                    <img className='monster-desktop-display' src={logo} alt={"monster"} />
                                                </div>
                                                <div className="forgot-password-bgCard">
                                                    <div className='text-right col-12'
                                                        style={{ paddingRight: '0px', paddingTop: '3%', fontSize: '25px' }}
                                                    // onClick={() => closeEmailSentMsgHandler()}
                                                    >
                                                        {/* <GrFormClose /> */}
                                                    </div>
                                                    <div className={"email-sent-msg"} style={{ paddingTop: '5%', fontSize: '18px' }}>
                                                        {`Email sent`}
                                                    </div>
                                                    <div>
                                                        <div className='row' style={{ paddingTop: '8%' }}>
                                                            <div className='col-12 check-mark-filled'>
                                                                <BsCheckCircleFill />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    : <form onSubmit={handleSubmit}>
                                        <div className={""}>
                                            <div className={"forgot-password-container"}>
                                                <div className={"card-top-img"}>
                                                    <img className='monster-desktop-display' src={logo} alt={"monster"} />
                                                </div>
                                                <div className="forgot-password-bgCard">
                                                    <div className='row' style={{ paddingRight: '0px', paddingTop: '8%' }}>
                                                        <span className='col-2' style={{ fontSize: '18px' }}></span>
                                                        <span className='col-8 text-center forgot-password-header-size'>Forgot password</span>
                                                        <span className='col text-right forgot-password-close-modal-button pointer' onClick={() => modalHandler()}>
                                                            <GrFormClose />
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <div className='container'>
                                                            <div className={"form-grop"} style={{ paddingRight: '8px', paddingLeft: '8px' }}>
                                                                <div className={"password-updated-response-msg-font forgot-password-msg-size"}>
                                                                    {`Enter your registered email to receive a code to reset password`}
                                                                </div>
                                                                <div className='row' style={{ paddingTop: '5%', }}>
                                                                    <div className='col-12'>
                                                                        <div className={"input-pass password-updated-input"}>
                                                                            <input
                                                                                className='password-updated-input-font'
                                                                                data-testid={"forgotPasswordEmail"}
                                                                                type={'text'}
                                                                                name="email"
                                                                                id="forgotPasswordEmail"
                                                                                placeholder={"Enter your email"}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                                value={values.email.replace(/\s+/g, '')}
                                                                                style={{ borderBottomWidth: 0, fontSize: '18px' }}
                                                                            />
                                                                        </div>
                                                                        <div className='errorProfileMsgFont padding-left-error-forgot-pw-email'>
                                                                            {touched.email && errors.email && values.email.length > 0
                                                                                ? <div>
                                                                                    <IoIosAlert />
                                                                                    <span>{` Incorrect format`}</span>
                                                                                </div>
                                                                                : ''}
                                                                            {values.email.length === 0
                                                                                ? <div>
                                                                                    <IoIosAlert />
                                                                                    <span>{` Field cannot be empty`}</span>
                                                                                </div>
                                                                                : ''}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-12'>
                                                                    {forgotPasswordLimitError
                                                                        ? <div className='text-center' style={{ color: 'red' }}>Attempt limit exceeded, please try after some time. </div>
                                                                        : ''}
                                                                    <div className='forgot-password-send-container'>
                                                                        <button
                                                                            disabled={submitForgotEmailClicked ? true : false}
                                                                            data-testid='submitForgotPw'
                                                                            id="submitForgotPw"
                                                                            type='submit'
                                                                            className='btn-container'>
                                                                            {submitForgotEmailClicked ? `Sending...` : `Send`}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                }
                            </div>
                        }
                    </div>
                }
            </div>

        </div >
    )
}

export default ForgotPassword