// eslint-disable-next-line  no-unused-vars
import React, { useEffect, useState } from "react";
import murph from "../../assets/images/murph.png";
import useWindowDimensions from "../../Hook/useWindowDimensions";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AppButton from "../../components/AppButton";
import { capitalizeFirstLetter } from "../../helper";
import Budget from "./Budget";
import MissedPickups from "./MissedPickups";
import PickupFrequently from "./PickupFrequently";
import SwitchedToOtherService from "./SwitchedToOtherService";
import DeleteService from "../Services/DeleteService";
import Others from "./Others/Other";
import Moving from "./Moving";
import { removeService } from "../../api";
import { OptimizelyFeature } from "@optimizely/react-sdk";
import { getContactSubs } from "../../store/features/deal/getContactSubs";
import { sentryCaptureError } from "../../components/utils/index";
// import { selectedDeal } from "../../store/features/deal/selectedDeal";

function CancelSubscription() {
  // @ts-ignore
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [enableModal, setEnableModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [serviceRemoved, setServiceRemoved] = useState(false);
  const [serviceRemovedError, setServiceRemovedError] = useState(false);
  const hasMobile = width <= 768;

  const reasons = [
    { title: "Moving", value: "moving" },
    { title: "Budget", value: "budget" },
    { title: "Missed pick ups", value: "missedPickups" },
    { title: "Pickup frequency", value: "pickupFrequency" },
    { title: "Switched to other service", value: "switchToOtherService" },
    { title: "Other", value: "other" }
    // { title: "Bags", value: "bags" },
    // { title: "Price", value: "price" },
    // { title: "Do not use service enough", value: "dontUseServiceEnough" },
    // { title: "No longer want to recycle", value: "noLongerWantToRecycle" },
    // {title:"hoaApartmentDoesntAllow",value:"hoaApartmentDoesntAllow"},
    // {
    //   title: "Pick ups not frequent enough",
    //   value: "pickupsNotFrequentEnough"
    // },
    // { title: "Could not collect", value: "couldNotCollect" },
    // { title: "Location discontinued", value: "locationDiscontinued" },
  ];
  const [reason, setReason] = useState("");

  // @ts-ignore
  const loggedInUser = useSelector((state) => state.customerData);
  // @ts-ignore
  const selectedDealObj = useSelector((state) => state.selectedDeal.deal);
  // @ts-ignore
  const userSub = useSelector((state) => state.getContactSubs.subscription);

  useEffect(() => {
    if (Object.keys(selectedDealObj).length === 0) {
      navigate("/dashboard/services");
    } else {
      // eslint-disable-next-line
      let joiningDate = new Date(selectedDealObj.firstPickupDate);
      let day = joiningDate.getDay();
      let month = joiningDate.toLocaleString("en-us", { month: "long" });
      let year = joiningDate.getFullYear();
      joiningDate = day + " " + month + ", " + year;
      joiningDate = joiningDate.toLocaleString();
      setState((prevState) => ({
        ...prevState,
        joiningDate
      }));
    }

  }, [loggedInUser, selectedDealObj, navigate]);

  /* eslint-enable @typescript-eslint/no-unused-vars */

  const [state, setState] = React.useState({
    isLoading: false,
    isError: false,
    errorMessage: "",
    cancelationReason: "",
    joiningDate: ""
  });

  const onBackPressHandler = () => {
    navigate("/dashboard/services");
  };

  const handleSelectReason = (event) => {
    setState({
      ...state,
      cancelationReason: event.target.value
    });
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    setReason(state.cancelationReason);
  };

  const updateReduxContactSub = async (arr) => {
    try {
      await dispatch(getContactSubs(arr));
    } catch (err) {
      sentryCaptureError(err, { extra: { arr } });
      console.log("error with updating contact subs via cancel subscription", err);
    }
  };

  // const updateReduxSelectedDeal = async (obj) => {
  //   try {
  //     await dispatch(selectedDeal(obj));
  //   } catch (err) {
  //     sentryCaptureError(err, { extra: { obj } });
  //     console.log("error with selecting deal", err);
  //   }
  // };

  const removeServiceHandler = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const cancelationReason = state.cancelationReason;
    // .split(" ")
    // .join("_")
    // .toLowerCase();
    if (selectedDealObj.contactId) {
      let deleteBody = {
        updateType: "cancel",
        newServicesEnrolled: [],
        oldServicesEnrolled: selectedDealObj?.subscriptionServicesEnrolled
          ? selectedDealObj.subscriptionServicesEnrolled
          : [],
        stripeCustomerId: selectedDealObj.billId,
        locationId: selectedDealObj.locationId,
        locationZone: selectedDealObj?.locationZone ? selectedDealObj.locationZone : "",
        oldPaymentFrequency: selectedDealObj.paymentFrequency,
        oldPickupFrequency: selectedDealObj.pickupFrequency,
        isFranchised: selectedDealObj.isFranchised,
        cancelationReason
      };
      try {
        // remove service
        let res = await removeService(
          selectedDealObj.contactId,
          selectedDealObj.dealId,
          deleteBody
        );

        setServiceRemovedError(false);

        if (res && res.success) {
          setServiceRemoved(true);
          setButtonLoading(false);

          // START cancel subscription
          let userSubClone = structuredClone(userSub);

          for (let i = 0; i < userSubClone.length; i++) {
            if (userSubClone[i].billId === selectedDealObj.billId) {
              userSubClone[i].subscriptionServicesEnrolled = []
              userSubClone[i].subscriptions = []
            }
          }

          updateReduxContactSub(userSubClone)

          // END cancel subscription

          setTimeout(() => {
            setEnableModal(false);
            setServiceRemoved(false);
            navigate("/dashboard/services");
            // window.location.reload();
          }, 3000);
        } else {
          setServiceRemoved(true);
          setServiceRemovedError(true);
          setButtonLoading(false);
          setTimeout(() => {
            setEnableModal(false);
            setServiceRemoved(false);
          }, 4000);
        }
      } catch (error) {
        return {
          error: `Error with removing customer's service: ${error}`
        };
      }
    } else {
      alert("Error occured. Please try again or refresh page");
      setButtonLoading(false);
    }
  };

  return (
    <>
      {reason === "Budget" ? (
        <Budget />
      ) : reason === "Missed pick ups" ? (
        <MissedPickups />
      ) : reason === "Moving" ? (
        <Moving />
      ) : reason === "Pick up frequency" ? (
        <PickupFrequently />
      ) : reason === "Switched to other service" ? (
        <SwitchedToOtherService />
      ) : reason === "Other" ? (
        <Others />
      ) : (
        <>
          <div className="app-main-content  main-seaction">
            <section
              className={`${!hasMobile
                ? "card-section-web section-bg-card active"
                : "active"
                } `}
            >
              <div className={"page-account"}>
                {enableModal ? (
                  <DeleteService
                    buttonLoading={buttonLoading}
                    serviceRemovedError={serviceRemovedError}
                    setEnableModal={setEnableModal}
                    removeServiceHandler={removeServiceHandler}
                    serviceRemoved={serviceRemoved}
                  />
                ) : (
                  ""
                )}
                {hasMobile ? (
                  <>
                    <Header
                      hasBack
                      onBackPress={onBackPressHandler}
                      isSettings={false}
                      title={"Cancel subscription"}
                      icon={""}
                    />
                    <div className="contact-main p-5">
                      <div className="mb-3">
                        <h3 className="w500 " style={{ borderBottom: 0 }}>
                          Thank you for being a Recyclops member since{" "}
                          {state.joiningDate}
                          {/* 23 July
                          2021 */}
                        </h3>
                      </div>

                      <div>
                        <p className="form-grop input">
                          You’ve been a part of recycling x tons of waste.
                        </p>
                      </div>

                      <div className="d-flex justify-content-center">
                        <img src={murph} alt="loading" />
                      </div>

                      <div className="contact-form-fields">
                        <div className="my-2">
                          <p>
                            Before you cancel your subscription, please give us
                            a chance to make it right.
                          </p>
                        </div>
                        <p style={{ fontSize: "18px", fontWeight: "500" }}>
                          Why do you want to cancel?
                        </p>
                        {reasons.map((reasonCancel, key) => {
                          return (
                            <div key={key} className="category-radio my-2">
                              <input
                                type={"radio"}
                                value={reasonCancel.value}
                                name="reason"
                                checked={
                                  state.cancelationReason === reasonCancel.value
                                }
                                onChange={handleSelectReason}
                              />
                              <label
                                htmlFor={reasonCancel}
                                className="ml-2 mb-0"
                              >
                                {/* {category} */}
                                {capitalizeFirstLetter(
                                  reasonCancel.title.replace(/_/g, " ")
                                )}
                              </label>
                            </div>
                          );
                        })}

                        <div>

                          {/* <AppButton
                            onClick={onBackPressHandler}
                            title="Go Back"
                          /> */}
                          <button onClick={onBackPressHandler} className="btn-container-white">Go Back</button>

                          <OptimizelyFeature feature="cancel_service_modal_flow">
                            {(enabled, variables) =>
                              enabled ? (
                                <AppButton
                                  className={state.cancelationReason ? "btn-container" : "button-without-background"}
                                  onClick={() => {
                                    setEnableModal(true);
                                  }}
                                  title="Continue Cancellation"
                                  isDisable={!state.cancelationReason}
                                />
                              ) : (
                                <AppButton
                                  // className="profile-desktop-btn-container"
                                  className={state.cancelationReason ? "btn-container" : "button-without-background"}
                                  onClick={handlerSubmit}
                                  title="Continue Cancellation"
                                />
                              )
                            }
                          </OptimizelyFeature>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="contact-main">
                      <h3 className="w500" style={{ borderBottom: 0 }}>
                        Cancel subscription
                      </h3>

                      <div className="contact-form">
                        <div className="d-flex ">
                          <div className="murph-width mt-5 d-flex flex-column justify-content-between">
                            <div className="mb-3">
                              <h3 className="w500 " style={{ borderBottom: 0 }}>
                                Thank you for being a Recyclops member since{" "}
                                {state.joiningDate}
                                {/* 23 July 2021 */}
                              </h3>
                            </div>

                            <div>
                              <p className="form-grop input">
                                You’ve been a part of recycling x tons of waste.
                              </p>
                            </div>

                            <div>
                              <p>
                                Before you cancel your subscription, please give
                                us a chance to make it right.
                              </p>
                            </div>
                          </div>

                          <div className="murph-width d-flex justify-content-end">
                            <div>
                              <img src={murph} alt="loading" />
                            </div>
                          </div>
                        </div>

                        <div className="contact-form-fields">
                          <h4>Why do you want to cancel?</h4>
                          {reasons.map((reasonCancel, key) => {
                            return (
                              <div key={key} className="category-radio my-2">
                                <input
                                  type={"radio"}
                                  value={reasonCancel.value}
                                  name="reason"
                                  checked={
                                    state.cancelationReason ===
                                    reasonCancel.value
                                  }
                                  onChange={handleSelectReason}
                                />
                                <label
                                  htmlFor={reasonCancel}
                                  className="ml-2 mb-0"
                                >
                                  {/* {category} */}
                                  {capitalizeFirstLetter(
                                    reasonCancel.title.replace(/_/g, " ")
                                  )}
                                </label>
                              </div>
                            );
                          })}
                        </div>

                        {/* <AppButton
                          onClick={onBackPressHandler}
                          title="Go Back"
                        /> */}

                        <button onClick={onBackPressHandler} className="btn-container-white">Go Back</button>

                        <OptimizelyFeature feature="cancel_service_modal_flow">
                          {(enabled, variables) =>
                            enabled ? (
                              <AppButton
                                className={state.cancelationReason ? "btn-container" : "button-without-background"}
                                onClick={() => {
                                  setEnableModal(true);
                                }}
                                title="Continue Cancellation"
                                isDisable={!state.cancelationReason}
                              />
                            ) : (
                              <AppButton
                                // className="profile-desktop-btn-container"
                                className="button-without-background"
                                onClick={handlerSubmit}
                                title="Continue Cancellation!!"
                              />
                            )
                          }
                        </OptimizelyFeature>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
}

export default CancelSubscription;
