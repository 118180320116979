/**
   * Validate input fields
   */
export const validateInput = (email) => {
  return String(email).trim().toLowerCase().match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}

export const isTwoStrIsSame = (text1 = null, text2 = "") => {
  return text1 === text2
}

export const capitalizeFirstLetterofFirstWord = string => {
  if (string?.length > 1) {
    const containsUnderScore = string.split('').includes('_')

    let result

    if (containsUnderScore) {
      let str = string.replace('_', ' ')
      result = str.charAt(0).toUpperCase() + str.slice(1)
    } else {
      result = string.charAt(0).toUpperCase() + string.slice(1)
    }

    if (string === 'every_other') {
      return `${result} week`
    } else {
      return `${result}`
    }
  }
};


export const capitalizeFirstLetterofEachWord = string => {
  if (string?.length > 1) {
    const containsUnderScore = string.split('').includes('_')

    let result

    if (containsUnderScore) {
      let str = string.replace('_', ' ')
      var splitStr = str.split(' ');

      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }

      result = splitStr.join(' ')
    } else {
      result = string.charAt(0).toUpperCase() + string.slice(1)
    }

    return result;
  }
};
