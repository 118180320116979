import { BsCheckCircleFill } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import { SiIfixit } from "react-icons/si";
import { capitalizeFirstLetter } from "../../helper";

function DeleteService({
  buttonLoading,
  serviceRemovedError,
  setEnableModal,
  removeServiceHandler,
  serviceRemoved,
  service = {}
}) {
  return (
    <div>
      {serviceRemoved ? (
        <div className={"modal-container-with-background"}>
          <div className="modal-design-popup-remove-credit-card">
            {/*  */}
            <div className="row">
              <div
                className="text-right col-12"
                style={{ paddingTop: "16%" }}
              ></div>
              <div style={{ flex: 1 }}>
                <div className={"form-grop"}>
                  {serviceRemovedError ? (
                    <div className="col-12 check-mark-error-filled-small">
                      <SiIfixit />
                    </div>
                  ) : (
                    <div className="col-12 check-mark-filled-small">
                      <BsCheckCircleFill />
                    </div>
                  )}
                  <div className="col-12" style={{ paddingTop: "22px" }}>
                    <div className={"password-updated-font"}>
                      {serviceRemovedError
                        ? `Error occured while removing service`
                        : `Your service has been removed`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-right col-12 top-padding-10-percent"></div>
            </div>
          </div>
        </div>
      ) : (
        <div className={"modal-container-with-background"}>
          <div className="modal-design-popup-remove-credit-card">
            {/*  */}
            <div className="row">
              <div
                className="text-right col-12 close-modal-button-remove-credit-card"
                onClick={() => setEnableModal()}
              >
                <GrFormClose />
              </div>
              <div
                className="text-right col-12"
                style={{ paddingTop: "2%" }}
              ></div>
              <div style={{ flex: 1 }}>
                <div className={"form-grop"}>
                  <div className="col-12">
                    <div className={"password-updated-font container"}>
                      {service?.isAddOn
                        ? `Are you sure you want to remove ${capitalizeFirstLetter(
                          service.service.replace(/_/g, " ")
                        )} from your service?`
                        : `Are you sure you want to cancel your subscription?`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 top-padding-30px">
                <div>
                  <button
                    disabled={buttonLoading ? true : false}
                    onClick={removeServiceHandler}
                    className="btn-containerEnabled-remove-credit-card"
                  >
                    {buttonLoading ? `Loading...` : `Yes`}
                  </button>
                </div>
              </div>
              <div className="col-12 top-padding-15px">
                <div>
                  <button
                    disabled={buttonLoading ? true : false}
                    onClick={() => setEnableModal()}
                    className="btn-container"
                  >
                    {`No`}
                  </button>
                </div>
              </div>
              <div className="text-right col-12 "></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DeleteService;
