import { Buffer } from "buffer";

const capitalizeFirstLetterofEachWord = (string) => {
  const containsUnderScore = string.split("").includes("_");

  let result;

  if (containsUnderScore) {
    let str = string.replace("_", " ");
    var splitStr = str.split(" ");

    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    result = splitStr.join(" ");
  } else {
    result = string.charAt(0).toUpperCase() + string.slice(1);
  }

  return result;
};

const capitalizeFirstLetterofFirstWord = (string) => {
  const containsUnderScore = string.split("").includes("_");

  let result;

  if (containsUnderScore) {
    let str = string.replace("_", " ");
    result = str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    result = string.charAt(0).toUpperCase() + string.slice(1);
  }

  if (string === "every_other") {
    return `${result} week`;
  } else {
    return `${result}`;
  }
};

const returningCustomerParams = () => {
  let url_string = window.location.href;
  let url = new URL(url_string);

  let salesRepName = "";
  let isReturningCustomer = false;

  let url_str = url.search.substring(1);

  // Start - QR code
  let isQrCode = url_str?.split("=");

  if (isQrCode[0] === "salesRepName") {
    let salesRep = isQrCode[1].replaceAll("%20", " ");

    salesRepName = salesRep;

    return {
      salesRepName
    };
    // End - QR code
  } else {
    let urlDecoded = Buffer.from(url_str, "base64").toString("ascii");
    let stringProps = urlDecoded.split("&");

    let serviceState = "";
    let serviceCounty = "";
    let locationName = "";
    let firstName = "";
    let lastName = "";
    let email = "";
    let pickupFrequency = "";
    let address1 = "";
    let address2 = "";
    let city = "";
    let state = "";
    let postalCode = "";
    let services = "";
    let noSetupFee = "";
    let oneMonthFree = "";
    let paymentFrequency = "";

    if (stringProps.length > 1) {
      for (let i = 0; i < stringProps.length; i++) {
        let URLProps = stringProps[i].split("=");

        if (URLProps[0] === "selectedSalesRep") {
          salesRepName = URLProps[1];
        }

        if (URLProps[0] === "serviceState") {
          serviceState = URLProps[1];
        }
        if (URLProps[0] === "serviceCounty") {
          serviceCounty = URLProps[1];
        }
        if (URLProps[0] === "locationName") {
          locationName = URLProps[1];
        }
        if (URLProps[0] === "firstName") {
          firstName = URLProps[1];
        }
        if (URLProps[0] === "lastName") {
          lastName = URLProps[1];
        }
        if (URLProps[0] === "email") {
          email = URLProps[1];
          isReturningCustomer = true;
        }
        if (URLProps[0] === "pickupFrequency") {
          pickupFrequency = URLProps[1];
        }
        if (URLProps[0] === "address1") {
          address1 = URLProps[1];
        }
        if (URLProps[0] === "address2") {
          address2 = URLProps[1];
        }
        if (URLProps[0] === "city") {
          city = URLProps[1];
        }
        if (URLProps[0] === "state") {
          state = URLProps[1];
        }
        if (URLProps[0] === "postalCode") {
          postalCode = URLProps[1];
        }
        if (URLProps[0] === "services") {
          services = URLProps[1];
        }
        if (URLProps[0] === "noSetupFee") {
          noSetupFee = URLProps[1];
        }
        if (URLProps[0] === "oneMonthFree") {
          oneMonthFree = URLProps[1];
        }
        if (URLProps[0] === "paymentFrequency") {
          paymentFrequency = URLProps[1];
        }
      }

      return {
        isReturningCustomer,
        serviceState,
        serviceCounty,
        locationName,
        firstName,
        lastName,
        email,
        pickupFrequency,
        address1,
        address2,
        city,
        state,
        postalCode,
        services,
        noSetupFee,
        oneMonthFree,
        paymentFrequency,
        salesRepName
      };
    }
  }
};

const earliestPickupDate = (dealsArr) => {

  let earliestPickupDate;

  if (dealsArr.length > 1) {
    earliestPickupDate = dealsArr.reduce(function (a, b) {
      return Date.parse(`${a.currentDay}-${a.monthDisplay}`) > Date.parse(`${b.currentDay}-${b.monthDisplay}`) ? b : a;
    });
  } else {
    earliestPickupDate = dealsArr[0]
  }

  return earliestPickupDate

};

export {
  capitalizeFirstLetterofEachWord,
  capitalizeFirstLetterofFirstWord,
  returningCustomerParams,
  earliestPickupDate
};
