const checkAvailabilityStyling = {
    centerEverything: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    dropDownColor: {
      color: 'rgb(62, 181, 171)',
      fontSize: '90%',
      border: '1px solid #EDEDED',
      textAlign: 'left'
    },
    dropDownNoColor: {
      color: '',
      fontSize: '90%',
      border: '1px solid #EDEDED',
      textAlign: 'left'
    },
    pagePosition: {
      textAlign: 'center',
      position: 'relative',
    },
    welcomeIconStyling: {
      marginBottom: '-2.0%',
      background: 'white',
    },
    formContainer: {
      boxShadow: '0px 2px 4px 2px rgba(0, 0, 0, 0.25)',
      borderRadius: '20px',
    },
    inputStylingStreetAddress: {
      width: '125%',
      // width: '100%',
      fontSize: '90%',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: '.5px solid',
      // borderBottom: 'none',
      borderColor: '#C4C4C4',
    },
    inputStylingUnitNumber: {
      width: '165%',
      fontSize: '90%',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: '.5px solid',
      borderColor: '#C4C4C4',
      // borderBottom: 'none',
    },
    inputStylingZipCode: {
      fontSize: '90%',
      width: '100%',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: '.5px solid',
      borderColor: '#C4C4C4',
      // borderBottom: 'none',
    },
    buttonEnabledStyling: {
      outline: 'none',
      width: '100%',
      // width: '60%', {/* for mobile */}
      border: 'solid white',
      color: 'white',
      background: '#3EB5AB',
      borderRadius: '20px',
      borderColor: '#3EB5AB',
      padding: '2%',
    },
    hoveredButtonEnabledStyling: {
      outline: 'none',
      width: '100%',
      // width: '60%', {/* for mobile */}
      border: 'solid #2A9B8D',
      color: 'white',
      background: '#2A9B8D',
      borderRadius: '20px',
      padding: '2%',
    },
    buttonStyling: {
      outline: 'none',
      width: '100%',
      // width: '60%', {/* for mobile */}
      border: '0.5px solid #3EB5AB',
      color: '#3EB5AB',
      background: 'white',
      borderRadius: '20px',
      padding: '2%',
    },
    buttonDisabledStyling: {
      outline: 'none',
      padding: '2%',
      width: '100%',
      borderRadius: '20px',
      border: '0.5px solid #C4C4C4',
      color: '#C4C4C4',
      background: 'white',
    },
  };
  
  export default checkAvailabilityStyling;
  