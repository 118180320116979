import React from "react";
import checkAvailabilityStyling from "../check_availability/checkAvailabilityStyling";
import PlacesAutocomplete from "react-places-autocomplete";
import { GoogleApiWrapper } from "google-maps-react";
//
class GoogleLocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      mouseHover: false,
      dropDownKey: ""
    };
  }

  onMouseEnterHandler = (key) => {
    this.setState({
      mouseHover: true,
      dropDownKey: key
    });
  };

  onMouseLeaveHandler = () => {
    this.setState({
      mouseHover: false,
      dropDownKey: ""
    });
  };

  handleChange = (address) => {
    const { addressHandle } = this.props;
    addressHandle(address);
    this.setState({ address });
  };

  handleSelect = (address) => {
    const { googleLocationSearchHandle, googleGeocodeHandle } = this.props;
    this.setState({ address });
    googleGeocodeHandle(address);
    googleLocationSearchHandle(address);
  };

  // componentDidMount() {
  //   window.initMap = this.initMap;
  //   const gmapScriptEl = document.createElement(`script`);
  //   gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_GEOCODE}&libraries=places&callback=initMap`;
  //   document
  //     .querySelector(`body`)
  //     .insertAdjacentElement(`beforeend`, gmapScriptEl);
  // }

  render() {
    const { dropDownKey } = this.state;

    let displayAddress = this.state.address
      ? this.state.address.split(",")[0]
      : "";

    return (
      <PlacesAutocomplete
        value={displayAddress}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              id="service-address"
              style={checkAvailabilityStyling.inputStylingStreetAddress}
              {...getInputProps({
                placeholder: `Enter your location`,
                className: "location-search-input"
              })}
              required
              autoComplete="off"
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}

              {suggestions.map((suggestion, key) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                return (
                  <div
                    onMouseOver={() => this.onMouseEnterHandler(key)}
                    onMouseLeave={this.onMouseLeaveHandler}
                    key={key}
                    type="submit"
                    style={
                      dropDownKey === key
                        ? checkAvailabilityStyling.dropDownColor
                        : checkAvailabilityStyling.dropDownNoColor
                    }
                    {...getSuggestionItemProps(suggestion, {
                      className
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_GEOCODE,
  libraries: ["places"]
})(GoogleLocationSearchInput);
