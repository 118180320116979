import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { validateInput } from "../helper";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../store/features/auth/authSlice";
import { AppDispatch } from "../../store";
import { errorSvg } from "../../components/Svgs";
import { customerFind } from "../../api";
import Auth from "@aws-amplify/auth";
import AppButton from "../../components/AppButton";
import ForgotPassword from "../Password/ForgotPassword";
import { getContactSubs } from "../../store/features/deal/getContactSubs";
import { getCustomerData } from "../../store/features/auth/customerData";
import { getCustomerSubscriptions } from '../../api';
import { sentrySetUser } from "./../../components/utils/index";

const checkColor = "#c4c4c4";

const initState = {
  email: "",
  password: "",
  isPassShown: false,
  passError: "",
  emailError: "",
  isCustomerExists: false,
  isLoading: false
  // isDisable: false,
};

function Login() {
  const [state, setState] = useState(initState);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const { errors } = useSelector((state) => state["auth"]);
  const navigate = useNavigate();

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      passError: errors
    }));
  }, [errors]);

  const loginHandler = async () => {
    if (!validateInput(state.email)) {
      setState({
        ...state,
        emailError: "Please enter valid email"
      });
      return;
    }
    if (state?.email && !state.isCustomerExists) {
      setState({ ...state, isLoading: true });
      sentrySetUser(null, state.email);
      const checkCustomer = await customerFind(state.email);

      if (checkCustomer.exists === false) {
        window.open(
          process.env.REACT_APP_SIGNUP_PORTAL_URL,
          "_self",
          "noopener,noreferrer"
        );
        // setState({...state,isLoading: false})
        return;
      } else if (checkCustomer.exists) {
        const hasUserOnCognito = await isUserExistOnCongniTo(state.email);
        if (hasUserOnCognito) {
          setState({
            ...state,
            isCustomerExists: true,
            isLoading: false
          });
        } else {
          navigate("/signup", { state: { email: state.email } });
        }
      }
      return;
    }

    if (!state.password || !state.email) {
      setState({
        ...state,
        passError: state.password ? "" : "Field cannot be empty",
        emailError: state.email ? "" : "Field cannot be empty"
      });
      return;
    }

    if (!validateInput(state.email)) {
      setState({
        ...state,
        emailError: "Please enter valid email"
      });
      return;
    }

    setIsLoading(true);

    try {
      const user = await Auth.signIn(state.email.trim(), state.password);

      if (user) {
        let customerSubscriptions = []

        // @ts-ignore
        await dispatch(getCustomerData());
        let userDataId = user?.attributes[`custom:hubspotContactId`]
        if (userDataId) {
          customerSubscriptions = await getCustomerSubscriptions(userDataId);

          if (customerSubscriptions.length > 0) {
            await dispatch(
              getContactSubs(
                // @ts-ignore
                customerSubscriptions
              )
            )
          }

        }

        let settingUser = new Promise(function (resolve, reject) {
          resolve(dispatch(setUser(user?.attributes)));

          reject(new Error("Will this be ignored?")); // ignored
          resolve("Ignored?"); // ignored
        });
        settingUser.then(
          (result) => {
            console.log('setting user');
          },
          (error) => {
            console.log(error);
          }
        );
        setIsLoading(false);
      }
    } catch (error) {
      alert(error);
      setIsLoading(false);
    }
  };

  const isUserExistOnCongniTo = (email) => {
    return Auth.signIn(email.toLowerCase().trim(), "123")
      .then((res) => {
        return false;
      })
      .catch((error) => {
        const code = error.code;
        switch (code) {
          case "UserNotFoundException":
            return false;
          case "NotAuthorizedException":
            return true;
          case "PasswordResetRequiredException":
            return true;
          case "UserNotConfirmedException":
            return true;
          default:
            return false;
        }
      });
  };

  const modalHandler = () => {
    setForgotPasswordModal(!forgotPasswordModal);
  };

  return (
    <div className="App">
      {forgotPasswordModal ? (
        <ForgotPassword modalHandler={modalHandler} />
      ) : (
        <div className={"bg-pattren"}>
          <div className={"bgCardContainer"}>
            <div className={"card-top-img"}>
              <img src={logo} alt={"monster"} />
            </div>
            <div className="bgCard">
              <div className={"login-form"} id={"login-form"}>
                <div className="row">
                  <div className={"form-grop"}>
                    <label>Email</label>
                    <input
                      data-testid={"email"}
                      type="text"
                      name="email"
                      placeholder={"Enter your email"}
                      value={state.email}
                      onChange={(e) =>
                        setState({
                          ...state,
                          email: e.target.value.trim(),
                          emailError: ""
                        })
                      }
                      onKeyDown={(k) => {
                        if (k.key === "Enter") {
                          loginHandler();
                        }
                      }}
                    />
                    {state.emailError ? (
                      <span
                        className={"pass-strength"}
                        style={{
                          color: "#F00000",
                          marginLeft: 0,
                          marginTop: 0
                        }}
                      >
                        <span>{errorSvg}</span> {state.emailError}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  className={`row ${!state.isCustomerExists ? "in-active" : ""
                    }`}
                >
                  <div className={"form-grop"}>
                    <label>Password</label>
                    <div
                      className={"input-pass"}
                      style={{ width: "100%", borderBottom: 0 }}
                    >
                      <input
                        data-testid={"password"}
                        type={!state.isPassShown ? "password" : "text"}
                        name="password"
                        placeholder={"Enter your password"}
                        value={state.password}
                        onChange={(e) =>
                            setState({
                                ...state,
                                password: e.target.value,
                                passError: ""
                            })
                        }
                        onKeyDown={(k) => {
                            if (k.key === "Enter") {
                              loginHandler();
                            }
                        }}
                        style={{ border: "none" }}
                      />
                      {!state.isPassShown ? (
                        <FaEye
                          color={checkColor}
                          onClick={() =>
                            setState({
                              ...state,
                              isPassShown: !state.isPassShown
                            })
                          }
                        />
                      ) : (
                        <FaEyeSlash
                          color={checkColor}
                          onClick={() =>
                            setState({
                              ...state,
                              isPassShown: !state.isPassShown
                            })
                          }
                        />
                      )}
                    </div>
                    {state.passError ? (
                      <span
                        className={"pass-strength"}
                        style={{
                          color: "#F00000",
                          marginLeft: 0,
                          marginTop: 0
                        }}
                      >
                        <span>{errorSvg}</span> {state.passError}
                      </span>
                    ) : (
                      ""
                    )}
                    { }
                    <div
                      className={`forgot-pass ${!state.isCustomerExists ? "in-active" : ""
                        }`}
                    >
                      <Link
                        className={"forgot-passLink"}
                        to={"/login"}
                        onClick={() => modalHandler()}
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </div>

                </div>
              </div>
              <div className={`route-change-tagline`}>
                    <span>Haven't created an online account yet?
                        <a href={`${process.env.REACT_APP_SIGNUP_PORTAL_URL}`} className="route-change-taglineLink">&nbsp;Create one.</a>
                    </span>
              </div>
              <AppButton
                onClick={loginHandler}
                isLoading={state.isLoading}
                isDisable={
                  isLoading
                    ? true
                    : state.email === ""
                      ? true
                      : state.isCustomerExists
                        ? state.password === ""
                          ? true
                          : false
                        : false

                  // || state.password === "" ? true : false
                }
                btnProps={{ "data-testid": "submitFormHandle" }}
                title={
                  state.isCustomerExists
                    ? !isLoading
                      ? "Sign in"
                      : "Loading..."
                    : "Continue"
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
