import Auth from "@aws-amplify/auth";
import { OptimizelyFeature } from "@optimizely/react-sdk";
import { useState, useEffect } from "react";
import { AiFillQuestionCircle, AiTwotoneMail } from "react-icons/ai";
import {
  FaBell,
  FaCalendarAlt,
  FaFileAlt,
  FaQuestionCircle,
  FaSignOutAlt,
  FaUser
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { logout, setUser } from "../../store/features/auth/authSlice";
import { PickUpSvg } from "../Svgs";
import { earliestPickupDate } from '../../helpers'

const sectionBgStyling = {
  minWidth: "324px",
  height: "100%"
}

const sectionBgStyling2 = {
  height: "fit-content",
  minHeight: "550px"
}

export default function SideMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [dayTxt, setDayTxt] = useState("");
  const [pickupDateExist, setPickupDateExist] = useState(false);
  // @ts-ignore
  const userSub = useSelector(state => state.getContactSubs.subscription)

  useEffect(() => {
    async function getPickupDatesHandler() {
      let earliestPickupDeal = earliestPickupDate(userSub)
      if (earliestPickupDeal?.dayDisplay && earliestPickupDeal?.monthDisplay && earliestPickupDeal?.currentDay) {
        setDayTxt(earliestPickupDeal.dayDisplay);
        setMonth(earliestPickupDeal.monthDisplay);
        setDay(earliestPickupDeal.currentDay);
        setPickupDateExist(true);
      } else {
        setPickupDateExist(false);
      }
    }
    getPickupDatesHandler();
  }, [userSub]);

  const onLogout = async () => {
    try {
      await Auth.signOut();
      dispatch(setUser(false));
    } catch (error) {
      dispatch(setUser(false));
    }
    dispatch(logout());
  };

  const pathName = window.location.pathname

  return (
    <div className="app-main-content">
      <div>
        <OptimizelyFeature feature="sidebar_next_pickup">
          {(enabled, variables) =>
            enabled ? (
              <div onClick={() => navigate("/dashboard")} className="pointer">
                {window.location.pathname !== "/dashboard" ? (
                  pickupDateExist && month && day && dayTxt ? (
                    // <div className = {"section-bg-card upcoming-date-side-section"}>
                    //     <label>Upcoming pick up date</label>
                    //     <div className = {"calender-card"}>
                    //         <h4>{moment().format('MMMM')}</h4>
                    //         <div className = {"calender-card-body"}>
                    //             <h2>{moment().format('d')}</h2>
                    //             <span>{moment().format('dddd')}</span>
                    //         </div>
                    //     </div>
                    // </div>
                    // <div className='center-content'>
                    <div
                      className={"section-bg-card upcoming-date-side-section"}
                    >
                      <label>Upcoming pick up date</label>
                      <div className="dashboard_calendar_container">
                        <div className="row calendar-header-display">
                          <div className="col-12 calendar-month-font">{`${month}`}</div>
                        </div>
                        <div className="row">
                          <div className="col-12 calendar-day-number-font">{`${day}`}</div>
                          <div className="col-12 calendar-day-text-font">{`${dayTxt}`}</div>

                          <div className="dasboard-bottom-calendar-top-padding"></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // <div className='center-content'>
                    <div
                      className={"section-bg-card upcoming-date-side-section"}
                    >
                      <label>Upcoming pick up date</label>
                      <div className="dashboard_calendar_container_no_pickup_date">
                        <div className="row">
                          <div className="col-12 calendar-no-pickup-msg">{`Information coming soon`}</div>
                        </div>
                        <div className="dasboard-bottom-calendar-top-padding"></div>
                      </div>
                    </div>
                  )
                ) : (
                  <></>
                )}
              </div>
            ) : (
              ""
            )
          }
        </OptimizelyFeature>
        <aside className={"section-bg-card"} style={pathName === "/dashboard" ? sectionBgStyling : sectionBgStyling2}>
          <div className={"menu-options-section"}>
            <h3 className="w500" data-testid={"Account"}>
              Account
            </h3>
            <ul>
              <li>
                <NavLink to={"/dashboard/profile"}>
                    <FaUser />
                    My profile
                </NavLink>
              </li>
              <li>
                <NavLink to={"/dashboard/plan"}>
                    <img
                        src={require("../../assets/images/serviceIcon.png")}
                        alt="icon8"
                        className="icon-design"
                    />
                    My plan
                </NavLink>
              </li>
              {/* <li> */}
              {/* <NavLink to = {"/dashboard/billing"}> */}
              {/* <img src={serviceIcon} alt="icon8" className="icon-design" /> */}
              {/* <FaUser /> */}
              {/* Subscription */}
              {/* </NavLink> */}
              {/* </li> */}
              {/* <li>
                <NavLink to={"/dashboard/billing"}>
                    <FaCreditCard />
                    Payment methods
                </NavLink>
              </li> */}
              <li>
                <a
                    href={`${process.env.REACT_APP_PICKUP_DATE_URL}`} target="_blank" rel="noreferrer">
                    <FaCalendarAlt />
                    Pickup calendar
                </a>
              </li>
              <li>
                <OptimizelyFeature feature="sidebar_pickups_button">
                  {(enabled, variables) =>
                    enabled ? (
                      <>
                        {/* <NavLink to={"/"}> 
                  </NavLink>  */}
                        <PickUpSvg />
                        Pick ups
                      </>
                    ) : (
                      ""
                    )
                  }
                </OptimizelyFeature>
              </li>
            </ul>
          </div>
          <div className={"menu-options-section"}>
            <h3 className="w500" data-testid={"settingsId"}>
              {/* Settings */}
            </h3>
            <ul>
              <li>
                <OptimizelyFeature feature="sidebar_notification_button">
                  {(enabled, variables) =>
                    enabled ? (
                      <>
                        <FaBell />
                        Notification
                      </>
                    ) : (
                      ""
                    )
                  }
                </OptimizelyFeature>
                {/* <NavLink to={"/"}> */}

                {/* </NavLink> */}
              </li>
              <li>
                <a
                    href={`${process.env.REACT_APP_KNOWLEDGE_BASE_URL}`} target="_blank" rel="noreferrer">
                    <AiFillQuestionCircle />
                    Help Center
                </a>
              </li>
              <li>
                 {/* <a
                    href={`${process.env.REACT_APP_KNOWLEDGE_BASE_URL}/kb-tickets/new`} target="_blank" rel="noreferrer">
                    <AiFillQuestionCircle />
                    Contact Us
                </a> */}
                <NavLink to={"/dashboard/contactus"}>
                    <AiTwotoneMail />
                    Contact us
                </NavLink>

                {/* </NavLink> */}
              </li>
              <li>
                <OptimizelyFeature feature="sidebar_faq_button">
                  {(enabled, variables) =>
                    enabled ? (
                      <>
                        <FaQuestionCircle />
                        FAQs
                      </>
                    ) : (
                      ""
                    )
                  }
                </OptimizelyFeature>
                {/* <NavLink to={"/"}> */}

                {/* </NavLink> */}
              </li>
              <li>
                {/* <NavLink to={"/"}> */}
                <OptimizelyFeature feature="sidebar_terms___conditions_button">
                  {(enabled, variables) =>
                    enabled ? (
                      <>
                        <FaFileAlt />
                        Terms & conditions
                      </>
                    ) : (
                      ""
                    )
                  }
                </OptimizelyFeature>

                {/* </NavLink> */}
              </li>
              <OptimizelyFeature feature="sidebar_signout_button">
                {(enabled, variables) =>
                  enabled ? (
                    <li onClick={onLogout}>
                      <NavLink to={"/"}>
                        <FaSignOutAlt />
                        Sign out
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )
                }
              </OptimizelyFeature>
            </ul>
          </div>
        </aside>
      </div>
    </div>
  );
}
