// eslint-disable-next-line  no-unused-vars
import React from "react";
// import Header from "../../components/Header";
// import InnerHTML from 'dangerously-set-html-content'
// import AppInput from '../../components/AppInput';
// import useWindowDimensions from '../../Hook/useWindowDimensions';
// import { useSelector } from "react-redux";
import ErrorModal from "../../components/ErrorModal";
import useWindowDimensions from "../../Hook/useWindowDimensions";
import Header from "../../components/Header";
import Iframe from "react-iframe";
import { useNavigate } from "react-router-dom";

function Calendar() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const hasMobile = width <= 768;
  // const [customerId, setCustomerId] = useState('')

  // const [remoteHtml, setRemoteHtml] = useState('');

  // @ts-ignore
  // const loggedInUser = useSelector(state => state.customerData)
  /* eslint-enable @typescript-eslint/no-unused-vars */

  const [state, setState] = React.useState<any>({
    isOpen: false,
    isLoading: false,
    isError: false,
    errorMessage: "",
  });

  // useEffect(() => {
  // if (loggedInUser?.data?.user) {
  //   setCustomerId(loggedInUser?.data?.user?.attributes[`custom:hubspotContactId`])
  // }

  // (async () => {
  //     fetch('https://recollect-widget.s3.us-west-2.amazonaws.com/test.html')
  //     .then(response => response.text())
  //     .then(html => setRemoteHtml(html))
  //     .catch(error => console.error(error.message))
  // })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loggedInUser]);

  const onBackPressHandler = () => {
    navigate(-1);
  };
  // const myHTML = `<script>_recollect_config = { area: "Recyclops", page: "tabbed_widget", name: "calendar" };</script>

  // <script src="https://assets.us.recollect.net/api/widget.js" charset="UTF-8"></script>`;
  return (
    <div className="app-main-content  main-seaction">
      <section
        className={`${!hasMobile ? "card-section-web section-bg-card active" : "active"
          } `}
      >
        <div className={"page-account"}>
          <ErrorModal
            isOpen={state.isError}
            onClose={() => {
              setState({ ...state, isError: false });
            }}
            message={state.errorMessage}
          />

          {hasMobile ? (
            <Header
              hasBack
              onBackPress={onBackPressHandler}
              isSettings={false}
              title={"Calendar"}
              icon={""}
            />
          ) : (
            <h3 className="w500" style={{ borderBottom: 0 }} data-testid={'calendarId'}>
              Calendar
            </h3>
          )}
          {/* <iframe title="hi" src="https://recollect-widget.s3.us-west-2.amazonaws.com/test.html"></iframe> */}
          <Iframe
            url="https://recollect-widget.s3.us-west-2.amazonaws.com/test.html"
            allow="geolocation"
            // position="absolute"
            width="100%"
            id="myId"
            className="recollect-widget"
            height="100%"
          // styles={{height: "225px"}}
          />
          {/* <div dangerouslySetInnerHTML={{ __html: myHTML }} /> */}
        </div>
      </section>
    </div>
  );
}

export default Calendar;
