// eslint-disable-next-line  no-unused-vars
import React, { useEffect, useRef, useState } from "react";
// import Header from "../../components/Header";
// import AppInput from '../../components/AppInput';
// import useWindowDimensions from '../../Hook/useWindowDimensions';
import ErrorModal from "../../components/ErrorModal";
import useWindowDimensions from "../../Hook/useWindowDimensions";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { addContactTicket } from "../../api";
import { useSelector } from "react-redux";
import AppButton from "../../components/AppButton";
// import { capitalizeFirstLetter } from "../../helper";
// import { imageUpload } from "../../api";
import { FaTimes } from "react-icons/fa";
import { BsCheckCircleFill } from "react-icons/bs";

// const fileToDataUri = (file) => new Promise((resolve, reject) => {
//   const reader = new FileReader();
//   reader.onload = (event) => {
//     resolve(event.target.result)
//   };
//   reader.readAsDataURL(file);
// })

function ContactUs() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const hasMobile = width <= 768;
  // eslint-disable-next-line
  const [selectedCategory, setSelectedCategory] = useState("");
  // const categories = ["billing", "service", "order_bags", "other"];
  // const [hubspotId, setHubspotId] = useState("");
  const form = useRef(null);
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // @ts-ignore
  const loggedInUser = useSelector((state) => state.customerData);
  // @ts-ignore
  const userSub = useSelector((state) => state.getContactSubs.subscription);

  useEffect(() => {
    // getUserSubscriptionsHandler();
    setState({
      ...state,
      contactBody: {
        ...state.contactBody,
        dealId: userSub.dealId
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);

  /* eslint-enable @typescript-eslint/no-unused-vars */

  const [state, setState] = React.useState<any>({
    isOpen: false,
    isLoading: false,
    isError: false,
    errorMessage: "",
    // dealsLoaded: false,
    // deals: [],
    submitted: false,
    contactBody: {
      dealId: "",
      subject: "",
      message: "",
      callbackRequested: false,
      files: []
    }
  });

  const onBackPressHandler = () => {
    navigate(-1);
  };

  // const handleSelectCategory = (event) => {
  //   setSelectedCategory(event.target.value);
  //   setState({
  //     ...state,
  //     contactBody: { ...state.contactBody, category: event.target.value }
  //   });
  // };

  const filesArray = [];
  const onChangeFiles = (e) => {
    for (var i = 0; i < e.target.files.length; i++) {
      filesArray.push(e.target.files[i]);
    }
    setState({
      ...state,
      contactBody: { ...state.contactBody, files: filesArray }
    });
  };

  // const getCustomerIds = async () => {
  //   const user = loggedInUser.data.user;
  //   const hubSpotId = user?.attributes["custom:hubspotContactId"];
  //   setHubspotId(hubSpotId);
  //   return user?.attributes["custom:hubspotContactId"];
  // };

  const submitTicket = async () => {
    setState({ ...state, isLoading: true });
    // const user = loggedInUser.data.user;
    // const hubSpotId = user?.attributes["custom:hubspotContactId"];
    const hubspotDealId = state.contactBody.dealId;
    const hubspotContactId = loggedInUser.data.user.attributes['custom:hubspotContactId'];

    try {
      // const contactBody = {
      //   dealId: state.contactBody.dealId,
      //   category: state.contactBody.category,
      //   subject: state.contactBody.subject,
      //   message: state.contactBody.message,
      //   callbackRequested: state.contactBody.callbackRequested,
      //   files: res
      // };
      let responseContactTicket: any = await addContactTicket(
        state.contactBody,
        hubspotContactId,
        hubspotDealId,
        form
      );
      if (responseContactTicket?.success) {
        setState({ ...state, isLoading: false, submitted: true });
        setTimeout(() => {
          setState({
            ...state,
            isLoading: false,
            submitted: false,
            contactBody: {
              ...state.contactBody,
              subject: "",
              message: "",
              callbackRequested: false,
              files: []
            }
          });
        }, 3000);
      } else {
        setState({
          ...state,
          isLoading: false,
          isError: true,
          errorMessage:
            responseContactTicket?.message ||
            "Contact form failed to submit, please try again"
        });
      }
    } catch (error) {
      setState({
        ...state,
        isError: true,
        isLoading: false,
        errorMessage:
          error?.message || "Contact form failed to submit, please try again"
      });
    }
  };

  // const handlerImage = async () => {
  //   const res = await imageUpload(state.contactBody.files);
  //   if (res) {
  //     setState({
  //       ...state,
  //       contactBody: { ...state.contactBody, files: res }
  //     });
  //   } else {
  //     console.log("Failed to upload image");
  //   }
  //   return res;
  // };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    // const res = await handlerImage();
    await submitTicket();
  };

  const removeImage = (item) => {
    const newArray = state.contactBody.files.filter(
      (currentItem) => item.lastModified !== currentItem.lastModified
    );
    setState({
      ...state,
      contactBody: { ...state.contactBody, files: [...newArray] }
    });
  };

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  return (
    <div className="app-main-content  main-seaction">
      <section
        className={`${
          !hasMobile ? "card-section-web section-bg-card active" : "active"
        } `}
      >
        {!!state.submitted && (
          <div className={"modal-container-with-background"}>
            <div className="modal-design-popup-remove-credit-card">
              {/*  */}
              <div className="row">
                <div
                  className="text-right col-12"
                  style={{ paddingTop: "16%" }}
                ></div>
                <div style={{ flex: 1 }}>
                  <div className={"form-grop"}>
                    <div className="col-12 check-mark-filled-small">
                      <BsCheckCircleFill />
                    </div>
                    <div className="col-12" style={{ paddingTop: "22px" }}>
                      <div className={"password-updated-font"}>
                        Your request has been submitted. We will contact you
                        soon
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-right col-12 top-padding-10-percent"></div>
              </div>
            </div>
          </div>
        )}
        <div className={"page-account"}>
          <ErrorModal
            isOpen={state.isError}
            onClose={() => {
              setState({ ...state, isError: false });
            }}
            message={state.errorMessage}
          />

          {hasMobile ? (
            <>
              <Header
                hasBack
                onBackPress={onBackPressHandler}
                isSettings={false}
                title={"Contact Us"}
                icon={""}
              />
              <div className="contact-main p-3 border rounded-2 shadow m-2 bg-white mt-5">
                <img
                  src={require("../../assets/images/logo.png")}
                  height="57px"
                  alt="logo"
                />
                <form
                  ref={form}
                  className="contact-form"
                  onSubmit={submitTicket}
                >
                  {userSub && (
                    <form className="contact-form">
                      <div className="contact-form-fields">
                        <h4>Select your address</h4>
                        <select
                          defaultValue={"choose"}
                          name="address"
                          id="address"
                          onChange={(e) =>
                            setState({
                              ...state,
                              contactBody: {
                                ...state.contactBody,
                                dealId: e.target.value
                              }
                            })
                          }
                        >
                          <option value="choose" disabled>
                            Choose
                          </option>
                          {userSub.map((deal, key) => {
                            return (
                              <option key={key} value={deal.dealId}>
                                {deal.serviceAddressDisplay}
                              </option>
                            );
                          })}
                        </select>
                        {/* <input type={"text"} placeholder="Choose" id="address" /> */}
                      </div>

                      {/* <div className="contact-form-fields">
                      <h4>Please choose a category</h4>
                      {categories.map((category, key) => {
                        return (
                          <div className="category-radio" key={key}>
                            <input
                              // key={key}
                              type={"radio"}
                              // id="billing"
                              value={category}
                              name="category"
                              checked={state.contactBody.category === category}
                              onChange={handleSelectCategory}
                            />
                            <label htmlFor={category} className="ml-2 mb-0">
                              {capitalizeFirstLetter(
                                category.replace(/_/g, " ")
                              )}
                            </label>
                          </div>
                        );
                      })}
                    </div> */}

                      {/* {selectedCategory === "missedpickup" && (
                    <div className="contact-form-fields">
                      <h4>Select the date of missed pick up</h4>
                      <input
                        type={"date"}
                        style={{ color: "#C4C4C4", width: "100%" }}
                      />
                    </div>
                  )} */}

                      <div className="contact-form-fields">
                        <h4>
                          Please provide a subject to summarize your request.
                        </h4>
                        <input
                          type={"text"}
                          placeholder="Enter subject"
                          value={state.contactBody.subject}
                          onChange={(e) =>
                            setState({
                              ...state,
                              contactBody: {
                                ...state.contactBody,
                                subject: e.target.value
                              }
                            })
                          }
                        />
                      </div>

                      <div className="contact-form-fields">
                        <h4>
                          Please provide a detailed description of your question
                          so we can quickly help you resolve this request
                        </h4>
                        <input
                          type={"text"}
                          placeholder="Enter message"
                          value={state.contactBody.message}
                          onChange={(e) =>
                            setState({
                              ...state,
                              contactBody: {
                                ...state.contactBody,
                                message: e.target.value
                              }
                            })
                          }
                        />
                      </div>

                      <div className="contact-form-fields">
                        <h4>
                          Would you like a customer experience specialist to
                          call you?
                        </h4>
                        <div className="specialist">
                          <div>
                            <input
                              type={"radio"}
                              id="yes"
                              value={"yes"}
                              name="callbackRequested"
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  contactBody: {
                                    ...state.contactBody,
                                    callbackRequested: true
                                  }
                                })
                              }
                            />
                            <label htmlFor="yes" className="ml-2 mb-0">
                              Yes
                            </label>
                          </div>
                          <div>
                            <input
                              type={"radio"}
                              id="no"
                              value={"no"}
                              name="callbackRequested"
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  contactBody: {
                                    ...state.contactBody,
                                    callbackRequested: false
                                  }
                                })
                              }
                            />
                            <label htmlFor="no" className="ml-2 mb-0">
                              No
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="contact-form-fields">
                        <h4>
                          Please upload any relevant files pertinent to your
                          request (optional)
                        </h4>
                        <p>Pictures, videos, invoices, etc</p>
                        <br />

                        <button
                          onClick={handleClick}
                          className="choose-files-button"
                        >
                          Choose Files
                        </button>
                        <input
                          type="file"
                          multiple={true}
                          ref={hiddenFileInput}
                          onChange={onChangeFiles}
                          style={{ display: "none" }}
                        />
                        {state.contactBody.files.length > 0
                          ? state.contactBody.files.map((item, index) => {
                              return (
                                <div className="image-title" key={index}>
                                  <h5>{item.name}</h5>
                                  <FaTimes
                                    onClick={() => {
                                      removeImage(item);
                                    }}
                                  />
                                </div>
                              );
                            })
                          : null}
                      </div>
                      <AppButton
                        onClick={handlerSubmit}
                        title={state.submitted ? "Submitted" : "Submit"}
                        isLoading={state.isLoading}
                        isDisable={
                          !state.contactBody.dealId ||
                          !state.contactBody.subject ||
                          !state.contactBody.message
                        }
                        btnProps={{
                          "data-testid": "saveBtn"
                        }}
                      />
                    </form>
                  )}
                </form>
              </div>
            </>
          ) : (
            <div className="contact-main">
              <h3 className="w500" style={{ borderBottom: 0 }}>
                Contact Us
              </h3>
              <form ref={form} className="contact-form" onSubmit={submitTicket}>
                {userSub && (
                  <form className="contact-form">
                    <div className="contact-form-fields">
                      <h4>Select your address</h4>
                      <select
                        defaultValue={"choose"}
                        name="address"
                        id="address"
                        onChange={(e) =>
                          setState({
                            ...state,
                            contactBody: {
                              ...state.contactBody,
                              dealId: e.target.value
                            }
                          })
                        }
                      >
                        <option value="choose" disabled>
                          Choose
                        </option>
                        {userSub.map((deal, key) => {
                          return (
                            <option key={key} value={deal.dealId}>
                              {deal.serviceAddressDisplay}
                            </option>
                          );
                        })}
                      </select>
                      {/* <input type={"text"} placeholder="Choose" id="address" /> */}
                    </div>

                    {/* <div className="contact-form-fields">
                    <h4>Please choose a category</h4>
                    {categories.map((category, key) => {
                      return (
                        <div className="category-radio" key={key}>
                          <input
                            type={"radio"}
                            value={category}
                            name="category"
                            checked={state.contactBody.category === category}
                            onChange={handleSelectCategory}
                          />
                          <label htmlFor={category} className="ml-2 mb-0">
                            {capitalizeFirstLetter(category.replace(/_/g, " "))}
                          </label>
                        </div>
                      );
                    })}
                  </div> */}

                    {selectedCategory === "missedpickup" && (
                      <div className="contact-form-fields">
                        <h4>Select the date of missed pick up</h4>
                        <input type={"date"} style={{ color: "#C4C4C4" }} />
                      </div>
                    )}

                    <div className="contact-form-fields">
                      <h4>
                        Please provide a subject to summarize your request.
                      </h4>
                      <input
                        type={"text"}
                        placeholder="Enter subject"
                        value={state.contactBody.subject}
                        onChange={(e) =>
                          setState({
                            ...state,
                            contactBody: {
                              ...state.contactBody,
                              subject: e.target.value
                            }
                          })
                        }
                      />
                    </div>

                    <div className="contact-form-fields">
                      <h4>
                        Please provide a detailed description of your question
                        so we can quickly help you resolve this request
                      </h4>
                      <input
                        type={"text"}
                        placeholder="Enter message"
                        data-testid={`inputTest`}
                        value={state.contactBody.message}
                        onChange={(e) =>
                          setState({
                            ...state,
                            contactBody: {
                              ...state.contactBody,
                              message: e.target.value
                            }
                          })
                        }
                      />
                    </div>

                    <div className="contact-form-fields">
                      <h4>
                        Would you like a customer experience specialist to call
                        you?
                      </h4>
                      <div className="specialist">
                        <div>
                          <input
                            type={"radio"}
                            id="yes"
                            value={"yes"}
                            name="callbackRequested"
                            onChange={(e) =>
                              setState({
                                ...state,
                                contactBody: {
                                  ...state.contactBody,
                                  callbackRequested: true
                                }
                              })
                            }
                          />
                          <label htmlFor="yes" className="ml-2 mb-0">
                            Yes
                          </label>
                        </div>
                        <div>
                          <input
                            type={"radio"}
                            id="no"
                            value={"no"}
                            name="callbackRequested"
                            onChange={(e) =>
                              setState({
                                ...state,
                                contactBody: {
                                  ...state.contactBody,
                                  callbackRequested: false
                                }
                              })
                            }
                          />
                          <label htmlFor="no" className="ml-2 mb-0">
                            No
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="contact-form-fields">
                      <h4>
                        Please upload any relevant files pertinent to your
                        request (optional)
                      </h4>
                      <p>Pictures, videos, invoices, etc</p>
                      <button
                        onClick={handleClick}
                        className="choose-files-button"
                      >
                        Choose Files
                      </button>
                      <input
                        type="file"
                        multiple={true}
                        ref={hiddenFileInput}
                        onChange={onChangeFiles}
                        style={{ display: "none" }}
                      />
                      {state.contactBody.files.length > 0
                        ? state.contactBody.files.map((item, index) => {
                            return (
                              <div className="image-title">
                                <h5 key={index}>{item.name}</h5>
                                <FaTimes
                                  onClick={() => {
                                    removeImage(item);
                                  }}
                                />
                              </div>
                            );
                          })
                        : null}
                    </div>
                    <AppButton
                      onClick={handlerSubmit}
                      title={state.submitted ? "Submitted" : "Submit"}
                      isLoading={state.isLoading}
                      isDisable={
                        !state.contactBody.dealId ||
                        !state.contactBody.subject ||
                        !state.contactBody.message
                      }
                      btnProps={{ "data-testid": "saveBtn" }}
                    />
                  </form>
                )}
              </form>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default ContactUs;
