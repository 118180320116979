import React from "react";
import AppButton from "../../../../components/AppButton";


const CallingModal = ({setCallModal}) => {
  return (
    <div className={"modal-container-with-background"}>
      <div className="modal-design-popup-remove-credit-card">
        <div
          style={{ paddingTop: "70px", paddingBottom: "35px" }}
          className="d-flex flex-column justify-content-between align-items-center"
        >
          <div>
            <p style={{fontWeight: "600"}} className="password-updated-font px-5">We’ll call you soon on the number linked to your account</p>
          </div>

          <div onClick={()=>{setCallModal(false)}} >
            <AppButton  title="Continue" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallingModal;
