import React, { useState } from "react";
import Header from "../../../components/Header";
import AppButton from "../../../components/AppButton";
import useWindowDimensions from "../../../Hook/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import CancelationModal from "../ContinueCancelation/Modal/CancelationModal";
import Layout from "../../../components/layout/layout";

function Moving() {
  const heading =
    "We can transfer your services to your new address without any additional charge. *";
  const paragraphOne =
    "Please enter your address below to check if Recyclops is available in your new area. ";
  const btnTwo = "No, Continue Cancellation";

  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  //   const [SwitchtoFreemonth, setSwitchtoFreemonth] = useState(false);
  //   const [SwitchtoRadeemcancel, setSwitchtoRadeemcancel] = useState(false);
  const hasMobile = width <= 768;
  const [Cancelation, setCancelation] = useState(false);
  //   const [CallModal, setCallModal] = useState(false);

  const onBackPressHandler = () => {
    navigate("/dashboard/services");
  };

  return (
    <>
      {Cancelation ? <CancelationModal setCancelation={setCancelation} /> : ""}
      {/* {CallModal ? <CallingModal setCallModal={setCallModal} /> : ""} */}
      <div className="app-main-content  main-seaction">
        <section
          className={`${
            !hasMobile ? "card-section-web section-bg-card active" : "active"
          } `}
        >
          <div className={"page-account"}>
            {hasMobile ? (
              <>
                <Header
                  hasBack
                  onBackPress={onBackPressHandler}
                  isSettings={false}
                  title={"Cancel subscription"}
                  icon={""}
                />
                <div className="contact-main p-5 border rounded-2 shadow m-2 bg-white">
                  <div className="mb-3">
                    <h3 className="w500 " style={{ borderBottom: 0 }}>
                      {heading}
                    </h3>
                  </div>

                  <div>
                    <p className="form-grop input">{paragraphOne}</p>
                    {/* <p>{paragraphTwo}</p> */}
                  </div>

                  {/* <div className="mt-5">
                        <AppButton
                          className="w-100"
                        //   style={{height: "38px"}}
                          onClick={() => {
                            btnOne === "Radeem offer"
                              ? setSwitchtoFreemonth(true)
                              : btnOne === "I’ll give you a chance"
                              ? setSwitchtoRadeemcancel(true)
                              : btnOne === "Yes, call me"
                              ? setCallModal(true)
                              : setSwitchtoFreemonth(true);
                          }}
                          title={btnOne}
                        />
                      </div> */}
                  <div>
                    <Layout />
                  </div>
                  <AppButton
                    className="profile-desktop-btn-container"
                    onClick={() => {
                      setCancelation(true);
                    }}
                    title={btnTwo}
                  />
                </div>
              </>
            ) : (
              <div className="contact-main">
                <h3 className="w500" style={{ borderBottom: 0 }}>
                  Cancel subscription
                </h3>

                <div className="contact-form">
                  <div className="d-flex ">
                    <div className=" mt-5 d-flex flex-column justify-content-between">
                      <div className="mb-3">
                        <h3 className="w500 " style={{ borderBottom: 0 }}>
                          {heading}
                        </h3>
                      </div>

                      <div>
                        <p className="form-grop input">{paragraphOne}</p>
                        {/* <p>{paragraphTwo}</p> */}
                      </div>
                    </div>
                  </div>

                  {/* <AppButton
                        onClick={() => {
                          btnOne === "Radeem offer"
                            ? setSwitchtoFreemonth(true)
                            : btnOne === "I’ll give you a chance"
                            ? setSwitchtoRadeemcancel(true)
                            : btnOne === "Yes, call me"
                            ? setCallModal(true)
                            : setSwitchtoFreemonth(true);
                        }}
                        title={btnOne}
                      /> */}
                  <div className="contact-form">
                    <div className="d-flex ">
                      <div className=" mt-5 d-flex flex-column justify-content-between">
                        {/* <div> */}
                        <Layout />
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                  <AppButton
                    className="profile-desktop-btn-container"
                    onClick={() => {
                      setCancelation(true);
                    }}
                    title={btnTwo}
                  />
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
}

export default Moving;
