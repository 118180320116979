import RadeemCancel from "../CancelTabs/RadeemCancel";

const SwitchedToOtherService = () => {
  const heading = "Looks like you found someone else";
  const paragraphOne =
    "Would you like us to call you and check if we can improve your offer?";
  const paragraphTwo = "";
  const btnOne = "Yes, call me";
  const btnTwo = "No, Continue Cancellation";

  return (
    <RadeemCancel
      heading={heading}
      paragraphOne={paragraphOne}
      paragraphTwo={paragraphTwo}
      btnOne={btnOne}
      btnTwo={btnTwo}
    />
  );
};

export default SwitchedToOtherService;
