
import { useState } from "react";
import { GoThreeBars } from "react-icons/go";
import HamburgerMenu from './menu'
import { NavLink } from "react-router-dom";

export default function TopMenu() {

    const [openMenu, setOpenMenu] = useState(false)

    const hamburgerMenuHandler = () => {
        setOpenMenu(!openMenu)
    }

    return (
        <>
            <div className="row" style={{ marginTop: '0px', marginRight: '-14px', marginLeft: '-30px' }}>
                <div className="col-2" >
                    <img src={require("../../assets/images/brand.png")} alt="" />
                </div>
                <div className="col text-right top-menu-display" style={{ marginTop: '20px' }}>
                    <span>
                        <a className="top-menu-links" href="https://recyclops.com/franchise/" target="_blank" rel="noreferrer">Franchising</a>
                    </span>
                    <span>
                        <a className="top-menu-links" href="https://recyclops.com/business/" target="_blank" rel="noreferrer">Commercial Recycling</a>
                    </span>
                    <span>
                        <a className="top-menu-links" href="https://recyclops.com/drivers/" target="_blank" rel="noreferrer">Become a driver</a>
                    </span>
                    <span>
                        <a className="top-menu-links" href="https://recyclops.com/about-us/" target="_blank" rel="noreferrer">About us</a>
                    </span>
                    <span>
                        <a className="top-menu-links" href="https://ask.recyclops.com" target="_blank" rel="noreferrer">Help Center</a>
                    </span>
                    <span>
                        <NavLink data-testid={'myAccountId'} to={"/dashboard"}>My Account</NavLink>
                    </span>
                </div>
                <div className="col text-right top-menu-hamburger-display" style={{ marginTop: '4px' }}>
                    {openMenu
                        ? <HamburgerMenu hamburgerMenuHandler={hamburgerMenuHandler} />
                        : <span className="top-menu-hamburger-color" data-testid={'hamburgerMenuId'} onClick={hamburgerMenuHandler}>
                            <GoThreeBars />
                        </span>
                    }
                </div>
            </div >
        </>
    );
}
