import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit'

export const updateServicePaymentFreq = createAsyncThunk(
    'services/updatePaymentFreq',
    async (newPaymentFreq) => {
        return newPaymentFreq
    })

const updateServiceSlice = createSlice({
    name: 'services',
    initialState: {
        changePlan: {},
        status: null,
    },
    extraReducers: {
        [updateServicePaymentFreq.pending]: (state, action) => {
            state.status = 'loading'
        },
        [updateServicePaymentFreq.fulfilled]: (state, { payload }) => {
            state.changePlan = payload
            state.status = 'success'
        },
        [updateServicePaymentFreq.rejected]: (state, action) => {
            state.status = 'failed'
        },
    },
})

export default updateServiceSlice.reducer
