import React, { Component } from 'react';
import CheckAvailabilityContainer from '../check_availability/checkAvailabilityContainer';
// import ConfirmationPage from '../confirmation_page/confirmationPage';
// import FollowSocialMedia from '../confirmation_page/followSocialMedia';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isWithinServicedLocation: false,
      customerIsOnForms: false,
      formCompleted: false,
      checkAvailabilityClicked: '',
      formState: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      }
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  isWithinServicedLocationHandle = () => {
    console.log("isWithinServicedLocationHandle");
    this.setState({
      isWithinServicedLocation: true,
    });
  };

  customerIsOnFormsHandle = result => {
    const { customerIsOnForms } = this.state;

    if (result === 'completed') {
      this.setState({
        formCompleted: true,
      });
    }
    this.setState({
      customerIsOnForms: !customerIsOnForms,
    });
  };

  checkAvailabilityClickedHandle = result => {
    this.setState({
      checkAvailabilityClicked: result,
    });
  };
  
  render() {
    console.log("isWithinServicedLocation", this.state.isWithinServicedLocation);
    const {
      formCompleted,
      checkAvailabilityClicked
    } = this.state;

    return (
      <div>
        <div className="container" style={{ height: '100%' }}>
          <div>
          {formCompleted ? (
              <div className="container">
                <div style={{ paddingTop: '1%' }}></div>
                {/* <ConfirmationPage formState={this.state.formState} />
                <FollowSocialMedia /> */}
              </div >
            ) : (
              <div>
                <CheckAvailabilityContainer
                  setFormState={state => {
                    this.setState({
                      formState: {
                        ...this.state.formState,
                        ...state,
                      },
                    });
                  }}
                  checkAvailabilityClickedHandle={
                    this.checkAvailabilityClickedHandle
                  }
                  checkAvailabilityClicked={checkAvailabilityClicked}
                  isWithinServicedLocationHandle={
                    this.isWithinServicedLocationHandle
                  }
                  customerIsOnFormsHandle={this.customerIsOnFormsHandle}
                />
              </div >
            )
            }
          </div>
        </div >
      </div >
    );
  }
}

export default Layout