import { useState, useEffect } from 'react'
import { updateCustomerProfile } from '../../api'
import { BsFillPersonFill } from "react-icons/bs";
import { MdMail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLockClosedSharp } from "react-icons/io5";
import { IoIosAlert } from "react-icons/io";
import { AiFillEdit } from "react-icons/ai";
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom'
import UpdatePassword from '../Password/PasswordUpdate'
import ForgotPassword from '../Password/ForgotPassword'
import * as Yup from 'yup'
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from '../../store';
import { getCustomerData } from '../../store/features/auth/customerData'
import Header from '../../components/Header';

const initState = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: ''
}

function Profile() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>()
  const [state, setState] = useState(initState);
  const [profileUpdated, profileUpdatedState] = useState(false);
  const [changeDetected, setChangeDetected] = useState(false);
  const [getProfileIsLoading, setGetProfileIsLoading] = useState(true);
  const [updateProfileSuccessful, setUpdateProfileSuccessful] = useState(true);
  const [updatingProfileIsLoading, setUpdatingProfileIsLoading] = useState(false);
  const [enableModal, setEnableModal] = useState(false);
  const [cognitoUsername, setCognitoUsername] = useState('');
  const [hubspotId, setHubspotId] = useState('');
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [currentUser, setCurrentUser] = useState('');

  // @ts-ignore
  let loggedInUser = useSelector(state => state.customerData.data)
  // @ts-ignore
  const userSub = useSelector((state) => state.getContactSubs.subscription);
  // set state based off hubspot customer info API call

  const getCustomerIds = async () => {
    const user = loggedInUser.user
    const cognitoId = user?.attributes?.sub
    const hubSpotId = user?.attributes['custom:hubspotContactId']

    setCurrentUser(user)
    setCognitoUsername(cognitoId)
    setHubspotId(hubSpotId)

    return user?.attributes['custom:hubspotContactId']
  }

  const getProfileHandler = async () => {
    if (!cognitoUsername) {
      setGetProfileIsLoading(true)
      let res = loggedInUser.userProfile
      if (loggedInUser && res) {
        let phoneNum = res.phoneNumber

        if (phoneNum) {
          if (res.phoneNumber.indexOf('+') > -1) {
            phoneNum = res.phoneNumber.split('+')[1].substring(1)
          }
        }
        setState({
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.email,
          phoneNumber: phoneNum
        })
        setGetProfileIsLoading(false)
        profileUpdatedState(false)
      }
    }
  }

  //eslint-disable-next-line
  const regex = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/gm
  const { handleSubmit, handleChange, values, touched, errors, handleBlur, } = useFormik({
    initialValues: state,
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string().min(1, 'Field cannot be empty').required(),
      lastName: Yup.string().min(1, 'Field cannot be empty').required(),
      email:
        Yup
          .string()
          .email()
          .min(1, 'Field cannot be empty').required(),
      phoneNumber:
        Yup
          .string()
          .matches(regex, 'Phone number is not valid').required()
          .min(1, 'Field cannot be empty').required()
    }),
    onSubmit: values => {
      updateProfileHandler(values)
    },
  });

  const updateNameHandler = async (value) => {
    const result = await Auth.updateUserAttributes(currentUser, {
      'given_name': value.firstName,
      'family_name': value.lastName
    });

    if (!result) {
      console.error(`Error updating name in cognito`)
    }
  }

  const updateProfileHandler = async (value) => {
    if (changeDetected && !updatingProfileIsLoading) {
      setUpdatingProfileIsLoading(true)
      // remove all non-numeric characters in a given string
      //eslint-disable-next-line
      let phoneNum = value.phoneNumber.replace(/[^\d\+]/g, "")

      let body = {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        phone: `+1${phoneNum}`,
        cognitoUsername: cognitoUsername,
        isFranchised: userSub.isFranchised
      }

      let componentStateBody = {
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        phoneNumber: `${phoneNum}`
      }

      setState(componentStateBody)

      updateNameHandler(value)

      let updateResult = await updateCustomerProfile(hubspotId, body)

      if (updateResult) {
        profileUpdatedState(true)
        setUpdatingProfileIsLoading(false)
        updateStateAfterUpdateHandler(componentStateBody)
        setUpdateProfileSuccessful(true)
        setTimeout(() => {
          profileUpdatedState(false)
          setChangeDetected(false)
        }, 4000)
      } else {
        setUpdateProfileSuccessful(false)
        profileUpdatedState(false)
        setUpdatingProfileIsLoading(false)
      }
    }
  }

  const updateStateAfterUpdateHandler = async (body) => {
    try {
      // @ts-ignore
      await dispatch(getCustomerData(body))
    } catch (err) {
      return `Error when updating state via redux, ${err}`
    }
  }

  useEffect(() => {
    // set state based off hubspot customer info API call
    getProfileHandler()
    if (values.firstName.toLocaleLowerCase() !== state.firstName.toLocaleLowerCase()) {
      setChangeDetected(true)
    } else if (values.lastName.toLocaleLowerCase() !== state.lastName.toLocaleLowerCase()) {
      setChangeDetected(true)
    } else if (values.email.toLocaleLowerCase() !== state.email.toLocaleLowerCase()) {
      setChangeDetected(true)
    } else if (values.phoneNumber !== state.phoneNumber) {
      setChangeDetected(true)
    } else {
      setChangeDetected(false)
    }
    getCustomerIds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, values.firstName, values.lastName, values.email, values.phoneNumber, currentUser]);

  const modalOpenHandler = () => {
    let setBool = !enableModal
    setEnableModal(setBool)
  }

  const forgotPwModalHandler = () => {
    setForgotPasswordModal(!forgotPasswordModal)
    setEnableModal(false)
  }

  return (
    <div className='profile-dashboard-desktop-container'>
      <div style={{
        maxWidth: '900px'
      }}>

        <div data-testid="getProfileIsLoading">
          {getProfileIsLoading
            ? <div className='loading-center-screen'>
              <div className='loader-animation'></div>
            </div>
            :
            <div>
              <div className='hideDesktop'>
                <div className='top-padding-40-px-profile' ></div>

                {forgotPasswordModal
                  ? <div className='forgot-password-profile-modal-container'>
                    <ForgotPassword modalHandler={forgotPwModalHandler} />
                  </div>
                  : ''}

                {enableModal ? <UpdatePassword setEnableModal={setEnableModal} modalHandler={forgotPwModalHandler} /> : ''}

                <div className=''>
                  <div className='row'>
                    {/*  */}
                    <div className='col-12 hideDesktop'>
                      <div className='row'>
                        <div className='col-12'>
                          {/* <div className='pageHeaderFont'>
                            <NavLink to={"/dashboard/account"} end>
                              <IoIosArrowBack />
                            </NavLink>
                            <span> Profile</span>
                          </div> */}
                          <Header hasBack onBackPress={() => { navigate("/dashboard/account") }} title={"Profile"} icon={""} isSettings={false} />

                        </div>
                      </div>
                    </div>

                    {/*  */}
                    <div className='container'>

                      <div
                      // style={{ paddingTop: '40px' }}
                      ></div>

                      <div className="form-group">
                        <div className='profileNamePosition'>
                          <div className='row'>
                            <div className='col-1' style={{ paddingLeft: '5%' }}></div>
                            <div className='col profileTitleFont'>
                              <div className='profileTitlePosition'>First name</div>
                            </div>
                            <div className='col profileTitleFont'>
                              <div className='profileTitlePosition'>Last name</div>
                            </div>
                          </div>

                        </div>
                        <div className='row'>
                          <div className='col-1 profileIcon' style={{ paddingTop: '1%' }}><BsFillPersonFill /></div>
                          <div className='col' >
                            <input
                              placeholder={`${state.firstName ? state.firstName : values.firstName}`}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.firstName}
                              id="firstName2"
                              name="firstName"
                              type="text"
                              data-testid="firstName"
                              className="input-profile-style form-control"
                            />
                            <div className='errorProfileMsgFont'>
                              {!getProfileIsLoading && touched.firstName && errors.firstName
                                ? <div>
                                  <IoIosAlert />
                                  <span>{` Field cannot be empty`}</span>
                                </div>
                                : ''}
                            </div>
                          </div>
                          {/*  */}
                          <div className='col'>
                            <input
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.lastName}
                              placeholder={`${state.lastName ? state.lastName : values.lastName}`}
                              type="text"
                              name="lastName"
                              data-testid="lastName"
                              className="input-profile-style form-control"
                            />
                            <div className='errorProfileMsgFont'>
                              {!getProfileIsLoading && touched.lastName && errors.lastName
                                ? <div>
                                  <IoIosAlert />
                                  <span>{` Field cannot be empty`}</span>
                                </div>
                                : ''}
                            </div>
                          </div>
                        </div>

                        <div className='inputBetweenSpace'>
                          <div className='row'>
                            <div className='col-1' style={{ paddingLeft: '5%' }}></div>
                            <div className='col profileTitleFont'>
                              <div className='profileTitlePosition'>Email</div>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-1 profileIcon' style={{ paddingTop: '1%' }}><MdMail /></div>
                          <div className='col'>
                            <input
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.email}
                              placeholder={`${state.email ? state.email : values.email}`}
                              type="email"
                              name="email"
                              data-testid="email"
                              className="input-profile-style form-control"
                            />
                            <div className='errorProfileMsgFont'>
                              {!getProfileIsLoading && touched.email && errors.email && values.email.length > 0
                                ? <div>
                                  <IoIosAlert />
                                  <span>{`Incorrect format`}</span>
                                </div>
                                : ''}
                              {!getProfileIsLoading && values.email.length === 0
                                ? <div>
                                  <IoIosAlert />
                                  <span>{` Field cannot be empty`}</span>
                                </div>
                                : ''}

                            </div>
                          </div>
                        </div>

                        <div className='inputBetweenSpace'>
                          <div className='row'>
                            <div className='col-1' style={{ paddingLeft: '5%' }}></div>
                            <div className='col profileTitleFont' >
                              <div className='profileTitlePosition'>Phone</div>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-1 profileIcon' style={{ paddingTop: '1%' }}><FaPhoneAlt /></div>
                          <div className='col'>
                            <input
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.phoneNumber}
                              placeholder={`${state.phoneNumber ? state.phoneNumber : values.phoneNumber}`}
                              type="phoneNumber"
                              name="phoneNumber"
                              data-testid="phoneNumber"
                              className="input-profile-style form-control"
                            />
                            <div className='errorProfileMsgFont'>
                              {!getProfileIsLoading && touched.phoneNumber && errors.phoneNumber && values.phoneNumber.length > 0
                                ? <div>
                                  <IoIosAlert />
                                  <span>{`Incorrect format`}</span>
                                </div>
                                : ''}
                              {!getProfileIsLoading && values.phoneNumber.length === 0
                                ? <div>
                                  <IoIosAlert />
                                  <span>{` Field cannot be empty`}</span>
                                </div>
                                : ''}

                            </div>
                          </div>
                        </div>

                        <div className='inputBetweenSpace'>
                          <div className='row'>
                            <div className='col-1' style={{ paddingLeft: '5%' }}></div>
                            <div className='col profileTitleFont'>
                              <div className='profileTitlePosition'>Password</div>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-1 profileIcon'><IoLockClosedSharp /></div>
                          <div className='col'>
                            <div className='row'>
                              <div className='col-10 input-profile-style form-control'>
                                <input
                                  disabled={true}
                                  // onBlur={handleBlur}
                                  // onChange={handleChange}
                                  // value={values.password}
                                  type="password"
                                  name="password"
                                  data-testid="password"
                                  className=""
                                  placeholder={`*******`} />
                              </div>
                              <div className='col update-password-icon form-control' onClick={modalOpenHandler}>
                                {<AiFillEdit />}
                              </div>
                            </div>
                            <div className='errorProfileMsgFont'>
                              {/* {!getProfileIsLoading && state.password.length <= 0
                          ? <div>
                            <IoIosAlert />
                            <span>{`Field cannot be empty`}</span>
                          </div>
                          : ''} */}
                            </div>
                          </div>
                        </div>

                        <div className='center-content' style={{ color: 'red' }}>{updateProfileSuccessful ? '' : `Error with updating. Please try again`}</div>

                        <div className='top-padding-60px'>
                          <button
                            onClick={() => handleSubmit()}
                            id='submitFormHandler1'
                            data-testid="submitFormHandler"
                            className={changeDetected
                              ? "btn-containerEnabled"
                              : "btn-container"}>
                            {profileUpdated && changeDetected ?
                              `Saved`
                              : updatingProfileIsLoading ? `loading...` : `Save`}
                          </button>
                        </div>
                      </div>

                      {/* Referral Program */}
                      <div className='row'>
                            <div className='col-12'>
                            <Header hasBack={false} onBackPress={() => { navigate("") }} title={"Referral Program"} icon={""} isSettings={false} />
                            </div>

                            <div className='col-12' style={{paddingLeft: '30px', paddingTop: '20px'}} onClick={() => {navigator.clipboard.writeText(`https://recyclops.com/signup/?rc=${hubspotId}`)}}>
                                <span className='profile-desktop-font-lable'>Your unique referral link:&nbsp;</span>
                                <p style={{cursor: 'pointer', textDecoration: 'underline'}} className='profile-desktop-input-font'>https://recyclops.com/signup?rc={hubspotId}&nbsp;(click to copy)</p>
                            </div>

                            <div className='col-12' style={{paddingTop: '20px', paddingLeft: '30px'}}>
                                <span><a href="https://ask.recyclops.com/how-does-the-referral-program-work" target='_blank' rel='noopener noreferrer'>Head here to learn more about our referral program!</a></span>
                            </div>
                        </div>
                    </div>
                    {/* END Referral Program */}
                    {/*  */}
                  </div>
                </div>
              </div>
              {/* desktop start  */}
              <div className='hideMobile'>

                {forgotPasswordModal
                  ? <div className='forgot-password-profile-modal-container'>
                    <ForgotPassword modalHandler={forgotPwModalHandler} />
                  </div>
                  : ''}

                {enableModal ? <UpdatePassword setEnableModal={setEnableModal} modalHandler={forgotPwModalHandler} /> : ''}

                <div
                  style={{ maxWidth: '700px' }}>
                  <div className='row'>
                    <div className='col-4'>
                      <div className='profile-desktop-header-font'>
                        Profile
                      </div>
                    </div>
                    <div className='col-8'></div>

                    <div className='padding-top-65px'></div>

                    <div style={{ paddingTop: '60px' }}></div>
                    <div className='col-4 profile-desktop-lable-position'>
                      <span className='col-1 profileIcon'><BsFillPersonFill /></span>
                      <span className='profile-desktop-font-lable'>
                        Name
                      </span>
                    </div>
                    <div className='col-4'>
                      <input
                        placeholder={`${state.firstName ? state.firstName : values.firstName}`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                        id="firstName1"
                        name="firstName"
                        type="text"
                        data-testid="firstName"
                        className="input-profile-style form-control profile-desktop-input-font"
                      />
                      <div className='errorProfileMsgFont'>
                        {!getProfileIsLoading && touched.firstName && errors.firstName
                          ? <div>
                            <IoIosAlert />
                            <span>{` Field cannot be empty`}</span>
                          </div>
                          : ''}
                      </div>
                    </div>
                    <div className='col-4'>
                      <input
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName}
                        placeholder={`${state.lastName ? state.lastName : values.lastName}`}
                        type="text"
                        name="lastName"
                        data-testid="lastName"
                        className="input-profile-style form-control profile-desktop-input-font"
                      />
                      <div className='errorProfileMsgFont'>
                        {!getProfileIsLoading && touched.lastName && errors.lastName
                          ? <div>
                            <IoIosAlert />
                            <span>{` Field cannot be empty`}</span>
                          </div>
                          : ''}
                      </div>
                    </div>

                    <div className='padding-top-65px'></div>

                    <div className='col-4 profile-desktop-lable-position'>
                      <span className='col-1 profileIcon'><MdMail /></span>
                      <span className='profile-desktop-font-lable'>
                        Email
                      </span>
                    </div>
                    <div className='col-8'>
                      <input
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        placeholder={`${state.email ? state.email : values.email}`}
                        type="email"
                        name="email"
                        data-testid="email"
                        className="input-profile-style form-control profile-desktop-input-font"
                      />
                      <div className='errorProfileMsgFont'>
                        {!getProfileIsLoading && touched.email && errors.email && values.email.length > 0
                          ? <div>
                            <IoIosAlert />
                            <span>{`Incorrect format`}</span>
                          </div>
                          : ''}
                        {!getProfileIsLoading && values.email.length === 0
                          ? <div>
                            <IoIosAlert />
                            <span>{` Field cannot be empty`}</span>
                          </div>
                          : ''}
                      </div>
                    </div>

                    <div className='padding-top-65px'></div>

                    <div className='col-4 profile-desktop-lable-position'>
                      <span className='col-1 profileIcon'><FaPhoneAlt /></span>
                      <span className='profileTitleFont' style={{ color: '#666666' }}>
                        Phone
                      </span>
                    </div>
                    <div className='col-8'>
                      <input
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phoneNumber}
                        placeholder={`${state.phoneNumber ? state.phoneNumber : values.phoneNumber}`}
                        type="phoneNumber"
                        name="phoneNumber"
                        data-testid="phoneNumber"
                        className="input-profile-style form-control profile-desktop-input-font"
                      />
                      <div className='errorProfileMsgFont'>
                        {!getProfileIsLoading && touched.phoneNumber && errors.phoneNumber && values.phoneNumber.length > 0
                          ? <div>
                            <IoIosAlert />
                            <span>{`Incorrect format`}</span>
                          </div>
                          : ''}
                        {!getProfileIsLoading && values.phoneNumber.length === 0
                          ? <div>
                            <IoIosAlert />
                            <span>{` Field cannot be empty`}</span>
                          </div>
                          : ''}
                      </div>
                    </div>

                    <div className='padding-top-65px'></div>

                    <div className='col-4 profile-desktop-lable-position'>
                      <span className='col-1 profileIcon'><IoLockClosedSharp /></span>
                      <span className='profile-desktop-font-lable'>
                        Password
                      </span>
                    </div>
                    <div className='col'>
                      <div className='row'>
                        <div className='col-10 input-profile-style form-control'>
                          <input
                            disabled={true}
                            type="password"
                            name="password"
                            data-testid="password"
                            className="profile-desktop-input-font"
                            placeholder={`*******`} />
                        </div>
                        <div className='col update-password-icon form-control' onClick={modalOpenHandler}>
                          <span className='pointer'>
                            {<AiFillEdit />}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className='padding-top-65px'></div>

                  </div>
                </div>
                {/*  */}
                <div className='center-content' style={{ color: 'red' }}>{updateProfileSuccessful ? '' : `Error with updating. Please try again`}</div>

                <div className='profile-desktop-btn-position'>
                  <button
                    onClick={() => handleSubmit()}
                    id='submitFormHandler2'
                    data-testid="submitFormHandler"
                    disabled={profileUpdated && changeDetected ? true : false}
                    className={changeDetected
                      ? "profile-desktop-btn-containerEnabled"
                      : "profile-desktop-btn-container"}>
                    {profileUpdated && changeDetected ?
                      `Saved`
                      : updatingProfileIsLoading ? `Saving...` : `Save`}
                  </button>
                </div>
                
                {/* Referral Program */}
                <div className='row'>
                    <div className='col-4 padding-top-65px'>
                      <div className='profile-desktop-header-font'>Referral Program</div>
                    </div>
                    <div className='col-8'></div>

                    <div className='col-12' style={{paddingLeft: '30px', paddingTop: '20px'}} onClick={() => {navigator.clipboard.writeText(`https://recyclops.com/signup/?rc=${hubspotId}`)}}>
                       <span className='profile-desktop-font-lable'>Your unique referral link:&nbsp;</span>
                       <span style={{cursor: 'pointer', textDecoration: 'underline'}} className='profile-desktop-input-font'>https://recyclops.com/signup?rc={hubspotId}&nbsp;(click to copy)</span>
                    </div>

                    <div className='col-8' style={{paddingTop: '20px', paddingLeft: '30px'}}>
                        <span><a href="https://ask.recyclops.com/how-does-the-referral-program-work" target='_blank' rel='noopener noreferrer'>Head here to learn more about our referral program!</a></span>
                    </div>
                </div>
                {/* END Referral Program */}
              </div>
              {/*  desktop end */}
            </div>
          }</div>
      </div>
    </div>

  )
}

export default Profile
