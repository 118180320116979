import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const selectedDeal = createAsyncThunk("currentDeal", async (deal: Object) => {
  return deal;
});

const dealSlice = createSlice({
  name: "currentDeal",
  initialState: {
    deal: {},
    status: null
  },
  extraReducers: {
    [selectedDeal.pending]: (state, action) => {
      state.status = "loading";
    },
    [selectedDeal.fulfilled]: (state, { payload }) => {
      state.deal = payload;
      state.status = "success";
    },
    [selectedDeal.rejected]: (state, action) => {
      state.status = "failed";
    }
  }
});

export default dealSlice.reducer;
