// import React, { useState } from 'react';
// import customerBasicInfoStyling from './customerBasicInfoStyling'
// import { useFormik } from 'formik';
// import * as Yup from 'yup'
// import HelloMurph from '../../assets/images/peekLeft.png'
// import { newLocationRequest } from '../../api2'
// import { BsCheckCircleFill } from "react-icons/bs";
// import InputMask from 'react-input-mask';
// import { IoIosAlert } from "react-icons/io";
import Murph05 from '../../assets/images/Murph05.png';


// const initState = {
//     firstName: '',
//     lastName: '',
//     phoneNumber: '',
//     email: '',
// }

function NoServiceMsg({ changeAddressHandler, streetAddress1, streetAddress2, cityName, stateNameAbrev, zipCode, lat, lng }) {
    // const [successful, setThankYou] = useState(false)
    // const [mouseHover, setMouseHover] = useState(false)

    // const mouseHoverHandler = () => {
    //     setMouseHover(!mouseHover)
    // }

    // //eslint-disable-next-line
    // const regex = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/gm
    // const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    //     initialValues: initState,
    //     enableReinitialize: true,
    //     validationSchema: Yup.object({
    //         firstName: Yup.string().min(1, 'Field cannot be empty').required(),
    //         lastName: Yup.string().min(1, 'Field cannot be empty').required(),
    //         email:
    //             Yup
    //                 .string()
    //                 .email()
    //                 .min(1, 'Field cannot be empty').required(),
    //         phoneNumber:
    //             Yup
    //                 .string()
    //                 .min(12, 'Field cannot be empty').required()
    //                 .max(12, 'Field cannot be empty').required()
    //                 .matches(regex, "Must Contain 10 digits")
    //     }),
    //     onSubmit: values => {
    //         submitHandler(values)
    //     },
    // });

    // const submitHandler = async () => {
    //     setThankYou(true)

    //     let res = await newLocationRequest(values.firstName, values.lastName, values.email, values.phoneNumber, streetAddress1, streetAddress2, cityName, stateNameAbrev, zipCode, lat, lng)

    //     if (res?.data?.success) {
    //         console.log('Successful')
    //     } else {
    //         console.error('error with receiving api resp for new location request.')
    //     }
    // }

    // let isValid = false
    // if (Object.keys(errors).length === 0) {
    //     if (values.firstName.length > 0 && values.lastName.length > 0 && values.email.length > 0 && values.phoneNumber.length === 12 && streetAddress1.length > 0 && cityName.length > 0 && zipCode.length > 0 && lat && lng) {
    //         isValid = true
    //     }
    // }

    return (
        <div className='container'>
            <div className='row text center' style={{ width: "100%", display: "flex", justifyContent: "center", alignItmes: "center", fontSize: "20px", marginTop: '65px', marginBottom: '100px' }}>
                <div style={{width: "100%"}}>
                    <img
                    className="monsterPeekingMobile monsterPeeking"
                    src={Murph05}
                    alt="Logo"
                    style={{width: "200px", height: "200px"}}
                  // style={checkAvailabilityStyling.welcomeIconStyling}
                  />
                </div>
                <div className='col-sm-12 col-md-8 text-center location-request-display'>
                    <div className='no-service-murph-header text-center'>We are not available in your area yet. We will update you when we start service at the address mentioned by you.</div>
                    {/* <div className='no-service-murph-msg text-left' >
                        <div>
                            {`Thank you for your interest. If you want our recycling services in your area, 
                            please share your contact information and we'll let you know when our service is available.`}
                        </div>
                    </div> */}
                    <div className='no-service-murph-header text-center' style={{fontWeight: "bold"}}> You can pause the subscription till then.</div>

                    {/* <div className='row' style={{ paddingBottom: '30px', }}>
                        <div className='col-sm-12 col-md-6'>
                            <div className='no-service-murph-another-address text-left'
                                onClick={() => changeAddressHandler()}>
                                <span className='pointer txt'> You can pause the subscription till then.</span>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-3'></div>
                    </div> */}
                </div>

                {/* {successful
                    ? <div className='col-sm-12 col-md-4 location-request-success-container location-request-murph-display-parant'>
                        <img
                            className='location-request-success-murph-display-child hide-when-mobile'
                            src={HelloMurph}
                            alt='HelloMurph'
                        />
                        <div style={{ padding: '60px 0px 10px 0px' }}>
                            <div className='col-12 check-mark-filled'>
                                <BsCheckCircleFill />
                            </div>
                            <div className='location-request-submitted-font'>Submitted</div>
                            <div className='location-request-submitted-comment'>Thank you. We’ll keep you posted about the service availability.</div>
                        </div>
                    </div>
                    : 
                    <></>
                    // <div className='col-sm-12 col-md-4 new-location-request-container location-request-murph-display-parant'>
                    //     <img
                    //         className='location-request-murph-display-child hide-when-mobile'
                    //         src={HelloMurph}
                    //         alt='HelloMurph'
                    //     />
                    //     <div className=''>
                    //         <div className='text-left' style={touched.firstName && errors.firstName && values.firstName.length === 0 ? { paddingBottom: '15px' } : { paddingBottom: '30px' }}>
                    //             <div className='location-request-input-header'>
                    //                 First Name
                    //             </div>
                    //             <input
                    //                 type="text"
                    //                 name="firstName"
                    //                 onChange={handleChange}
                    //                 value={values.firstName}
                    //                 placeholder="Enter your first name"
                    //                 style={customerBasicInfoStyling.inputStyling}
                    //                 className='location-request-input'
                    //             />
                    //             {touched.firstName && errors.firstName && values.firstName.length === 0
                    //                 ? <div className='error-icon'>
                    //                     <IoIosAlert />
                    //                     <span>{` Field cannot be empty`}</span>
                    //                 </div>
                    //                 : ''}
                    //         </div>
                    //     </div>

                    //     <div>
                    //         <div className='text-left' style={touched.lastName && errors.lastName && values.lastName.length === 0 ? { paddingBottom: '15px' } : { paddingBottom: '30px' }}>
                    //             <div className='location-request-input-header'>
                    //                 Last Name
                    //             </div>
                    //             <input
                    //                 type="text"
                    //                 name="lastName"
                    //                 onChange={handleChange}
                    //                 value={values.lastName}
                    //                 placeholder="Enter your last name"
                    //                 style={customerBasicInfoStyling.inputStyling}
                    //                 className='location-request-input'
                    //             />
                    //             {touched.lastName && errors.lastName && values.lastName.length === 0
                    //                 ? <div className='error-icon'>
                    //                     <IoIosAlert />
                    //                     <span>{` Field cannot be empty`}</span>
                    //                 </div>
                    //                 : ''}
                    //         </div>
                    //     </div>

                    //     <div>
                    //         <div className='text-left' style={touched.phoneNumber && errors.phoneNumber ? { paddingBottom: '15px' } : { paddingBottom: '30px' }}>
                    //             <div className='location-request-input-header'>
                    //                 Phone Number
                    //             </div>
                    //             <InputMask
                    //                 name="phoneNumber"
                    //                 type="text"
                    //                 mask="999-999-9999"
                    //                 placeholder="Enter your phone number"
                    //                 style={customerBasicInfoStyling.inputStyling}
                    //                 onChange={handleChange}
                    //                 value={values.phoneNumber}
                    //                 className='location-request-input'
                    //             />
                    //             {touched.phoneNumber && errors.phoneNumber
                    //                 ? <div className='error-icon'>
                    //                     <IoIosAlert />
                    //                     <span>{` Field cannot be empty`}</span>
                    //                 </div>
                    //                 : ''}
                    //         </div>
                    //     </div>

                    //     <div>
                    //         <div className='text-left' style={touched.email && errors.email ? { paddingBottom: '15px' } : { paddingBottom: '30px' }}>
                    //             <div className='location-request-input-header'>
                    //                 Email
                    //             </div>
                    //             <input
                    //                 type="text"
                    //                 name="email"
                    //                 onChange={handleChange}
                    //                 value={values.email}
                    //                 placeholder="Enter your email"
                    //                 style={customerBasicInfoStyling.inputStyling}
                    //                 className='location-request-input'
                    //             />
                    //             {touched.email && errors.email
                    //                 ? <div className='error-icon'>
                    //                     <IoIosAlert />
                    //                     <span>{` Field cannot be empty`}</span>
                    //                 </div>
                    //                 : ''}
                    //         </div>
                    //     </div>

                    //     <div>
                    //         <div className='text-left' style={{ paddingBottom: '35px' }}>
                    //             <div className='location-request-input-header'>
                    //                 Address
                    //             </div>

                    //             <div className='location-request-input'>
                    //                 {`${streetAddress2 ? `${streetAddress1} ${streetAddress2},` : `${streetAddress1},`} ${cityName}, ${stateNameAbrev}, ${zipCode}`}
                    //             </div>
                    //         </div>
                    //     </div>

                    //     <div style={{
                    //         display: 'flex',
                    //         justifyContent: 'center',
                    //         alignItems: 'center'
                    //     }}>
                    //         <button
                    //             onMouseOver={mouseHoverHandler}
                    //             onMouseLeave={mouseHoverHandler}
                    //             disabled={!isValid ? true : false}
                    //             className={!isValid ? 'disabled-button' : mouseHover ? 'hovered-button' : 'addon-button-selected'}
                    //             onClick={() => handleSubmit()}>
                    //             {successful ? 'Thank you!' : `Submit`}
                    //         </button>
                    //     </div>
                    //     <div style={{ marginBottom: '70px' }}></div>
                    // </div>
                } */}
            </div >
        </div>
    );
}

export default NoServiceMsg