
import { configureStore } from '@reduxjs/toolkit'
import authReducer from './features/auth/authSlice'
import customerDataSlice from './features/auth/customerData'
import getContactSubs from './features/deal/getContactSubs'
import hideMenuBar from './features/Menu/HideMenu'
import updateServicePaymentFreq from './features/ChangeServicePlan/UpdatePaymentFrequency'
import addOnServices from './features/ChangeServicePlan/AddOnServices'
import updatePickupServiceSlice from './features/ChangeServicePlan/UpdatePickupFrequency'
import selectedPickupPlanSlice from './features/ChangeServicePlan/SelectedPickupPlan'
import updatedBaseServiceSlice from './features/ChangeServicePlan/SelectedServicesUpdate'
import firstPickupDataSlice from './features/pick/firstPickupData'
import getPickupFrequency from './features/pick/getPickupFrequency'
import selectedDeal from './features/deal/selectedDeal'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    customerData: customerDataSlice,
    getContactSubs: getContactSubs,
    selectedDeal: selectedDeal,
    hideMenuBar: hideMenuBar,
    updateServicePaymentFreq: updateServicePaymentFreq,
    addOnServices: addOnServices,
    updateServicePickupFreq: updatePickupServiceSlice,
    selectedPickupPlan: selectedPickupPlanSlice,
    updatedBaseService: updatedBaseServiceSlice,
    pickup: firstPickupDataSlice,
    pickupFrequency: getPickupFrequency,
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})


export type AppDispatch = typeof store.dispatch;
