import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    locationId: '',
    zoneId: '',
    routeDay: '',
    pickupDateDisplay: '',
    pickupDate: ''

}

export const firstPickupDataSlice = createSlice({
    name: "pickup",
    initialState,
    reducers: {
        setLocationId: (state, { payload }) => {
            state.locationId = payload
        },
        setZoneId: (state, { payload }) => {
            state.zoneId = payload
        },
        setRouteDay: (state, { payload }) => {
            state.routeDay = payload
        },
        setFirstPickupDateDisplay: (state, { payload }) => {
            state.pickupDateDisplay = payload
        },
        setFirstPickupDate: (state, { payload }) => {
            state.pickupDate = payload
        },
    },

})


export const { setLocationId, setZoneId, setRouteDay, setFirstPickupDateDisplay, setFirstPickupDate } = firstPickupDataSlice.actions
export default firstPickupDataSlice.reducer