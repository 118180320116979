import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit'

export const selectedServicesUpdate = createAsyncThunk(
    'services/selectedServicesUpdate',
    async (selectedServicesUpdate) => {
        return selectedServicesUpdate
    })

const updatedBaseServiceSlice = createSlice({
    name: 'services',
    initialState: {
        updatedPlan: [],
        status: null,
    },
    extraReducers: {
        [selectedServicesUpdate.pending]: (state, action) => {
            state.status = 'loading'
        },
        [selectedServicesUpdate.fulfilled]: (state, { payload }) => {
            state.updatedPlan = payload
            state.status = 'success'
        },
        [selectedServicesUpdate.rejected]: (state, action) => {
            state.status = 'failed'
        },
    },
})

export default updatedBaseServiceSlice.reducer
