import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit'

export const updateServicePickupFreq = createAsyncThunk(
    'services/updatePickupFreq',
    async (newPickupFreq) => {
        return newPickupFreq
    })

const updatePickupServiceSlice = createSlice({
    name: 'services',
    initialState: {
        changePickupFreq: '',
        status: null,
    },
    extraReducers: {
        [updateServicePickupFreq.pending]: (state, action) => {
            state.status = 'loading'
        },
        [updateServicePickupFreq.fulfilled]: (state, { payload }) => {
            state.changePickupFreq = payload
            state.status = 'success'
        },
        [updateServicePickupFreq.rejected]: (state, action) => {
            state.status = 'failed'
        },
    },
})

export default updatePickupServiceSlice.reducer
