import { useState } from 'react'
import OtpInput from 'react-otp-input';
import { GrFormClose } from "react-icons/gr";
import { FaEyeSlash, FaEye, FaCheckCircle, FaInfoCircle } from 'react-icons/fa';

const checkColor = "#A5A5A5"
const checkColorSuccess = "#3EB5AB"

const initState = {
    password: '',
    newPassword: '',
    confirmPassword: '',
    isNewPassShown: false,
    isPassShown: false,
    isConfirmPassShown: false,
    isConfirmPassActive: false,
}

function CodeInput({ resendCode, resendConfirmationCodeHandler, codeHandler, code, sendConfirmationCode, confirmationCodeSent, email, logo, modalHandler, confirmationCodeError, updatePasswordHandler }) {
    const [state, setState] = useState(initState)

    const passwordStrengthHandler = (type) => {
        if (type === "min") {
            return state.newPassword.length < 8
        }
        if (type === "hasLowerCase") {
            return !(/[a-z]/g.test(state.newPassword));
        }
        if (type === "hasUpperCase") {
            return !(/[A-Z]/.test(state.newPassword));

        }
        if (type === "hasNumber") {
            return !(/[0-9]/.test(state.newPassword));

        }
        if (type === "hasSpecial") {
            return !(/[-!@#$%^&*+_]/.test(state.newPassword));
        }
    }

    const checkAll = () => {
        return ((state.newPassword.length >= 8) && (/[a-z]/g.test(state.newPassword)) && (/[A-Z]/.test(state.newPassword) && (/[0-9]/.test(state.newPassword)) && (/[-!@#$%^&*+_]/.test(state.newPassword))))
    }

    const passwordCheckHandler = () => {
        if (state.newPassword.length > 0 && checkAll() && (state.confirmPassword === state.newPassword)) {
            updatePasswordHandler(state.newPassword)
        }
    }

    return (
        <div>
            {confirmationCodeSent
                ? <div className={"forgot-password-container"}>
                    <div className={"card-top-img"}>
                        <img className='monster-desktop-display' src={logo} alt={"monster"} />
                    </div>
                    <div className="forgot-password-bgCard">
                        <div className='row' style={{ paddingRight: '0px', paddingTop: '30px' }}>
                            <span className='col-2' style={{ fontSize: '18px' }}></span>
                            <span className='col-8 text-center forgot-password-header-size'>Update password</span>
                            <span className='col text-right forgot-password-close-modal-button' onClick={() => modalHandler()}>
                                <GrFormClose />
                            </span>
                        </div>
                        {/*  */}
                        <div className={"form-grop update-password-input-padding-20px"}>

                            <div className='col-12'>
                                <div style={{ paddingTop: '10px' }}></div>
                                <label className={"signupInput"}>New password</label>
                                {/* <div style={{ paddingTop: '2%' }}></div> */}
                                <div className={"input-pass"}  >
                                    <input className='forgot-update-input-size' data-testid={"newpassword"} type={!state.isNewPassShown ? "password" : "text"} name="newpassword" id="testx1" placeholder={"Enter new password"} value={state.newPassword} onChange={e => setState({ ...state, newPassword: e.target.value })} />
                                    {
                                        !state.isNewPassShown ?
                                            <FaEye className='pointer' color={checkColor} onClick={() => setState({ ...state, isNewPassShown: !state.isNewPassShown })} />
                                            :
                                            <FaEyeSlash className='pointer' color={checkColor} onClick={() => setState({ ...state, isNewPassShown: !state.isNewPassShown })} />
                                    }
                                </div>
                                {
                                    (state.newPassword.length > 0 && !checkAll()) ?
                                        <>
                                            <div className={""}>
                                                <p className={"pass-strength"} style={{ marginLeft: 0 }}>Your password must include </p>
                                                <div>
                                                    <span ><FaCheckCircle className={"FaCheckCircle-icon"} color={passwordStrengthHandler("min") ? checkColor : checkColorSuccess} size={14} /></span>
                                                    <span className={"pass-strength"}>Minimum 8 characters</span>
                                                </div>
                                                <div>
                                                    <span ><FaCheckCircle className={"FaCheckCircle-icon"} color={passwordStrengthHandler("hasLowerCase") ? checkColor : checkColorSuccess} size={14} /></span>
                                                    <span className={"pass-strength"}>A lowercase letter</span>
                                                </div>
                                                <div>
                                                    <span ><FaCheckCircle className={"FaCheckCircle-icon"} color={passwordStrengthHandler("hasUpperCase") ? checkColor : checkColorSuccess} size={14} /></span>
                                                    <span className={"pass-strength"}>An uppercase letter</span>
                                                </div>
                                                <div>
                                                    <span ><FaCheckCircle className={"FaCheckCircle-icon"} color={passwordStrengthHandler("hasNumber") ? checkColor : checkColorSuccess} size={14} /></span>
                                                    <span className={"pass-strength"}>A number</span>
                                                </div>
                                                <div>
                                                    <span ><FaCheckCircle className={"FaCheckCircle-icon"} color={passwordStrengthHandler("hasSpecial") ? checkColor : checkColorSuccess} size={14} /></span>
                                                    <span className={"pass-strength"}>A special character(-!@#$%^&*+_)</span>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    state.newPassword.length > 0 && (state.newPassword.toLowerCase() === state.password.toLowerCase()) ?
                                        <span className={"pass-strength"} style={{ color: 'red', marginLeft: 0, marginTop: 0 }}>
                                            <FaInfoCircle style={{ top: 1, position: 'relative', marginRight: 3 }} />
                                            {`Password cannot match old password `}
                                        </span>
                                        :
                                        null
                                }
                            </div>
                            <div className='col-12 update-password-top-padding-20px'>
                                <label className={"signupInput update-password--header-padding"}>Confirm password</label>
                                <div className={"input-pass"}  >
                                    <input className='forgot-update-input-size' type={!state.isConfirmPassShown ? "password" : "text"} name="confirm-password" id="test14" placeholder={"Confirm new Password"} value={state.confirmPassword} onChange={e => setState({ ...state, confirmPassword: e.target.value })} onFocus={() => setState({ ...state, isConfirmPassActive: true })} />
                                    {
                                        !state.isConfirmPassShown ?
                                            <FaEye className='pointer' color={checkColor} onClick={() => setState({ ...state, isConfirmPassShown: !state.isConfirmPassShown })} />
                                            :
                                            <FaEyeSlash className='pointer' color={checkColor} onClick={() => setState({ ...state, isConfirmPassShown: !state.isConfirmPassShown })} />
                                    }
                                </div>
                                <div>
                                    {
                                        state.confirmPassword.length > 0 ?
                                            <span className={"pass-strength"} style={{ color: state.newPassword !== state.confirmPassword ? "#FB0303" : checkColorSuccess, marginLeft: 0, marginTop: 0 }}>
                                                <FaInfoCircle style={{ top: 1, position: 'relative', marginRight: 3 }} />
                                                {
                                                    state.newPassword !== state.confirmPassword ?
                                                        "Password do not match"
                                                        : "Password match"
                                                }
                                            </span>
                                            :
                                            null
                                    }
                                </div>
                                <div className='col-12 update-password-code-input-button'>
                                    <div>
                                        <button
                                            type='button'
                                            onClick={passwordCheckHandler}
                                            className='btn-container'>
                                            {`Update password`}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*  */}
                    </div>
                </div>
                : <div className={"forgot-password-container"}>
                    <div className={"card-top-img"}>
                        <img className='monster-desktop-display' src={logo} alt={"monster"} />
                    </div>
                    <div className="forgot-password-bgCard">
                        <div className='row' style={{ paddingRight: '0px', paddingTop: '7%' }} >
                            <span className='col-2' style={{ fontSize: '18px' }}></span>
                            <span className='col-8 text-center forgot-password-header-size'>Enter your code</span>
                            <span className='col text-right forgot-password-close-modal-button' onClick={() => modalHandler()}>
                                <GrFormClose />
                            </span>
                        </div>
                        {confirmationCodeError
                            ? <div className={"email-sent-msg forgot-password-error-code"}>
                                {`Incorrect code. Enter again.`}
                            </div>
                            : <div className={"password-updated-code-response-msg-font forgot-password-msg-size container"}>
                                {`Code sent to ${email} will expire in 30 mins`}
                            </div>
                        }
                        <div className='container'>
                            <div className='row' style={{ paddingTop: '5%', }}>
                                <div className='col-12 center-content' data-testid='codeInputId'>
                                    <OtpInput
                                        shouldAutoFocus={true}
                                        isInputNum={true}
                                        inputStyle={confirmationCodeError ? `code-verication-email-error` : `code-verication-email`}
                                        value={code}
                                        onChange={codeHandler}
                                        numInputs={6}
                                        separator={<span style={{ paddingRight: '20px' }}></span>}
                                    />
                                </div>
                                <div className='col-12 center-content' style={{ paddingTop: '20px' }}>
                                    <button
                                        className='resend-confirmation-code'
                                        data-testid='resendConfirmationCode'
                                        id='resendConfirmationCode'
                                        style={{ cursor: 'pointer' }}
                                        disabled={resendCode ? true : false}
                                        onClick={() => resendConfirmationCodeHandler()}>
                                        {resendCode ? 'Sent' : `Resend code`}
                                    </button>

                                </div>
                                <div className='col-12'>
                                    <div style={{ paddingTop: '20px' }}></div>
                                    <div>
                                        <button
                                            data-testid='submitResetPw'
                                            id='submitResetPw'
                                            type='button'
                                            onClick={sendConfirmationCode}
                                            className='btn-container'>
                                            {`Reset password`}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>



    )
}

export default CodeInput
