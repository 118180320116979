
function HamburgerMenu({ hamburgerMenuHandler }) {
    return (
        <div className="modal-container-with-background">
            <div className="hamburger-background-left" data-testid={'hamburgerMenuMobileId'} onClick={hamburgerMenuHandler}></div>
            <div className="hamburger-body">
                <div className="row text-center">
                    <div className='col-12'></div>
                    <div className="col-12">
                        <div style={{ padding: '150px 50px 50px 50px' }}>
                            <div className="hamburger-menu-display">
                                <a href="https://recyclops.com/franchise/" target="_blank" rel="noreferrer">Franchising</a>
                            </div>
                            <div className="hamburger-menu-display">
                                <a href="https://recyclops.com/business/" target="_blank" rel="noreferrer">Commercial Recycling</a>
                            </div>
                            <div className="hamburger-menu-display">
                                <a href="https://recyclops.com/drivers/" target="_blank" rel="noreferrer">Become a driver</a>
                            </div>
                            <div className="hamburger-menu-display">
                                <a href="https://recyclops.com/about-us/" target="_blank" rel="noreferrer">About us</a>
                            </div>
                            <div className="hamburger-menu-display">
                                <a href="https://ask.recyclops.com" target="_blank" rel="noreferrer">Help Center</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default HamburgerMenu;