import React, { Component } from "react";
import peekMain from "../../assets/images/peekMain.png";
import checkAvailabilityStyling from "./checkAvailabilityStyling";
import GoogleLocationSearchInput from "../google_address_suggestion/googleLocationSearchInput";

class CheckAvailabilityForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseHover: false
    };
  }

  onMouseEnterHandler = () => {
    this.setState({
      mouseHover: true
    });
  };

  onMouseLeaveHandler = () => {
    this.setState({
      mouseHover: false
    });
  };

  render() {
    const {
      handleSubmit,
      addressHandle,
      address,
      streetAddress1,
      streetAddress2,
      zipCode,
      zipCodeHandle,
      streetAddress2Handle,
      // servicedWithinLocation,
      checkAvailabilityDisabled,
      googleLocationSearchHandle,
      googleGeocodeHandle,
      checkAvailabilityClicked
    } = this.props;

    const { mouseHover } = this.state;

    return (
      <div style={checkAvailabilityStyling.pagePosition}>
        <form onSubmit={handleSubmit} required autoComplete="off">
          <div className="">
            <div style={checkAvailabilityStyling.centerEverything}>
              <div className="row">
                <div className="col-12">
                  <img
                    className="monsterPeekingMobile monsterPeeking"
                    src={peekMain}
                    alt="Logo"
                    // style={checkAvailabilityStyling.welcomeIconStyling}
                  />
                </div>
                <div
                  className="col-12"
                  style={checkAvailabilityStyling.formContainer}
                >
                  <br />
                  <div
                    style={{
                      paddingLeft: "3%",
                      paddingRight: "3%",
                      paddingTop: "1.5%"
                    }}
                  >
                    {/* <br /> */}
                    <div
                      className="row"
                      style={{
                        marginBottom: "0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start"
                      }}
                    >
                      <div
                        className="col-lg-9 col-sm-12"
                        style={{ paddingTop: "1%" }}
                      >
                        <div className="row">
                          <div className="col-lg-6 col-6">
                            <GoogleLocationSearchInput
                              handleSubmit={handleSubmit}
                              addressHandle={addressHandle}
                              address={address}
                              googleGeocodeHandle={googleGeocodeHandle}
                              streetAddress2={streetAddress2}
                              streetAddress1={streetAddress1}
                              googleLocationSearchHandle={
                                googleLocationSearchHandle
                              }
                            />
                          </div>
                          <div className="col-lg-3 col">
                            <input
                              name="test"
                              type="text"
                              value={streetAddress2}
                              onChange={streetAddress2Handle}
                              placeholder={"Unit#"}
                              style={
                                checkAvailabilityStyling.inputStylingUnitNumber
                              }
                            />
                          </div>
                          <div className="col-lg-3 col">
                            <input
                              name="test"
                              type="text"
                              value={zipCode}
                              placeholder="Zip"
                              onChange={zipCodeHandle}
                              style={
                                checkAvailabilityStyling.inputStylingZipCode
                              }
                            />
                          </div>
                        </div>

                        {/* <div style={{ paddingTop: '5%' }}></div> */}
                      </div>

                      <div className="col-lg-3 col-sm-12">
                        <div className="row">
                          {/* when in mobile view, center button below address input */}
                          <div className="col-2 resizeCheckAvailability"></div>
                          <div
                            className="col"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            <button
                              disabled={checkAvailabilityDisabled}
                              id="checkAvailabilityButtonId"
                              type="submit"
                              value="Submit"
                              onMouseOver={this.onMouseEnterHandler}
                              onMouseLeave={this.onMouseLeaveHandler}
                              // style={
                              //   checkAvailabilityDisabled
                              //     ? checkAvailabilityStyling.buttonDisabledStyling
                              //     : servicedWithinLocation || mouseHover
                              //       ? checkAvailabilityStyling.buttonEnabledStyling
                              //       : checkAvailabilityStyling.buttonStyling
                              // }
                              style={
                                checkAvailabilityDisabled
                                  ? checkAvailabilityStyling.buttonDisabledStyling
                                  : mouseHover
                                  ? checkAvailabilityStyling.hoveredButtonEnabledStyling
                                  : checkAvailabilityStyling.buttonEnabledStyling
                              }
                            >
                              {/* <span className="white_color">{` `}</span> */}
                              <div style={{ fontWeight: "500" }}>
                                {checkAvailabilityClicked === "loading"
                                  ? `Checking...`
                                  : `Check availability`}
                              </div>
                            </button>
                          </div>
                          {/* when in mobile view, center button below address input */}
                          {/* <div className="col-2 resizeCheckAvailability"></div> */}
                        </div>

                        <br />
                        {/* <br /> */}
                      </div>
                    </div>
                  </div>
                  {/* <br /> */}
                  <div style={{ paddingTop: "1%" }}></div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CheckAvailabilityForm;
