// eslint-disable-next-line  no-unused-vars
import React, { useState } from "react";
import Header from "../../../components/Header";
import AppButton from "../../../components/AppButton";
import useWindowDimensions from "../../../Hook/useWindowDimensions";
import { useNavigate } from "react-router-dom";
import CancelationModal from "../ContinueCancelation/Modal/CancelationModal";

const Others = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [Cancelation, setCancelation] = useState(false);

  const hasMobile = width <= 768;

  const onBackPressHandler = () => {
    navigate("/dashboard/cancel-subscription");
  };

  return (
    <>
      {Cancelation ? <CancelationModal setCancelation={setCancelation} /> : ""}
      <div className="app-main-content  main-seaction">
        <section
          className={`${
            !hasMobile ? "card-section-web section-bg-card active" : "active"
          } `}
        >
          <div className={"page-account"}>
            {hasMobile ? (
              <>
                <Header
                  hasBack
                  onBackPress={onBackPressHandler}
                  isSettings={false}
                  title={"Cancel subscription"}
                  icon={""}
                />
                <div className="contact-main p-5 border rounded-2 shadow m-2 bg-white">
                  <div className="mb-3">
                    <h3 className="w500 " style={{ borderBottom: 0 }}>
                      {"Please provide reason for your cancellation."}
                    </h3>
                  </div>

                  <div>
                    <p className="form-grop input">
                      {
                        "After mentioning the reason, click submit if you want our representative to call you and improvise your offer. Otherwise, continue to cancel."
                      }
                    </p>

                    <form className="d-flex flex-column" action="">
                      <input
                        className="border-bottom mt-3"
                        type="text"
                        name=""
                        id=""
                      />
                      <input
                        className="border-bottom mt-3 mb-2"
                        type="text"
                        name=""
                        id=""
                      />
                    </form>
                  </div>

                  <AppButton title={"Submit"} />
                  <AppButton
                    className="profile-desktop-btn-container"
                    title={"Continue cancellation"}
                    onClick={() => {
                      setCancelation(true);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="contact-main">
                <h3 className="w500" style={{ borderBottom: 0 }}>
                  Cancel subscription
                </h3>

                <div className="contact-form w-100">
                  <div className=" mt-5 d-flex flex-column justify-content-between">
                    <div className="mb-3">
                      <h3 className="w500 " style={{ borderBottom: 0 }}>
                        {"Please provide reason for your cancellation."}
                      </h3>
                    </div>

                    <div>
                      <p className="form-grop input">
                        {
                          "After mentioning the reason, click submit if you want our representative to call you and improvise your offer. Otherwise, continue to cancel."
                        }
                      </p>

                      <form className="d-flex flex-column " action="">
                        <input
                          className="border-bottom mt-3"
                          type="text"
                          name=""
                          id=""
                        />
                        <input
                          className="border-bottom mt-3 mb-2"
                          type="text"
                          name=""
                          id=""
                        />
                      </form>
                    </div>
                  </div>

                  <AppButton title={"Submit"} />
                  <AppButton
                    className="profile-desktop-btn-container"
                    title={"Continue cancellation"}
                    onClick={() => {
                      setCancelation(true);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Others;
