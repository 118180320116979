import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit'

export const addOnServices = createAsyncThunk(
    'services/addOnServices',
    async (addOns) => {
        return addOns
    })

const addOnServicesSlice = createSlice({
    name: 'services',
    initialState: {
        addOns: [],
        status: null,
    },
    extraReducers: {
        [addOnServices.pending]: (state, action) => {
            state.status = 'loading'
        },
        [addOnServices.fulfilled]: (state, { payload }) => {
            state.addOns = payload
            state.status = 'success'
        },
        [addOnServices.rejected]: (state, action) => {
            state.status = 'failed'
        },
    },
})

export default addOnServicesSlice.reducer
