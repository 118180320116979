import RadeemCancel from "../CancelTabs/RadeemCancel";

const MissedPickups = () => {
  const heading = "Apologies for inconvenience";
  const paragraphOne =
    "It looks like your pick up was on route, but driver couldn’t complete it. We are extremely sorry for that.";
  const paragraphTwo = "Please give us a chance and we will do the pick on mmddyyyy.";
  const btnOne = "I’ll give you a chance";
  const btnTwo = "No, Continue Cancellation";

  return (
    <RadeemCancel
      heading={heading}
      paragraphOne={paragraphOne}
      paragraphTwo={paragraphTwo}
      btnOne={btnOne}
      btnTwo={btnTwo}
    />
  );
};

export default MissedPickups;
