import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import murphHoldUp from "../../assets/images/murphHoldUp.png";
import { earliestPickupDate } from '../../helpers'
// import useWindowDimensions from '../../Hook/useWindowDimensions'
// import CreditCard from '../CreditCard'

function Home() {
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [dayTxt, setDayTxt] = useState("");
  const [pickupDateExist, setPickupDateExist] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // @ts-ignore
  const loggedInUser = useSelector((state) => state.customerData);
  // @ts-ignore
  const userSub = useSelector((state) => state.getContactSubs.subscription);

  useEffect(() => {
    setIsLoading(true);
    async function getPickupDatesHandler() {
      let earliestPickupDeal = earliestPickupDate(userSub)
      if (month && day && dayTxt) {
        setPickupDateExist(true);
        setIsLoading(false);
      } else {
        if (loggedInUser.status === "success") {
          setFirstName(loggedInUser.data.userProfile?.firstName);
          setPickupDateExist(true);
          setDayTxt(earliestPickupDeal?.dayDisplay);
          setMonth(earliestPickupDeal?.monthDisplay);
          setDay(earliestPickupDeal?.currentDay);
          setIsLoading(false);
        } else {
          // setPickupDateExist(false)
          // setIsLoading(false)
        }
      }
    }

    getPickupDatesHandler();
  }, [
    userSub,
    loggedInUser.status,
    loggedInUser.data?.userProfile?.firstName,
    pickupDateExist,
    month,
    day,
    dayTxt,
    firstName
  ]);

  return (
    <div className="dashboard-background-color">
      <div className="dasboard-header-top-padding">
        <div className="dashboard-greeting" data-testid={"dashboardGreeting1"}>
          <div>{`Hello, ${firstName || ""}`}</div>
        </div>
        {/* <div className='dashboard-greeting-2'>{`Let's continue your recycling journey!`}</div> */}
        <div
          className="dashboard-greeting-2"
          data-testid={"dashboardGreeting2"}
        >
          <div>{`Thank you for helping to save the planet one recycling bag at a time!`}</div>
          <div
            style={{ paddingTop: "5px" }}
          >{`Your next chance to be a hero is coming up!`}</div>
        </div>

        <div className="dashboard-upcoming-pick-up-top-padding"></div>

        <div>
          {isLoading ? (
            <div>
              <div className="center-content" style={{ minHeight: "50vh" }}>
                <div className="loader-animation"></div>
              </div>
            </div>
          ) : (
            <div>
              <div className="col-12 upcoming-pickup-title">
                Upcoming pick up date
              </div>

              {pickupDateExist && month && day && dayTxt ? (
                <div className="center-content">
                  <div className="dashboard_calendar_container">
                    <div className="row calendar-header-display">
                      <div className="col-12 calendar-month-font">{`${month}`}</div>
                    </div>
                    <div className="row">
                      <div className="col-12 calendar-day-number-font">{`${day}`}</div>
                      <div className="col-12 calendar-day-text-font">{`${dayTxt}`}</div>

                      <div className="dasboard-bottom-calendar-top-padding"></div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="center-content">
                  <div className="dashboard_calendar_container_no_pickup_date">
                    <div className="row">
                      <div className="col-12 calendar-no-pickup-msg">{`Information coming soon`}</div>
                    </div>
                    <div className="dasboard-bottom-calendar-top-padding"></div>
                  </div>
                </div>
              )}

              <div className="center-content">
                <div className="merph">
                  <img src={murphHoldUp} alt={"murphHoldUp"} width={110} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
