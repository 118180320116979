/*eslint-disable eqeqeq*/
import { useState } from 'react'
import { FaEyeSlash, FaEye, FaInfoCircle } from 'react-icons/fa';
import { MdEdit } from "react-icons/md";
import { getYears, months, states } from '../../helper';
const checkColor = "#c4c4c4"
// const checkColorSuccess = "#3EB5AB"

function AppInput({ onFocus = () => { }, hasError = "", type = "text", onChange, disabled = false, value, placeholder, label, inputStyle = {}, containerStyle = {}, hasEditIcon = "" }) {
    const [isShown, setIsShown] = useState(type === "password")
    // console.log('value', value)
    return (
        <div className={"form-grop"}>
            <label>{label}</label>
            <div className={`input-container ${(type === "password" || hasEditIcon) ? "input-pass" : ""}`} style={containerStyle}>
                {type === "select" ?
                    <select defaultValue={value} onChange={onChange} style={inputStyle}>

                        {placeholder == "Year" && getYears().map((year, i) => (
                            <option key={i} value={year}>{year}</option>
                        ))}

                        {placeholder == "Month" && months.map((month, i) => (
                            <option key={i} value={month}>{month}</option>
                        ))}

                        {placeholder == "State" && states.map((state, i) => (
                            <option key={i} value={state.abbreviation}>{state.name}</option>
                        ))}

                    </select>
                    :
                    <input
                        data-testid={`${label}`.toLowerCase()}
                        type={type === "password" ? !isShown ? "text" : "password" : type}
                        style={inputStyle}
                        placeholder={placeholder}
                        value={value}
                        disabled={disabled}
                        onChange={onChange}
                        onFocus={onFocus}
                    />}
                {
                    type === "password" ?
                        <>
                            {
                                !isShown ?
                                    <FaEye color={checkColor} onClick={() => setIsShown(!isShown)} />
                                    :
                                    <FaEyeSlash color={checkColor} onClick={() => setIsShown(!isShown)} />
                            }
                        </>
                        : <></>
                }
                {
                    hasEditIcon ?
                        hasEditIcon === "more" ?
                            <span style={{ color: "#3EB5AB" }}>more</span>
                            :
                            <MdEdit color={"#3EB5AB"} size={20} />
                        : ""
                }
            </div>
            {
                hasError ?
                    <span className={"pass-strength blank-password"} style={{ marginLeft: 0, color: "#FB0303" }}><FaInfoCircle style={{ top: 1, position: 'relative', marginRight: 3 }} /> {hasError}</span>
                    : <></>
            }
        </div>
    )
}

export default AppInput