import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { customerProfile } from '../../../api';
import { sentryCaptureError } from '../../../components/utils/index';

export const getCustomerData = createAsyncThunk(
    'user/getCustomerData',
    async (updatedProfileBody) => {
        try {
            const user = await Auth.currentAuthenticatedUser()

            let userProfile = {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
            }

            let customerId = user?.attributes['custom:hubspotContactId']

            let profile = {};

            if (customerId) {
                profile = await customerProfile(customerId)
            } else {
                sentryCaptureError(`Error while pulling customer ID from logged in user data`, { extra: { customerId } });
            }

            let profileUpdated = false
            if (updatedProfileBody) {
                let updatedProfileBodyExist = Object.keys(updatedProfileBody).length > 0
                profileUpdated = updatedProfileBodyExist
            }
            if (profile?.exists) {
                userProfile.firstName = profileUpdated && updatedProfileBody.firstName ? updatedProfileBody.firstName : profile.firstName
                userProfile.lastName = profileUpdated && updatedProfileBody.lastName ? updatedProfileBody.lastName : profile.lastName
                userProfile.email = profileUpdated && updatedProfileBody.email ? updatedProfileBody.email : profile.email
                userProfile.phoneNumber = profileUpdated && updatedProfileBody.phoneNumber ? updatedProfileBody.phoneNumber : profile.phoneNumber
            }

            if (user) {
                return {
                    user,
                    userProfile
                }
            } else {
                sentryCaptureError(`Error while pulling logged in user's data`);
            }

        } catch (err) {
            sentryCaptureError(err);
            console.error(`Error while getting logged in customer data, ${err}`)
            return `error`;
        }

    })

const customerDataSlice = createSlice({
    name: 'user',
    initialState: {
        data: [],
        status: null,
    },
    extraReducers: {
        [getCustomerData.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getCustomerData.fulfilled]: (state, { payload }) => {
            state.data = payload
            state.status = 'success'
        },
        [getCustomerData.rejected]: (state, action) => {
            state.status = 'failed'
        },
    },
})

export default customerDataSlice.reducer
