import { createSlice, createAsyncThunk, } from '@reduxjs/toolkit'

export const hideMenuBar = createAsyncThunk(
    'services/change-plan',
    async (bool) => {
        return bool
    })

const dealSlice = createSlice({
    name: 'services',
    initialState: {
        changePlan: false,
        status: null,
    },
    extraReducers: {
        [hideMenuBar.pending]: (state, action) => {
            state.status = 'loading'
        },
        [hideMenuBar.fulfilled]: (state, { payload }) => {
            state.changePlan = payload
            state.status = 'success'
        },
        [hideMenuBar.rejected]: (state, action) => {
            state.status = 'failed'
        },
    },
})

export default dealSlice.reducer
